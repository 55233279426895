import React from 'react';
import {
  Row,
  Col,
  Icon,
  Typography,
} from 'antd';
import {
  validateTitle,
  validateDescription,
} from '../../tools';

const { Text } = Typography;

const SectionItem = (props) => {
  const {
    item,
    arrayId,
    editing,
    isEditingExtra,
    showModal,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    sectionList,
    onEraseItem,
  } = props;

  const iconContainer = {
    xs: { span: 6 },
    sm: { span: 6 },
    md: { span: 6, offset: 0 },
    lg: { span: 5, offset: 0 },
    xl: { span: 5, offset: 0 },
    xxl: { span: 5, offset: 0 },
  };
  const descriptionContainer = {
    xs: { span: 16, offset: 0 },
    sm: { span: 16, offset: 0 },
    md: { span: 18, offset: 0 },
    lg: { span: 19, offset: 0 },
    xl: { span: 19, offset: 0 },
    xxl: { span: 19, offset: 0 },
  };

  return (
    (isEditingExtra(item.isComplete))
      && (
      <Row key={item.arrayId} style={{ marginBottom: '3vh' }}>
        <Col
          className="icon-align"
          {...iconContainer}
        >
          <Icon
            key={item.arrayId}
            type={item.icon}
            className="icon-container"
            onClick={
                (editing ? (event) => showModal(item, arrayId, event) : null)
              }
            data-cy="test-icon-section-icon-sectionItem"
          />
        </Col>
        <Col
          className={`section-description ${!item.isComplete ? 'incomplete-field' : ''}`}
          {...descriptionContainer}
        >
          <Row>
            <div className="edit median-subtitle">
              <Text
                strong
                onClick={(e) => displayEditable(e)}
                editable={{
                  onChange: (event) => onChangeField(
                    item.arrayId, arrayId, event, 'sectionList', 'title', validateTitle,
                  ),
                }}
                data-cy="test-text-title-text-section-item"
              >
                {item.title}
              </Text>
            </div>
          </Row>
          <Row>
            <Text
              onClick={(e) => displayEditable(e)}
              editable={{
                onChange: (event) => onChangeField(
                  item.arrayId, arrayId, event, 'sectionList', 'description', validateDescription,
                ),
              }}
              data-cy="test-text-description-text-section-item"
            >
              {item.description}
            </Text>
          </Row>
          <Row type="flex" justify="end" align="bottom">
            {checkLimitsAddErase(
              arrayId, false, sectionList, onEraseItem, 'close-container', item, 'sectionList',
            )}
          </Row>
        </Col>
      </Row>
      )
  );
};

export default SectionItem;
