import React, {Component} from 'react';
import {
  Row 
} from 'antd';

import './styles.scss';

class ContentListData extends Component {

  render() {
    return (
      <Row className="content-list">
        <p className="content-title">{this.props.title}</p>
        {
          this.props.listItem.map((item) => 
            <Row className="inner-list">
              <p className="title" key={item.title}>{item.title}</p>
              {
                item.contentList.map((content) =>
                  <p className="content">{content}</p>
                )
              }
            </Row>
          )
        }
      </Row>
    );
  }
}

export default ContentListData;