import React from 'react';
import {Link} from 'react-router-dom';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import {
    Row,
    Col,
    Typography,
    Breadcrumb,
} from 'antd';

import MessageComponent from '../../../components/Message';
import ManageEmail from '../../../components/ManageEmail';
import { mainContainerBody } from '../../../styles/responsive';

const {Title} = Typography;

const managersQuery = gql`
query Query($ventureId: ID!) {
  allManagers {
    id
    emailAddress
    firstName
    lastName
    memberopinionSet {
      venture {
        id
      }
      email
      evaluation
    }
  }
  venture (id: $ventureId) {
    id
    companyName
  }
}
`;

const investmentQuery = gql`
query Query($ventureId: ID!) {
  investorForVenture(ventureId: $ventureId) {
    suggested {
        investor {
            id
            emailAddress
            firstName
            lastName
            memberopinionSet {
              venture {
                id
              }
              email
              evaluation
            }
        }
        score
    }
    nonSuggested {
        id
        emailAddress
        firstName
        lastName
        memberopinionSet {
          venture {
            id
          }
          email
          evaluation
        }
    }
  }
  venture (id: $ventureId) {
    id
    companyName
  }
}
`;

class VentureManageEmail extends React.Component {
    state = {
        companyName: ""
    };

    render() {
        const {companyName} = this.state;
        const ventureId = this.props.match.params.id;
        const type = this.props.match.params.type;
        let query;
        let title = "";
        let dataName = "";
        if (type === "IM") {
            query = managersQuery;
            title = "Internal Managers";
            dataName = "allManagers";
        } else {
            query = investmentQuery;
            title = "Investment Committee";
            dataName = "investorForVenture";
        }

        return (
            <Row type="flex" justify="space-around" align="middle">
                <Col {...mainContainerBody}>
                    <Breadcrumb className="dashboard-breadcrumb">
                        <Breadcrumb.Item>
                            <Link to="/dashboard/venture/list/">
                                Ventures
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Query query={query} variables={{ventureId}} fetchPolicy="network-only">
                        {({loading, error, data, refetch}) => {
                            if (loading)
                                return (
                                    <MessageComponent
                                        icon="loading"
                                        iconSize={50}
                                        text="Loading"
                                        level={4}
                                    />
                                );
                            if (error)
                                return (
                                    <MessageComponent
                                        icon="smile"
                                        iconSize={50}
                                        text={`${error.message}`}
                                        level={4}
                                    />
                                );
                            if (this.props.location.state !== undefined) {
                                refetch();
                            }
                            if (companyName !== data.venture.companyName) {
                                this.setState({
                                    companyName: data.venture.companyName,
                                });
                            }
                            let dataSuggested = null;
                            let dataNonSuggested = [];
                            if (type === "IC") {
                                dataSuggested = data[dataName].suggested.map(investor => {
                                    return {
                                        ...investor.investor,
                                        score: investor.score
                                    };
                                });
                                dataNonSuggested = data[dataName].nonSuggested;
                            }
                            else {
                                dataNonSuggested = data[dataName];
                            }
                            return (
                                <div>
                                    <Title className="dashboard-title" level={1}>
                                        {`${title} for ${companyName}`}
                                    </Title>
                                    <ManageEmail
                                        data={dataNonSuggested}
                                        dataSuggested={dataSuggested}
                                        ventureId={ventureId}
                                        handleGeneralMessage={true}
                                        type={type}
                                    />
                                </div>
                            );
                        }}
                    </Query>
                </Col>
            </Row>
        );
    }
}

export default VentureManageEmail;