import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'

import VentureListView from './VentureListView';
import VentureManageEmail from './VentureManageEmail';

class VentureView extends Component {

  render() {
    return (
      <Switch>
        <Route path="/dashboard/venture/list/" component={VentureListView} />
        <Route path="/dashboard/venture/:id/manager_email/:type/" component={VentureManageEmail} />
      </Switch>
    )
  }
}

export default VentureView
