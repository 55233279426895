import React from 'react';
import {
  Row,
  Typography,
} from 'antd';
import DownloadPdf from '../DownloadPdf';

const {Title} = Typography;

const PitchDeck = (props) => {
  const { pitch } = props;

  return (
    <Row className="mb-3">
      <Row className="text-center">
        <Title className="mb-0 section-title title-team pt-0">
          Pitch Deck
        </Title>
      </Row>
      <DownloadPdf title="Download Venture Pitch" pdf={pitch} />
    </Row>
  );
};

export default PitchDeck;
