import React from 'react';
import gql from 'graphql-tag';
import {
  Query,
} from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Icon,
  Breadcrumb,
} from 'antd';
import InvestorInfo from '../../../components/InvestorInfo';
import MessageComponent from '../../../components/Message';

import '../../../styles/utils.scss';


const query = gql`
query DetailView($id: ID!, $showInvestor: Boolean!) {
  member(id: $id) {
    id
    firstName
    lastName
    emailAddress
    memberopinionSet {
      id
      venture{
        id
        companyName
      }
      email
      linkUsed
      evaluation
      comment
    }
    answerSet {
      choices {
        name
      }
      question {
        id
        name
      }
    }
    tags {
      id
      name
    }
  }
  tags(showInvestor: $showInvestor) {
    id
    name
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i += 1) {
    content[i].key = i;
  }

  return content;
};

const renderBoolean = (data) => {
  if (data) {
    return (
      <Icon
        className="positive-color font-15"
        type="check"
      />
    );
  }

  return (
    <Icon
      className="negative-color font-15"
      type="close"
    />
  );
};


class InvestmentDetailView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      memberId: null,
    };
  }

  render() {
    const columns = [
      {
        title: 'Venture',
        dataIndex: 'venture',
        key: 'venture',
        align: 'center',
        children: [
          {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (text, data) => (
              renderBoolean(!data.venture.archived)
            ),
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, data) => (
              <Link
                to={`/dashboard/founder/venture/${data.venture.id}/`}
              >
                {data.venture.companyName}
              </Link>
            ),
          }],
      },
      {
        title: 'Evaluation',
        dataIndex: 'evaluation',
        key: 'evaluation',
        align: 'center',
        children: [
          {
            title: 'Email Received',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            render: (text, data) => (
              renderBoolean(data.email)
            ),
          },
          {
            title: 'Link Used',
            dataIndex: 'link',
            key: 'link',
            align: 'center',
            render: (text, data) => (
              renderBoolean(data.linkUsed)
            ),
          },
          {
            title: 'Evaluation',
            dataIndex: 'evaluation',
            key: 'evaluation',
            align: 'center',
            render: (text, data) => (
              renderBoolean(data.evaluation)
            ),
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            align: 'center',
            render: (text, data) => (
              data.comment
            ),
          },
        ],
      },
    ];
    const { match } = this.props;
    const { id } = match.params;
    const { memberId } = this.state;

    return (
      <Query
        query={query}
        variables={
          {
            id,
            showInvestor: true,
          }
        }
        pollInterval={500}
      >
        {({
          loading, error, data,
        }) => {
          const shouldShowLoading = loading && !data.member;
          if (shouldShowLoading) {
            return (
              <MessageComponent
                icon="loading"
                iconSize={50}
                text="Loading"
                level={4}
              />
            );
          }
          if (error) {
            return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}
              />
            );
          }
          const memberData = {
            firstName: data.member.firstName,
            lastName: data.member.lastName,
            emailAddress: data.member.emailAddress,
          };
          addKeys(data.member.memberopinionSet);
          if (memberId !== data.member.id) {
            this.setState({
              memberId: data.member.id,
            });
          }

          return (
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                <Breadcrumb className="dashboard-breadcrumb">
                  <Breadcrumb.Item>
                    <Link to="/dashboard/member/investment/list/">
                      Investors
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {data.member.firstName}
                    {' '}
                    {data.member.lastName}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <InvestorInfo
                  memberTags={data.member.tags}
                  memberData={memberData}
                  answerSet={data.member.answerSet}
                  memberId={memberId}
                />
                <Table
                  columns={columns}
                  dataSource={data.member.memberopinionSet}
                  pagination={{ pageSize: 50 }}
                  bordered
                />
              </Col>
            </Row>
          );
        }}
      </Query>

    );
  }
}

export default InvestmentDetailView;
