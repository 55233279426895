import React from 'react';
import { Route } from 'react-router-dom';
import BaseWrapper from '../baseWrapper';

const PublicRoute = ({ component: Component, header=false, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <BaseWrapper privateURL={false} component={Component} header={header} withSider={false} {...props} />
    )}
  />
);

export default PublicRoute;
