import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  Link
} from '@react-pdf/renderer';
import styles from './styles';
import setting from './icons/setting.png';
import {isUndefined} from 'util';
import {
  API_URL,
  LINKEDIN_USER_URL
} from '../../../utils/constants';
import {contentComplete} from '../../../utils/tools';

const imageFormats = ['.jpg', '.png', 'jpeg'];

const renderImage = (image) => {
  let customURL = "";
  if (!isUndefined(image.fileList[0].url)) {
    customURL = image.fileList[0].url;
  }
  if (!isUndefined(image.fileList[0].response) && (image.fileList[0].status === "done")) {
    customURL = `${API_URL}${image.fileList[0].response.image}`;
  }
  if (customURL === "" || !imageFormats.includes(customURL.slice(-4))) {
    return null;
  }
  return <Image style={styles.cover} src={customURL} />;
};

const renderSection = (dataList) => {
  for (let item = 0; item < dataList.length; item++) {
    if (contentComplete(dataList[item].content)) return true;
  }
  return false;
};

const renderRevenueSection = (dataList) => {
  for (let item = 0; item < dataList.length; item++) {
    if (contentComplete(dataList[item].content) 
    || dataList[item].percentageComplete) return true;
  }
  return false;
};

// Create Document Component
const OnePagerPDF = (props) => {
  const { data } = props;
  const renderRevenue = renderRevenueSection(data.circleList);
  const renderSecondCol = renderSection(data.rightList);
  const renderFirsCol = renderSection(data.sectionList);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.margin}>
          <View style={styles.companyNameSection}>
            {
              data.companyNameComplete &&
              <Text style={styles.centerTitle}>
                {data.companyName}
              </Text>
            }
          </View>
          <View style={styles.twoColumn}>
          {
            renderFirsCol &&
            <View style={styles.firstColumn}>
              {
                data.sectionList.map((item) => (
                  contentComplete(item.content) &&
                  <View key={item.id}>
                    <Text style={styles.columnTitle}>
                      { item.title }
                    </Text>
                    {
                      item.content.map((content) => (
                        content.isComplete &&
                        <View style={styles.contentWrapper} key={`sub-${content.id}`}>
                          <View style={styles.sideIcon}>
                            <Image source={setting} />
                          </View>
                          <View style={styles.contentFirstColumn}>
                            <Text style={styles.bold}>
                              { content.title }
                            </Text>
                            <Text>
                              { content.description }
                            </Text>
                          </View>
                        </View>
                      ))
                    }
                  </View>
                ))
              }
            </View>
          }
          {
            !renderFirsCol &&
            <View style={styles.firstColumnNoRender} />
          }
          {
            renderSecondCol &&
            <View style={styles.secondColumn}>
              {
                data.rightList.map((item) => (
                  contentComplete(item.content) &&
                  <View key={item.id}>
                    <Text style={styles.centerTitle}>
                      { item.title }
                    </Text>
                    {
                      item.content.map((content) => (
                        content.isComplete &&
                        <View key={content.id}>
                          <View style={styles.circle}>
                            <Text style={styles.bigNumber}>
                              { content.number }
                            </Text>
                            <Text style={styles.medianText}>
                              { content.quantity }
                            </Text>
                            <Text style={styles.normalText}>
                              { content.preText ? content.preText : "" }
                              { content.year }
                              { content.postText ? content.postText: "" }
                            </Text>
                          </View>
                          <Text style={styles.normalText}>
                            { content.title }
                          </Text>
                        </View>
                      ))
                    }
                  </View>    
                ))
              }
            </View>
          }
          {
            !renderSecondCol &&
            <View style={styles.secondColumnNoRender} />
          }
          </View>
          {
            renderRevenue &&
            <View style={styles.revenueModelSection}>
              {
                data.circleList.map((item) => (
                  <View key={item.id}>
                    <View style={styles.revenueHeader}>
                      <View style={styles.revenueTitle}>
                        <Text>
                          { item.title }
                        </Text>
                      </View>
                      <View style={styles.revenuePercentage}>
                        {
                          item.percentageComplete &&
                          <Text>
                            { item.initPercentage }% - { item.endPercentage }% { item.percentageText }
                          </Text>
                        }
                      </View>
                    </View>
                    <View style={styles.semiCircleSection}>
                      {
                        item.content.map((content) => (
                            content.isComplete &&   
                            <View style={styles.semiCircle} key={content.id}>
                              <Text style={styles.bigNumber}>
                                { content.number }
                              </Text>
                              <Text style={styles.medianText}>
                                { content.quantity }
                              </Text>
                              <Text style={styles.normalText}>
                                { content.description }
                              </Text>
                            </View>
                        ))
                      }
                    </View>
                  </View>
                ))
              }
            </View>
          }
          {
            !renderRevenue &&
            <View style={styles.revenueNoRender} />
          }
          <View style={styles.checklistSection}>
            {
              data.checkList.map((item) => (
                contentComplete(item.content) &&
                <View style={styles.checklist} key={item.id}>
                  <Text>
                    { item.title } { item.boldTitle ? `- ${item.boldTitle}` : '' }
                  </Text>
                  {
                    item.content.map((content) => (
                      content.isComplete &&
                      <View style={styles.checklistContent} key={content.id}>
                        <View style={styles.checklistIcon}>
                          <Text>
                          </Text>
                        </View>
                        <View style={styles.checklistList}>
                          <Text style={styles.smallText}>
                            { content.title }
                          </Text>
                          <Text style={styles.smallText}>
                            { content.description }
                          </Text>
                        </View>
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
          {
            contentComplete(data.profileList) &&
            <View style={styles.profileSection}>
              <Text style={styles.centerTitle}>
                Team
              </Text>
              {
                data.profileList.map((item) => (
                  item.isComplete &&
                  <View style={styles.profileData}>
                    <View style={styles.imageProfile}>
                    {
                      item.image.fileList.length !== 0 &&
                      renderImage(item.image)
                    }
                    </View>
                    <View style={styles.profileDescription}>
                      <Text>
                        { item.name }
                      </Text>
                      {
                        item.content.map((content) => (
                          <Text>
                            { content.description }
                          </Text>
                        ))
                      }
                      <Link src={`${LINKEDIN_USER_URL}${item.linkedin}`}>
                        Linkedin 
                      </Link>
                    </View>
                  </View>
                ))
              }
            </View>
          }
        </View>
      </Page>
    </Document>
  );
};

export default OnePagerPDF;