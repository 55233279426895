import React from 'react';
import {
  Row,
  Col,
  Avatar,
  Typography,
} from 'antd';
import {
  validateTitle,
  validateDescription,
} from '../../tools';

const { Title, Text } = Typography;

const ChecklistSectionItem = (props) => {
  const {
    item,
    sectionId,
    isEditingExtra,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    checkList,
    onEraseItem,
  } = props;

  return (
    (isEditingExtra(item.isComplete))
      && (
      <Row key={item.arrayId} className="mt-3">
        <Row>
          <Col style={{ textAlign: 'center' }} span={6}>
            <Avatar
              style={{ backgroundColor: 'transparent' }}
              size="large"
              icon="check"
            />
          </Col>
          <Col
            span={15}
            className={!item.isComplete ? 'incomplete-field' : ''}
            data-cy="test-title-title-checklistSection"
          >
            <Title
              className="white-font title-5"
              level={4}
              strong
              onClick={(e) => displayEditable(e)}
              editable={{
                onChange: (event) => onChangeField(
                  item.arrayId, sectionId, event, 'checkList', 'title', validateTitle,
                ),
              }}
            >
              {item.title}
            </Title>
            <Text
              className="white-font"
              onClick={(e) => displayEditable(e)}
              editable={{
                onChange: (event) => onChangeField(
                  item.arrayId, sectionId, event, 'checkList', 'description', validateDescription,
                ),
              }}
              data-cy="test-description-title-checklistSection"
            >
              {item.description}
            </Text>
          </Col>
        </Row>
        <Row
          style={{ marginTop: '10px' }}
          type="flex"
          justify="center"
          align="bottom"
        >
          {checkLimitsAddErase(
            sectionId, false, checkList,
            onEraseItem, 'close-container-checklist', item, 'checkList',
          )}
        </Row>
      </Row>
      )
  );
};

export default ChecklistSectionItem;
