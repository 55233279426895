import React, { Component } from 'react';
import {
  Row,
  Col,
  Typography,
  Modal,
  Card,
  Icon,
} from 'antd';
import SectionItem from '../SectionItem';
import { contentComplete } from '../../../../../../utils/tools';

const { Title } = Typography;
const { Meta } = Card;

const icons = [
  {
    arrayId: 0,
    icon: 'shopping-cart',
    title: 'Shopping Cart',
  },
  {
    arrayId: 1,
    icon: 'cluster',
    title: 'Cluster',
  },
  {
    arrayId: 2,
    icon: 'file-search',
    title: 'Search',
  },
  {
    arrayId: 3,
    icon: 'wechat',
    title: 'Chat',
  },
  {
    arrayId: 4,
    icon: 'clock-circle',
    title: 'Clock',
  },
  {
    arrayId: 5,
    icon: 'setting',
    title: 'Setting',
  },
];

class Section extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      activeIconId: 0,
      activeSectionId: 0,
    };
  }

  showModal = (item, sectionIndex) => {
    this.setState({
      modalVisible: true,
      activeIconId: item.arrayId,
      activeSectionId: sectionIndex,
    });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  createItem = (sectionIndex) => {
    const { sections } = this.props;
    const title = sections[sectionIndex].itemTitleExample
      ? sections[sectionIndex].itemTitleExample : 'Title';
    const description = sections[sectionIndex].itemDescriptionExample
      ? sections[sectionIndex].itemDescriptionExample : 'Description';
    const newInfo = {
      arrayId: 0,
      icon: 'setting',
      title,
      description,
    };

    return newInfo;
  };

  renderSelectableIcon(item, activeIconId, activeSectionId) {
    const { onChangeField } = this.props;

    return (
      <Col span={8} key={item.arrayId}>
        <Card
          hoverable
          cover={(
            <Icon
              type={item.icon}
              className="icon-container-modal"
              data-cy="test-selectable-icon-section"
            />
            )}
          onClick={() => {
            onChangeField(activeIconId, activeSectionId, item.icon, 'sectionList', 'icon');
            this.handleCancel();
          }}
        >
          <Meta
            style={{ textAlign: 'center' }}
            title={item.title}
          />
        </Card>
      </Col>
    );
  }

  render() {
    const {
      itemSection,
      checkLimitsAddErase,
      sectionList,
      onAddItem,
      isEditingExtra,
      editing,
      displayEditable,
      onChangeField,
      onEraseItem,
      preview,
    } = this.props;
    const { modalVisible, activeIconId, activeSectionId } = this.state;
    const contentColumn = {
      xs: { span: 18, offset: 5 },
      sm: { span: 18, offset: 5 },
      md: { span: 14, offset: 4 },
      lg: { span: 14, offset: 3 },
      xl: { span: 14, offset: 3 },
      xxl: { span: 14, offset: 3 },
    };

    return (
      isEditingExtra(contentComplete(itemSection.content))
      && (
      <Row data-cy="test-all-row-section">
        <Row>
          <Col
            {...contentColumn}
            data-cy="test-section-col-section"
          >
            <Title
              className="section-title title-align"
              level={2}
              data-cy="test-section-title-title-section"
            >
              {itemSection.title}
            </Title>
          </Col>
        </Row>
        {
          itemSection.content.map((item) => (
            <SectionItem
              item={item}
              arrayId={itemSection.arrayId}
              editing={editing}
              isEditingExtra={isEditingExtra}
              showModal={this.showModal}
              displayEditable={displayEditable}
              onChangeField={onChangeField}
              checkLimitsAddErase={checkLimitsAddErase}
              sectionList={sectionList}
              onEraseItem={onEraseItem}
            />
          ))
        }
        <Row type="flex" justify="center" align="middle" data-cy="test-row-title-title-section">
          {
            checkLimitsAddErase(
              itemSection.arrayId, true,
              sectionList, onAddItem, 'plus-container', null, 'sectionList', this.createItem,
            )
          }
        </Row>
        {
          !preview
          && (
          <Modal
            width={1000}
            visible={modalVisible}
            title={(
              <Title level={4} className="pd-0 mg-0">
                Select an icon for this section

              </Title>
            )}
            onCancel={this.handleCancel}
            footer={[]}
            destroyOnClose
          >
            <Row gutter={16}>
              {icons.map((item) => (
                this.renderSelectableIcon(item, activeIconId, activeSectionId)))}
            </Row>
          </Modal>
          )
        }
      </Row>
      )
    );
  }
}

export default Section;
