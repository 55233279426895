import {
  Icon,
  notification
} from "antd";
import React from "react";
import * as jwtDecode from "jwt-decode";
import {
  ADMIN,
  INTERNAL_MANAGER,
  INVESTMENT_COMMITTEE
} from "./constants";
import { PATHS } from "./paths";

export const isUndefined = (data) => {
  /*
  * Function to determine if data is undefined
  *
  * @param: {data}: any
  *
  * */
  if (typeof data === 'undefined') {
    return true;
  }
  return false;
};

export const exactEqualList = (firstList, secondList) => {
  /*
  * Function to determine if two list are equal, including order
  *
  * @param: {firstList}: First Array
  * @param: {secondList}: Second Array
  *
  * */
  return JSON.stringify(firstList) === JSON.stringify(secondList);
};

export const contentComplete = (content) => {
  /*
  * Function to determine if a content is complete, only for pdf and OnePager.
  *
  * @param: {content}: Object Array
  *
  * */
  for (let item = 0; item < content.length; item++) {
    if (content[item].isComplete) return true;
  }
  return false;
};

export const manageGraphqlErrors = (graphQLErrors) => {
  /*
  * Function to  manage in case graphql gets a error in catch
  *
  * @param: {graphQLErrors}: Array
  *
  * */
  for (let i = 0; i < graphQLErrors.length; i++) {
    notification['error']({
      message: 'Network Error',
      description: graphQLErrors[i].message,
      duration: 3.5,
    });
  }
};

export const renderBoolean = (data) => {
  /*
  * Function to render a boolean in icon
  *
  * @param: {data}: Boolean
  *
  * */
  if (data) {
    return <Icon
      className="positive-color font-15"
      type="check"/>;
  } else {
    return <Icon
      className="negative-color font-15"
      type="close"/>;
  }
};

export const loginProcess = (access, refresh, type, next=null) => {
  /*
  * Function to login, set localStorage and redirect
  *
  * @param: {access}: String
  * @param: {refresh}: String
  * @param: {type}: String
  * @param: {history}
  * @param: {next} String
  *
  * */
  let expirationAccess = jwtDecode(access).exp;
  let expirationRefresh = jwtDecode(refresh).exp;
  localStorage.setItem('access', access);
  localStorage.setItem('refresh', refresh);
  localStorage.setItem('accessExp', expirationAccess);
  localStorage.setItem('refreshExp', expirationRefresh);
  localStorage.setItem('type', type);
  if (next){
    window.location.replace(next);
  }
  else if (type === ADMIN) {
    window.location.replace(PATHS.dashboardAdmin);
  } else if (type === INVESTMENT_COMMITTEE) {
    window.location.replace(PATHS.dashboardInvestor);
  } else if (type === INTERNAL_MANAGER) {
    window.location.replace(PATHS.dashboardInvestor);
  }
};

export const logoutProcess = () => {
  /*
  * Function to logout process, clean localStorage and redirect
  * */
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('refreshExp');
  localStorage.removeItem('accessExp');
  localStorage.removeItem('type');
  window.location.replace('/login/');
};


export const isNull = (data) => {
   /*
  * Function to determine if data is null
  *
  * @param: {data}: any
  *
  * */
  return data === null;
};