export const chartContainer = {
  xs: { span: 22, offset: 1 },
  md: { span: 20, offset: 2 },
  xl: { span: 15, offset: 2 },
};

export const guideContainer = {
  xs: { span: 22, offset: 1 },
  md: { span: 20, offset: 2 },
  xl: { span: 4, offset: 1 },
};

export const guideItems = {
  xs: { span: 24, offset: 0 },
  lg: { span: 12, offset: 0 },
  xl: { span: 24, offset: 0 },
};

export const searchContainer = {
};

export const selectContainer = {
  xs: { span: 10, offset: 0 },
  md: { span: 8, offset: 0 },
  lg: { span: 6, offset: 0 },
  xl: { span: 5, offset: 0 },
  xxl: { span: 4, offset: 0 },
};

export const buttonsContainer = {
  xs: { span: 24, offset: 0 },
  lg: { span: 12, offset: 0 },
  xxl: { span: 12, offset: 0 },
};