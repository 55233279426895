import React, {Component} from 'react';
import { 
  Col,
  Row 
} from 'antd';
import ContentListData from '../../components/ContentListData';
import {
  imprintSideInfo,
  imprintContentInfo
} from './constants';

import '../../styles/utils.scss';

class ImprintView extends Component {

  render() {
    return (
      <Row className="mt-2 mb-2">
        <Col span={14} offset={5}>
          <Col span={8}>
            <ContentListData title="Imprint" listItem={imprintSideInfo} />
          </Col>
          <Col span={15} offset={1}>
            <ContentListData title="Data Privacy" listItem={imprintContentInfo} />
          </Col>
        </Col>
      </Row>
    );
  }
}

export default ImprintView;
