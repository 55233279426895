import React from 'react';

import {
  graphql,
  Query,
} from "react-apollo";
import {
  Link,
  withRouter
} from 'react-router-dom';
import gql from 'graphql-tag';

import OnePageRenderer from '../onepager/OnePagerRenderer';
import MessageComponent from '../../../components/Message';
import {
  BackTop,
  Col,
  Row,
  Tabs,
  Typography,
  Input,
  Form,
  Button,
  Popconfirm,
  Icon,
  notification
} from "antd";
import AddInvestorColleague from '../../../components/AddInvestorColleague';
import '../../../styles/utils.scss';
import {isUndefined} from 'util';
import { INVESTMENT_COMMITTEE } from '../../../utils/constants';
import { PATHS } from '../../../utils/paths';
import PitchDeck from '../../../components/PitchDeck';
import InitialEvaluation from './InitialEvalutaion';

const TabPane = Tabs.TabPane;
const {Title} = Typography;
const {TextArea} = Input;

const query = gql`
query Query($venture_id: ID!, $member_token: String!, $venture_token: String!){
  sections{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsRightCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsChecklist{
    id
    order
    minItems
    maxItems
    title
    boldTitle
  }
  sectionsProfile{
    id
    order
    minItems
    maxItems
  }
  contentSections(idVenture: $venture_id){
    id
    order
    icon
    title
    description
    isComplete
    section{
      id
    }
  }
  contentSectionsRightCircle(idVenture: $venture_id){
    id
    order
    number
    quantity
    year
    preText
    postText
    title
    isComplete
    rightCircleSection{
      id
    }
  }
  contentSectionsCircle(idVenture: $venture_id){
    id
    order
    initPercentage
    endPercentage
    percentageText
    percentageComplete
    circlesectioncontentSet{
      id
      order
      number
      quantity
      description
      isComplete
    }
    circleSection{
      id
    }
  }
  contentSectionsChecklist(idVenture: $venture_id){
    id
    order
    title
    description
    isComplete
    checklistSection{
      id
    }
  }
  contentSectionsProfile(idVenture: $venture_id){
    id
    order
    name
    linkedin
    isComplete
    profilesectioncontentSet{
      id
      order
      description
    }
    profileSection{
      id
    }

  }
  profilePhotos(idVenture: $venture_id){
    id
    order
    image
  }
  pitchVenture(idVenture: $venture_id){
    pitch
  }
  opinion(memberToken: $member_token, ventureToken: $venture_token){
    id
    member{
      id
      token
      type
      firstName
      lastName
      emailAddress
    }
    venture{
      id
      isReceived
      emailSent
      companyName
      companyNameComplete
      founder{
        id
        firstName
        lastName
      }
    }
    linkUsed
    evaluation
    comment
  }
}
`;

const mutation = gql`
mutation Mutation(
  $idOpinion: Int!,
  $comment: String!,
  $questions: [String],
  $idMember: Int!,
  $idVenture: Int!,
)
  {
  comment(
    idOpinion: $idOpinion,
    comment: $comment,
  )
  {
    status
  }
  receivedEvaluation(
    idOpinion: $idOpinion,
  ){
    status
  }
  createIcQuestions(
    questions: $questions,
    idMember: $idMember,
    idVenture: $idVenture,
  ){
    status
  }
}
`;

const link_used = gql`
query OpinionLinkUsedQuery(
  $member_id: Int!,
  $venture_id: Int!,
) {
  visitLink(
    memberId: $member_id,
    ventureId: $venture_id,
  ){
    id
    email
    linkUsed
    evaluation
    comment
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};

let keyId = 0;

class OpinionForm extends React.Component {
  state = {
    saveLoading: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {idOpinion, updateAfterSent, idMember, idVenture} = this.props;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saveLoading: true});
        let {questions} = values;
        if (typeof questions === "undefined" || typeof questions[0] === "undefined") {
          questions = [];
        }
        this.props
          .mutate({
            variables: {
              idOpinion: idOpinion,
              comment: values.comment,
              questions: questions,
              idMember: idMember,
              idVenture: idVenture
            }
          })
          .then(res => {
            if (res.data.comment.status === 200 && res.data.receivedEvaluation.status === 200) {
              this.setState({saveLoading: false});
              notification['success']({
                message: 'Due Diligence sent!',
                duration: 3.5,
              });
              updateAfterSent();
            }
            if (res.data.errors) {
              notification['error']({
                message: 'An error have occurred',
                duration: 3.5,
              });
              console.log(res.data.errors);
            }
          })
          .catch(err => {
            this.setState({saveLoading: false});
            if (err.graphQLErrors) {
              for (let i = 0; i < err.graphQLErrors.length; i++) {
                notification['error']({
                  message: 'Network Error',
                  description: err.graphQLErrors[i].message,
                  duration: 3.5,
                });
              }
            }
            console.log('Network error');
          });
      }
    });
  };

  removeQuestion = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  addQuestion = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(keyId++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };


  render() {
    const {comment} = this.props;
    const {saveLoading} = this.state;
    const {getFieldDecorator, getFieldValue} = this.props.form;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const questionItems = keys.map((key, index) => (
      <Form.Item
          label={index === 0 ? "Send your questions (not obligatory)" : ""}
          required={false}
          key={key}
      >
        <Row gutter={32}>
          <Col span={22}>
            {getFieldDecorator(`questions[${key}]`, {
              rules:[{
                required: keys.length > 1,
                message: 'Fill this question'
              }]
            })(
                <Input placeholder="Question" />
            )}
          </Col>
          <Col span={2}>
            {keys.length > 1 ? (
                <Icon
                    type="minus-circle-o"
                    onClick={() => this.removeQuestion(key)}
                />
            ) : null}
          </Col>
        </Row>
      </Form.Item>
    ));

    return (
      <Form className="login-form">

        <Row style={{marginTop: '50px'}} type="flex" justify="space-around" align="middle">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {questionItems}
            <Form.Item>
              <Button onClick={this.addQuestion}>
                <Icon type="plus" /> Add question
              </Button>
            </Form.Item>
            <Form.Item
              label="Send your evaluation to finish this process"
            >
              {getFieldDecorator('comment', {
                initialValue: (isUndefined(comment) || (comment === null)) ? "" : comment,
              })(
                <TextArea
                  placeholder="Share a comment about this venture (not obligatory)"
                  autosize={{minRows: 3, maxRows: 10}}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{marginBottom: '50px', marginTop: '30px'}} type="flex" justify="space-around" align="middle"
             gutter={48}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item>
              <Popconfirm
                title="You are about to send your evaluation. After this you will not be able to edit this anymore."
                onConfirm={this.handleSubmit}
                okText="Yes, send it"
                cancelText="Not yet">
                <Button type="primary" htmlType="submit"
                        className="login-form-button"
                        loading={saveLoading}>
                  Send
                </Button>
              </Popconfirm>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    );
  }
}


OpinionForm = withRouter(OpinionForm);
OpinionForm = graphql(mutation)(OpinionForm);
const WrappedOpinionForm = Form.create({name: 'opinion_add'})(OpinionForm);


class OpinionView extends React.Component {

  state = {
    current: "one-pager",
    updateOnSent: false,
  };

  updateAfterSent = () => {
    this.setState({
      updateOnSent: true,
    });
  };

  changeTab = (newValue) => {
    this.setState({
      current: newValue
    });
  };

  render() {
    const {current, updateOnSent} = this.state;
    let venture_id = this.props.venture.id;
    let venture_token = this.props.match.params.token;
    let member_token = this.props.match.params.memberToken;
    let admin = false;

    return (
      <Query query={query} variables={{venture_id, member_token, venture_token}}>
        {({loading, error, data, refetch}) => {
          const shouldShowLoading = loading && !data.venture;
          if (shouldShowLoading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text=""
              level={4}/>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );

          data.sections = addKeys(data.sections);
          data.sectionsRightCircle = addKeys(data.sectionsRightCircle);
          data.sectionsCircle = addKeys(data.sectionsCircle);
          data.sectionsChecklist = addKeys(data.sectionsChecklist);
          data.sectionsProfile = addKeys(data.sectionsProfile);
          data.contentSections = addKeys(data.contentSections);
          data.contentSectionsRightCircle = addKeys(data.contentSectionsRightCircle);
          data.contentSectionsCircle = addKeys(data.contentSectionsCircle);
          data.contentSectionsChecklist = addKeys(data.contentSectionsChecklist);
          data.contentSectionsProfile = addKeys(data.contentSectionsProfile);
          data.profilePhotos = addKeys(data.profilePhotos);

          let manager = data.opinion.member.type === 'IM';
          let member_id = data.opinion.member.id;

          const queryFirstTime =
            < Query
              query={link_used}
              variables={
                {
                  member_id,
                  venture_id,
                }
              }
            >
              {
                () => {
                  return null;
                }
              }
            </Query>;

          if (data.opinion.evaluation || updateOnSent) {
            return (
              <div>
                <MessageComponent
                  icon="check-circle"
                  iconSize={100}
                  theme="twoTone"
                  twoToneColor="#52c41a"
                  text="Thank you! Your opinion has been successfully submitted.
                  We will inform you the final result once the procedure is conducted."
                  level={4}
                />
                {localStorage.getItem('type') === INVESTMENT_COMMITTEE && (
                  <Row type="flex" align="center" className="my-2">
                    <Col span={6}>
                      <Link to={PATHS.dashboardInvestor}>
                        <Button type="primary" className="text-center w-100">Go to dashboard</Button>
                      </Link>
                    </Col>
                  </Row>
                )}
              </div>
            );
          }

          let pre_evaluation = true;
          const { venture } = data.opinion;
          const pitch = data.pitchVenture !== null ? data.pitchVenture.pitch : null;
          const pitchUpload =  !venture.emailSent && pitch !== null;

          return (
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                <BackTop/>
                {data.opinion.linkUsed ? null : queryFirstTime}
                <Title className="dashboard-title" level={1}>
                  {this.props.venture.companyName}
                </Title>
                <Title className="dashboard-title" level={4}>
                  {data.opinion.member.firstName} {data.opinion.member.lastName}
                </Title>

                <Tabs
                  className="dashboard-table"
                  defaultActiveKey="1"
                  activeKey={current}
                  onChange={this.changeTab}
                >
                  <TabPane tab={pitchUpload ? "Pitch Deck" : "One Pager"} key="one-pager">
                    { data.opinion.linkUsed && (
                        !pitchUpload 
                        ? <OnePageRenderer
                          sections={data.sections}
                          sectionsRightCircle={data.sectionsRightCircle}
                          sectionsCircle={data.sectionsCircle}
                          sectionsChecklist={data.sectionsChecklist}
                          sectionsProfile={data.sectionsProfile}
                          contentSections={data.contentSections}
                          contentSectionsRightCircle={data.contentSectionsRightCircle}
                          contentSectionsCircle={data.contentSectionsCircle}
                          contentSectionsChecklist={data.contentSectionsChecklist}
                          contentSectionsProfile={data.contentSectionsProfile}
                          profilePhotos={data.profilePhotos}
                          pitchVenture={data.pitchVenture}
                          venture={this.props.venture}
                          preview={true}
                        />
                        : <PitchDeck pitch={pitch} />
                      )
                    }
                  </TabPane>
                  <TabPane tab="Initial Evaluation" key="initial">
                    <InitialEvaluation
                      pre_evaluation={pre_evaluation}
                      admin={admin}
                      manager={manager}
                      member_token={member_token}
                      venture={this.props.venture}
                      changeTab={this.changeTab}
                    />
                  </TabPane>
                  {!manager && (
                      <TabPane tab="Invite Colleague" key="invite">
                          <AddInvestorColleague
                              idVenture={venture_id}
                              location={this.props.location}
                              memberToken={member_token}
                              changeTab={this.changeTab}
                              newTab={'results'}
                          />
                      </TabPane>
                  )}
                  <TabPane tab="Send" key="results">
                    <WrappedOpinionForm
                      idOpinion={data.opinion.id}
                      comment={data.opinion.comment}
                      updateAfterSent={this.updateAfterSent}
                      idMember={member_id}
                      idVenture={venture_id}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          );
        }}
      </Query>
    );
  }
}

export default OpinionView;