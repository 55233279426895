import React from 'react';
import gql from 'graphql-tag';
import {
  Query,
  Mutation
} from 'react-apollo';

import {
  Row,
  Col,
  Table,
  Button,
  Icon,
  Tabs,
  Breadcrumb,
  notification,
  Modal,
  Input,
  Switch
} from 'antd';
import MessageComponent from "../Message";
import UploadPitch from './UploadPitch';
import DownloadPdf from '../DownloadPdf';

import {Typography} from 'antd';
import {Link} from "react-router-dom";

import '../../styles/utils.scss';

const TabPane = Tabs.TabPane;

const {Title} = Typography;
const {TextArea} = Input;


const query = gql`
query DetailView($id_founder: ID!) {
  founder(id: $id_founder) {
    id
    firstName
    lastName
    emailAddress
    ventureopportunitySet{
      id
      companyName
      emailSent
      emailSentManagers
      emailSentInvestment
      isReceived
      initialEvaluation
      detailedEvaluation
    }
  }
}
`;

const allVentures = gql`
query allVentures {
  allVentures{
    archived{
      id
      companyName
      emailSent
      emailSentManagers
      emailSentInvestment
      isReceived
      adminEvaluation
      initialEvaluation
      detailedEvaluation
      pitchventure{
        pitch
      }
    }
    notArchived{
      id
      companyName
      emailSent
      emailSentManagers
      emailSentInvestment
      isReceived
      adminEvaluation
      initialEvaluation
      detailedEvaluation
      pitchventure{
        pitch
      }
    }
  }
}
`;

const founderVentures = gql`
query DetailView($id_founder: ID!) {
  allVenturesFounder(founderId: $id_founder){
    archived{
      id
      companyName
      emailSent
      emailSentManagers
      emailSentInvestment
      isReceived
      adminEvaluation
      initialEvaluation
      detailedEvaluation
      pitchventure{
        pitch
      }
    }
    notArchived{
      id
      companyName
      emailSent
      emailSentManagers
      emailSentInvestment
      isReceived
      adminEvaluation
      initialEvaluation
      detailedEvaluation
      pitchventure{
        pitch
      }
    }
  }
}
`;

const founderInvitation = gql`
mutation Mutation($id: Int!, $message: String) {
  sendVentureEmail(id: $id, message: $message) {
    status
    founder {
      id
    }
  }
}
`;

const memberInvitation = gql`
mutation Mutation($type: String!, $venture: Int!) {
  sendMemberEmail(type: $type, venture: $venture) {
    status
    opinions {
      id
      venture{
        id
        companyName
      }
      member{
        firstName
        lastName
        emailAddress
      }
    }
  }
}
`;

const addKeys = (content, prefix) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = prefix + i;
  }
  return content;
};


class ButtonLoading extends React.Component {

  state = {
    saveLoading: false,
    icon: this.props.icon,
    type: this.props.type,
    text: this.props.text,
    data: this.props.data,
    disabled: this.props.disabled,
    category: this.props.category,
    visible: false,
    confirmLoading: false,
    founderCustomContent: false,
    customContent: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (ventureId, mutationFunction, category) => {
    this.setState({
      confirmLoading: true,
    });
    this.enterIconLoading(ventureId, mutationFunction, category);
    this.setState({
      confirmLoading: false,
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  enterIconLoading = (ventureId, mutationFunction, category) => {
    this.setState({saveLoading: true});

    let mutationVariables = null;

    if (category === 'founder') {
      mutationVariables = {
        variables: {
          id: ventureId,
          message: this.state.customContent
        }
      };
    }
    if (category === 'manager') {
      mutationVariables = {variables: {venture: ventureId, type: 'IM'}};
    }
    if (category === 'investment') {
      mutationVariables = {variables: {venture: ventureId, type: 'IC'}};
    }

    mutationFunction(mutationVariables)
      .then(res => {

        let resVariable = null;
        if (category === 'founder') {
          resVariable = res.data.sendVentureEmail;
        }
        if (category === 'manager' || category === 'investment') {
          resVariable = res.data.sendMemberEmail;
        }

        if (resVariable.status === 200) {
          this.setState({saveLoading: false});
          this.setState({
            icon: 'reload',
            type: 'default',
            text: 'Resend',
          });
          notification['success']({
            message: 'Email sent!',
            duration: 3.5,
          });
        } else {
          this.setState({saveLoading: false});
          notification['error']({
            message: 'An error have occurred',
            duration: 3.5,
          });
        }
      })
      .catch(err => {
        if (err.graphQLErrors) {
          for (let i = 0; i < err.graphQLErrors.length; i++) {
            notification['error']({
              message: 'Network Error',
              description: err.graphQLErrors[i].message,
              duration: 3.5,
            });
          }
        }
        console.log(err);
        this.setState({saveLoading: false});
        console.log('Network error');
      });
  };

  render() {
    const {text, data, icon, type, disabled} = this.state;
    const {saveLoading, category, visible, confirmLoading, founderCustomContent, customContent} = this.state;

    if (category === 'founder') {
      return (
        <Mutation mutation={founderInvitation}>
          {(sendVentureEmail, {mutationData}) => (
            <div>
              <Button
                disabled={disabled}
                key={data.id}
                type={type}
                icon={icon}
                loading={saveLoading}
                onClick={this.showModal}
                test-attr="test-button-send"
              >
                {text}
              </Button>
              <Modal
                title="Custom Email for Founder"
                visible={visible}
                onOk={(event) => this.handleOk(data.id, sendVentureEmail, category, event)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                  <Button
                    key="back"
                    onClick={this.handleCancel}
                    test-attr="test-modal-cancel-button"
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={confirmLoading}
                    onClick={(event) => this.handleOk(data.id, sendVentureEmail, category, event)}
                    test-attr="test-modal-send-button"
                  >
                    Send
                  </Button>,
                ]}
              >
                <p>
                  <Switch
                    size="small"
                    onChange={(checked => {
                      this.setState({
                        founderCustomContent: checked,
                        customContent: ''
                      })
                    })
                    }
                  /> Add additional content for the message
                </p>
                <TextArea
                  rows={4}
                  disabled={!founderCustomContent}
                  defaultValue={customContent}
                  onChange={event => {
                    this.setState({customContent: event.target.value})
                  }}
                />
              </Modal>
            </div>
          )}
        </Mutation>
      );
    }
    if (category === 'manager' || category === 'investment') {
      return (
        <Mutation mutation={memberInvitation}>
          {(sendVentureEmail, {mutationData}) => (
            <Button
              disabled={disabled}
              key={data.id}
              type={type}
              icon={icon}
              loading={saveLoading}
              onClick={(event) => this.enterIconLoading(data.id, sendVentureEmail, category, event)}>
              {text}
            </Button>
          )}
        </Mutation>
      );
    }
  }
}


class VentureComponent extends React.Component {

  renderInvitation(text, data, category) {
    let icon = "mail";
    let type = "primary";
    let buttonText = "Send";
    let disabled = false;
    let memberType = "";
    let extraText = undefined;
    if (category === 'founder') {
      if (data.emailSent) {
        icon = "reload";
        type = "default";
        buttonText = "Resend";
      }
    }
    if (category === 'manager') {
      memberType = "IM";
      if (data.emailSentManagers) {
        icon = "reload";
        type = "default";
        buttonText = "Manage notifications";
        extraText = "Evaluation form Sent";
      } else {
        buttonText = "Send Custom Notifications"
      }
      if (data.adminEvaluation !== 'Y') {
        disabled = true;
      }
    }
    if (category === 'investment') {
      memberType = "IC";
      if (data.emailSentInvestment) {
        icon = "reload";
        type = "default";
        buttonText = "Manage notifications";
        extraText = "Evaluation form Sent";
      } else {
        buttonText = "Send Custom Notifications"
      }
      if (data.initialEvaluation !== 'Y') {
        disabled = true;
      }
    }
    return (
      <div key={"VentureComponent"+ data.id}>
        {memberType !== "" ? (
          [
            <Link
              key={"VentureComponentLink"+ data.id}
              to={`/dashboard/venture/${data.id}/manager_email/${memberType}/`}>
              <Button
                className="my-1"
                test-attr="test-button-send"
                disabled={disabled}
              >
                {buttonText}
              </Button>
            </Link>,
            <span
              key="VentureComponentSpan">{typeof extraText !== "undefined" ? extraText : ""}</span>
          ]
        ) : (
          <ButtonLoading
            disabled={disabled}
            key={"data-loading" + data.id}
            data={data}
            icon={icon}
            type={type}
            text={buttonText}
            category={category}
          />
        )}
      </div>
    );
  }

  renderReceived(text, data) {
    if (data.isReceived) {
      return (
        <Icon
          className="positive-color"
          type="check"/>
      );
    } else {
      return (
        <Icon
          className="negative-color"
          type="close"/>
      );
    }
  }

  getEvaluationDisplay(value) {
    if (value === 'Y') {
      return (
        <Icon
          className="positive-color"
          type="check"/>
      );

    }
    if (value === 'N') {
      return (
        <Icon
          className="negative-color"
          type="close"/>
      );
    }
    if (value === 'ND') {
      return (
        <Icon
          type="minus"/>
      );
    }
  }

  renderVentures(id_founder) {
    const columns = [
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        align: 'center',
        children: [{
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          render: (text, data) => (
            <Link
              to={`/dashboard/founder/venture/${data.id}/`}>{data.companyName}
            </Link>
          )
        }, {
          title: 'Received',
          dataIndex: 'received',
          key: 'received',
          align: 'center',
          render: (text, data) => (
            this.renderReceived(text, data)
          )
        }],

      },
      {
        title: 'Evaluation',
        dataIndex: 'evaluation',
        key: 'evaluation',
        align: 'center',
        children: [{
          title: 'Admin',
          dataIndex: 'admin',
          key: 'admin',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.adminEvaluation)
          )
        }, {
          title: 'Initial',
          dataIndex: 'initial',
          key: 'initial',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.initialEvaluation)
          )
        }, {
          title: 'Detailed',
          dataIndex: 'detailed',
          key: 'detailed',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.detailedEvaluation)
          )
        }],
      },
      {
        title: 'Invitations',
        dataIndex: 'invitations',
        key: 'invitations',
        align: 'center',
        children: [{
          title: 'Founder',
          dataIndex: 'founder',
          key: 'founder',
          align: 'center',
          render: (text, data) => {
            const pitch = data.pitchventure !== null ? data.pitchventure.pitch : null;
            const pitchUpload = !data.emailSent && pitch !== null;
            return (
              [pitchUpload ? undefined : this.renderInvitation(text, data, "founder"),
                pitchUpload ? <DownloadPdf title="Download Pitch"
                                           pdf={pitch}/> : undefined,
                data.emailSent ? undefined :
                  <UploadPitch disabled={data.emailSent} ventureId={data.id}/>]
            );
          }
        }, {
          title: 'Internal Managers',
          dataIndex: 'internal',
          key: 'internal',
          align: 'center',
          render: (text, data) => (
            this.renderInvitation(text, data, "manager")
          )
        }, {
          title: 'Investment Committee',
          dataIndex: 'investment',
          key: 'investment',
          align: 'center',
          render: (text, data) => (
            this.renderInvitation(text, data, "investment")
          )
        }],
      },
    ];
    return (
      <Query
        query={founderVentures}
        variables={
          {
            id_founder: id_founder,
          }
        }
        pollInterval={500}
      >
        {({loading, error, data, refetch}) => {
          if (loading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text="Loading"
              level={4}/>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );

          data.allVenturesFounder.notArchived = (data.allVenturesFounder.notArchived, "notArchived");
          data.allVenturesFounder.archived = addKeys(data.allVenturesFounder.archived, "archived");

          let countNotArchived = data.allVenturesFounder.notArchived.length;
          let countArchived = data.allVenturesFounder.archived.length;

          return (
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab={`Active Ventures (${countNotArchived})`} key={1}>
                <Table
                  columns={columns}
                  dataSource={data.allVenturesFounder.notArchived}
                  pagination={{pageSize: 50}}
                  bordered
                />
              </TabPane>
              <TabPane tab={`Archived Ventures (${countArchived})`} key={2}>
                <Table
                  columns={columns}
                  dataSource={data.allVenturesFounder.archived}
                  pagination={{pageSize: 50}}
                  bordered
                />
              </TabPane>
            </Tabs>
          );
        }}
      </Query>

    );
  }

  renderAllVentures() {
    const columns = [
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        align: 'center',
        children: [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, data) => (
              <Link
                to={`/dashboard/founder/venture/${data.id}/`}>{data.companyName}
              </Link>
            )
          }, {
            title: 'Received',
            dataIndex: 'received',
            key: 'received',
            align: 'center',
            render: (text, data) => (
              this.renderReceived(text, data)
            )
          }],
      },
      {
        title: 'Evaluation',
        dataIndex: 'evaluation',
        key: 'evaluation',
        align: 'center',
        children: [{
          title: 'Admin',
          dataIndex: 'admin',
          key: 'admin',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.adminEvaluation)
          )
        }, {
          title: 'Initial',
          dataIndex: 'initial',
          key: 'initial',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.initialEvaluation)
          )
        }, {
          title: 'Detailed',
          dataIndex: 'detailed',
          key: 'detailed',
          align: 'center',
          render: (text, data) => (
            this.getEvaluationDisplay(data.detailedEvaluation)
          )
        }],
      },
      {
        title: 'Invitations',
        dataIndex: 'invitations',
        key: 'invitations',
        align: 'center',
        children: [{
          title: 'Founder',
          dataIndex: 'founder',
          key: 'founder',
          align: 'center',
          render: (text, data) => {
            const pitch = data.pitchventure !== null ? data.pitchventure.pitch : null;
            const pitchUpload = !data.emailSent && pitch !== null;
            return (
              [pitchUpload ? undefined : this.renderInvitation(text, data, "founder"),
                pitchUpload ? <DownloadPdf title="Download Pitch"
                                           pdf={pitch}/> : undefined,
                data.emailSent ? undefined :
                  <UploadPitch key="uploadPitchColumn" disabled={data.emailSent} ventureId={data.id}/>]
            );
          }
        }, {
          title: 'Internal Managers',
          dataIndex: 'internal',
          key: 'internal',
          align: 'center',
          render: (text, data) => (
            this.renderInvitation(text, data, "manager")
          )
        }, {
          title: 'Investment Committee',
          dataIndex: 'investment',
          key: 'investment',
          align: 'center',
          render: (text, data) => (
            this.renderInvitation(text, data, "investment")
          )
        }, {
          title: 'Custom invitation',
          dataIndex: 'custom',
          key: 'custom',
          align: 'center',
          render: (text, data) => (
            <Link to={`/dashboard/member/guest/${data.id}/list/`}>
              <Button>
                Manage guests
              </Button>
            </Link>
          )
        }],
      },
    ];
    return (
      <Query
        query={allVentures}
        pollInterval={500}
      >
        {({loading, error, data, refetch}) => {
          if (loading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text="Loading"
              level={4}/>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );

          data.allVentures.notArchived = addKeys(data.allVentures.notArchived, "notArchived");
          data.allVentures.archived = addKeys(data.allVentures.archived, "archived");

          let countNotArchived = data.allVentures.notArchived.length;
          let countArchived = data.allVentures.archived.length;

          return (
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab={`Active Ventures (${countNotArchived})`} key={1}>
                <Table
                  columns={columns}
                  dataSource={data.allVentures.notArchived}
                  pagination={{pageSize: 50}}
                  bordered
                />
              </TabPane>
              <TabPane tab={`Archived Ventures (${countArchived})`} key={2}>
                <Table
                  columns={columns}
                  dataSource={data.allVentures.archived}
                  pagination={{pageSize: 50}}
                  bordered
                />
              </TabPane>
            </Tabs>
          );
        }}
      </Query>
    );
  }

  render() {
    const {id_founder} = this.props;

    if (id_founder) {
      return (
        <Query
          query={query}
          variables={
            {
              id_founder
            }
          }
          pollInterval={500}
        >
          {({loading, error, data, refetch}) => {
            if (loading) return (
              <MessageComponent
                icon="loading"
                iconSize={50}
                text="Loading"
                level={4}/>
            );
            if (error) return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}/>
            );

            data.allFounders = addKeys(data.founder.ventureopportunitySet, "AllFounder");

            return (
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} sm={22} md={22} lg={22} xl={20} xxl={20}>
                  <Breadcrumb className="dashboard-breadcrumb">
                    <Breadcrumb.Item>
                      <Link to="/dashboard/founder/list/">
                        Founders
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {data.founder.firstName} {data.founder.lastName}
                    </Breadcrumb.Item>
                  </Breadcrumb>

                  <Title className="dashboard-title"
                         level={1}>{data.founder.firstName} {data.founder.lastName}</Title>

                  <Title className="dashboard-title dashboard-button"
                         level={4}>{data.founder.emailAddress}</Title>

                  {this.renderVentures(id_founder)}

                </Col>
              </Row>

            );
          }}
        </Query>
      );
    } else {
      return (
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={22} sm={22} md={22} lg={22} xl={20} xxl={20}>
            <Breadcrumb className="dashboard-breadcrumb">
              <Breadcrumb.Item>
                <Link to="/dashboard/venture/list/">
                  Ventures
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Title className="dashboard-title" level={1}>Ventures</Title>
            {this.renderAllVentures()}
          </Col>
        </Row>
      );
    }


  }
}

export default VentureComponent;
