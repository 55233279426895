import React, { Component } from 'react';
import {
  Row,
  Button,
} from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OnePagerPDF from '../../../../../pdf/OnePager';

const forceDownload = (blob, filename) => {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

class PdfButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderPDF: false,
    };
  }

  render() {
    const { values } = this.props;
    const { renderPDF } = this.state;
    const fileName = `Fincite_Ventures_${values.companyName}.pdf`;
    return (
      <Row>
        <Row>
          <Button
            onClick={() => {
              this.setState({
                renderPDF: true,
              });
            }}
          >
            {renderPDF ? 'Loading document...' : 'Download this one-pager in pdf format'}
          </Button>
        </Row>
        {
          values && renderPDF
          && (
          <PDFDownloadLink
            document={<OnePagerPDF data={values} />}
            fileName={fileName}
          >
            {(item) => {
              const { url, loading } = item;
              if (loading) {
                return;
              }
              forceDownload(url, `Fincite_Ventures_${values.companyName}.pdf`);
              this.setState({
                renderPDF: false,
              });
              return;
            }}
          </PDFDownloadLink>
          )
        }
      </Row>
    );
  }
}

export default PdfButton;
