import React from 'react';
import gql from 'graphql-tag';
import {
    graphql,
    Query
} from 'react-apollo';
import {
    Button,
    Col,
    Icon,
    Row,
    Table,
    notification
} from 'antd';
import {WrappedAddInvestmentForm} from '../../views/dashboard/member/InvestmentAddView';
import MessageComponent from '../Message';
import '../../styles/utils.scss';

const guest_query = gql`
query GuestList($ventureId: ID!, $addByToken: String!) {
  guests(ventureId: $ventureId, addByToken:$addByToken) {
    id
    firstName
    lastName
    emailAddress
  }
}
`;

const sendEmail = gql`
mutation Mutation($ventureId: Int!, $membersId: [MemberIdMessage]!) {
  sendMemberEmail(venture: $ventureId, membersId:$membersId) {
    status
  }
}
`;

class AddInvestorColleague extends React.Component {
    state = {
        addInvestor: false,
    };

    afterSave = (memberId, message="") => {
        const {idVenture} = this.props;
        const memberStructure = {
            id: memberId,
            message: message
        };
        this.props.mutate({
            variables: {
                membersId: [memberStructure],
                ventureId: idVenture
            }
        }).then(res => {
            this.setState({saveLoading: false});
            if (res.data.sendMemberEmail.status === 200) {
                notification['success']({
                    message: 'Email sent!',
                    duration: 3.5,
                });
            }
            if (res.data.errors) {
                notification['error']({
                    message: 'An error have occurred',
                    duration: 3.5,
                });
            }
        }).catch(err => {
            this.setState({saveLoading: false});
            if (err.graphQLErrors) {
                for (let i = 0; i < err.graphQLErrors.length; i++) {
                    notification['error']({
                        message: 'Network Error',
                        description: err.graphQLErrors[i].message,
                        duration: 3.5,
                    });
                }
            }
            console.log('Network error');
        });
        this.setState({addInvestor: false});
    };

    render() {
        const {addInvestor} = this.state;
        const {idVenture, memberToken, changeTab, newTab} = this.props;
        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
            {
                title: 'Email Address',
                dataIndex: 'emailAddress',
                key: 'emailAddress',
            },
            {
                title: 'Email sent',
                key: 'emailSent',
                render: (text, data) => (
                    <Icon className="positive-color" type="check" />
                )
            }
        ];
        return (
            <div>
                <Row type="flex" justify="space-around" align="middle">
                    {addInvestor ? (
                        <Col span={10} className="dashboard-table">
                            <WrappedAddInvestmentForm
                                isGuest={true}
                                idVenture={idVenture}
                                afterSave={this.afterSave}
                                memberToken={memberToken}
                            />
                        </Col>
                    ) : (
                        <Col span={20}>
                            <Button className="my-1"
                                    type="primary"
                                    onClick={(event) => {
                                        this.setState({addInvestor: true});
                                    }}
                            >
                                <Icon type="plus" /> Add
                            </Button>
                            <Query
                                query={guest_query}
                                variables={{ventureId: idVenture, addByToken:memberToken}}
                                fetchPolicy="network-only"
                            >
                                {({loading, error, data, refetch}) => {
                                    if (loading) return (
                                        <MessageComponent
                                            icon="loading"
                                            iconSize={50}
                                            text="Loading"
                                            level={4}
                                        />
                                    );
                                    if (error) return (
                                        <MessageComponent
                                            icon="smile"
                                            iconSize={50}
                                            text={`${error.message}`}
                                            level={4}
                                        />
                                    );
                                    if (this.props.location.state !== undefined) {
                                        refetch();
                                    }
                                    return (
                                        <Table
                                            columns={columns}
                                            dataSource={data.guests}
                                            pagination={{pageSize: 50}}
                                            bordered
                                        />
                                    );
                                }}
                            </Query>
                        </Col>
                    )}
                </Row>
                <Row type="flex" justify="center" className="my-1">
                    <Col span={4}>
                        <Button type="primary" className="w-100" onClick={e => changeTab(newTab)}>
                            Skip
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AddInvestorColleague = graphql(sendEmail)(AddInvestorColleague);