import React from 'react';
import gql from 'graphql-tag';
import {
  graphql,
  Query,
} from 'react-apollo';

import {
  Row,
  Col,
  Typography,
  Button,
  Icon,
  Breadcrumb,
  Modal,
  Form,
  Input,
  Select,
  notification
} from 'antd';

import {Link} from 'react-router-dom';
import MessageComponent from '../../../components/Message';
import '../../../styles/utils.scss';
import ManageEmail from '../../../components/ManageEmail';
import { mainContainerBody } from '../../../styles/responsive';

const {Title} = Typography;
const {Option} = Select;

const query = gql`
query GuestList($ventureId: ID!) {
  guests(ventureId: $ventureId) {
    id
    firstName
    lastName
    emailAddress
    typeDisplay
    memberopinionSet {
      venture {
        id
      }
      email
      evaluation
    }
  }
  venture(id: $ventureId) {
    id
    companyName
  }
}
`;

const mutation = gql`
mutation Mutation(
  $emailAddress: String!,
  $firstName: String!,
  $lastName: String!,
  $type: String!
  $ventureId: Int!,
) {
  addGuest(
    emailAddress: $emailAddress,
    firstName: $firstName,
    lastName: $lastName,
    type: $type,
    venture: $ventureId,
  ) {
    status
    guest {
      id
      firstName
      lastName
      emailAddress
      typeDisplay
      memberopinionSet {
        venture {
          id
        }
        email
        evaluation
      }
    }
  }
}
`;

class GuestListView extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    guests: [],
    companyName: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        confirmLoading: true,
      });
      if(!err){
        let {guests} = this.state;
        this.props
            .mutate({
              variables: {
                ...values,
                ventureId: this.props.match.params.id,
              }
            })
            .then(res => {
              if (res.data.addGuest.status === 200) {
                guests.push(res.data.addGuest.guest);
                this.setState({confirmLoading: false, visible:false, guests});
                notification['success']({
                  message: 'Success!',
                  description: "Investment member created",
                  duration: 3.5,
                });
              }
              if (res.data.errors) {
                this.setState({confirmLoading: false});
                notification['error']({
                  message: 'An error have occurred',
                  duration: 3.5,
                });
                console.log(res.data.errors);
              }
            })
            .catch(err => {
              this.setState({confirmLoading: false});
              if (err.graphQLErrors) {
                for (let i = 0; i < err.graphQLErrors.length; i++) {
                  notification['error']({
                    message: 'Network Error',
                    description: err.graphQLErrors[i].message,
                    duration: 3.5,
                  });
                }
              }
              console.log('Network error');
            });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, confirmLoading } = this.state;
    const {getFieldDecorator} = this.props.form;
    const ventureId = this.props.match.params.id;
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
      {
        title: 'Type',
        dataIndex: 'typeDisplay',
        key: 'type',
      },
      {
        title: 'Email Sent',
        dataIndex: 'memberopinionSet',
        key: 'emailSent',
        align: 'center',
        render: (text, data) => {
          const memberOpinion = data.memberopinionSet.filter(item => item.venture.id === ventureId)[0];
          const sent = memberOpinion.email;

          return (
            memberOpinion &&
            (
              sent 
              ? <Icon 
                className={"positive-color"}
                type={"check"}
              />
              : <Icon 
                className={"negative-color"}
                type={"close"}
              />
            )
          );
        }
      },
      {
        title: 'Evaluation Completed',
        dataIndex: 'memberopinionSet',
        key: 'evaluationCompleted',
        align: 'center',
        render: (text, data) => {
          const memberOpinion = data.memberopinionSet.filter(item => item.venture.id === ventureId)[0];
          const sent = memberOpinion.evaluation;

          return (
            memberOpinion &&
            (
              sent 
              ? <Icon 
                className={"positive-color"}
                type={"check"}
              />
              : <Icon 
                className={"negative-color"}
                type={"close"}
              />
            )
          );
        }
      },
    ];
    const addGuestButton = (
        <Button className="mx-10px" type="primary" onClick={this.showModal}>
          <Icon type="plus" /> Add
        </Button>
    );

    return (
      <Row type="flex" justify="space-around" align="middle">
        <Col {...mainContainerBody}>
          <Breadcrumb className="dashboard-breadcrumb">
            <Breadcrumb.Item>
              <Link to="/dashboard/venture/list/">
                Ventures
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Guests
            </Breadcrumb.Item>
          </Breadcrumb>
          <Modal
              title="Add a guest"
              visible={visible}
              onOk={this.handleOk}
              confirmLoading={confirmLoading}
              onCancel={this.handleCancel}
          >
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item
                  label="First Name"
              >
                {getFieldDecorator('firstName', {
                  rules: [{
                    required: true,
                    message: 'First Name is required'
                  }],
                })(
                    <Input
                        prefix={<Icon type="user" className="text-black-opacity-lite" />}
                        placeholder="Manager first name"/>
                )}
              </Form.Item>
              <Form.Item
                  label="Last Name"
              >
                {getFieldDecorator('lastName', {
                  rules: [{
                    required: true,
                    message: 'Last Name is required'
                  }],
                })(
                    <Input
                        prefix={<Icon type="user" className="text-black-opacity-lite" />}
                        placeholder="Manager last name"/>
                )}
              </Form.Item>
              <Form.Item
                  label="Email Address"
              >
                {getFieldDecorator('emailAddress', {
                  rules: [{
                    type: 'email',
                    message: 'Email Address is not a valid email'
                  }, {
                    required: true,
                    message: 'Email Address is required'
                  }],
                })(
                    <Input
                        prefix={<Icon type="mail" className="text-black-opacity-lite" />}
                        placeholder="example@mail.com"/>
                )}
              </Form.Item>
              <Form.Item
                  label="Type"
              >
                {getFieldDecorator('type', {
                  initialValue: "IM"
                })(
                    <Select>
                      <Option value="IM">Internal manager</Option>
                      <Option value="IC">Investment committee</Option>
                    </Select>
                )}
              </Form.Item>
            </Form>
          </Modal>

          <Title className="dashboard-title" level={1}>
            Guests for {this.state.companyName}
          </Title>

          <Query query={query} variables={{ventureId}}>
            {({loading, error, data, refetch}) => {
              if (loading) return (
                <MessageComponent
                  icon="loading"
                  iconSize={50}
                  text="Loading"
                  level={4}/>
              );
              if (error) return (
                <MessageComponent
                  icon="smile"
                  iconSize={50}
                  text={`${error.message}`}
                  level={4}/>
              );
              if (this.props.location.state !== undefined) {
                refetch();
              }
              if (this.state.guests !== data.guests)
                this.setState({
                  guests: data.guests,
                  companyName: data.venture.companyName
                });
              return (
                  <ManageEmail
                      columns={columns}
                      data={data.guests}
                      ventureId={ventureId}
                      extraButton={addGuestButton}
                      updateQuery={this.updateQuery}
                  />
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

GuestListView = graphql(mutation)(GuestListView);
const WrappedGuestListViewForm = Form.create({name: 'guest_view'})(GuestListView);
export default WrappedGuestListViewForm;
