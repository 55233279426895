import gql from 'graphql-tag';

export const MUTATIONS = {
  setPassword: gql`
    mutation Mutation (
      $password1: String!,
      $password2: String!,
      $uid: String!,
      $token: String!
    ) {
      setPassword (
        password1: $password1,
        password2: $password2,
        uid: $uid,
        token: $token
      ) {
        status
        auth {
          refresh
          access
          type
        }
      }
    }
  `,
};