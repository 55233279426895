import React from 'react';
import gql from 'graphql-tag';
import {
  Query,
  Mutation
} from 'react-apollo';

import {
  Row,
  Col,
  Statistic,
  Button,
  Icon,
  Tabs,
  Card,
  Popconfirm,
  Modal,
  notification,
} from 'antd';
import MessageComponent from "../../../components/Message";
import MembersResultsView from "./MembersResultsView";

import {Typography} from 'antd';
import ManageEmail from "../../../components/ManageEmail";
import {isUndefined} from 'util';
import {
  INTERNAL_MANAGER,
  INVESTMENT_COMMITTEE,
} from '../../../utils/constants';

const TabPane = Tabs.TabPane;

const {Title} = Typography;


const query = gql`
query Query(
  $venture_id: Int!,
  $pre_evaluation: Boolean!,
  $member_token: String!,
) {
  resultDiligence(
    ventureId: $venture_id,
    preEvaluation: $pre_evaluation,
    memberToken:$member_token,
  ){
    diligence
    result
    extra
    good
  }
}
`;

const averageQuery = gql`
query averageQuery(
  $venture_id: Int!,
  $pre_evaluation: Boolean!,
  $member_type: String!,
) {
  averageResultDiligence(
    ventureId: $venture_id,
    preEvaluation: $pre_evaluation,
    memberType: $member_type,
  ){
    member
    results{
      diligence
      result
      extra
      good
    }
  }
}
`;

const archive = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: Boolean!,
  $notification: Boolean!,
  $internal: Boolean!,
)
  {
  archiveVenture(
    ventureId: $ventureId,
    value: $value,
    notification: $notification,
    internal: $internal,
  )
  {
    status
    venture{
      id
      isArchived
    }
  } 
}
`;


const admin = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: String!,
)
  {
  adminVenture(
    ventureId: $ventureId,
    value: $value,
  )
  {
    status
  } 
}
`;


const investment = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: String!,
)
  {
  investmentVenture(
    ventureId: $ventureId,
    value: $value,
  )
  {
    status
  } 
}
`;


const initial = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: String!,
)
  {
  initialVenture(
    ventureId: $ventureId,
    value: $value,
  )
  {
    status
  } 
}
`;

const detailed = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: String!,
)
  {
  detailedVenture(
    ventureId: $ventureId,
    value: $value,
  )
  {
    status
  } 
}
`;

const memberInvitation = gql`
mutation Mutation($type: String!, $venture: Int!) {
  sendMemberEmail(type: $type, venture: $venture) {
    status
    opinions {
      id
      venture{
        id
        companyName
      }
      member{
        id
        firstName
        lastName
        emailAddress
      }
    }
  }
}
`;

const investmentQuery = gql`
query Query($ventureId: ID!) {
  investorForVenture(ventureId: $ventureId) {
    suggested {
        investor {
            id
            emailAddress
            firstName
            lastName
            memberopinionSet {
              venture {
                id
              }
              email
              evaluation
            }
        }
        score
    }
    nonSuggested {
        id
        emailAddress
        firstName
        lastName
        memberopinionSet {
          venture {
            id
          }
          email
          evaluation
        }
    }
  }
}
`;

const managersQuery = gql`
  {
    allManagers {
      id
      emailAddress
      firstName
      lastName
      memberopinionSet {
        venture {
          id
        }
        email
        evaluation
      }
    }
  }
`;


class AverageView extends React.Component {
  render() {
    const {member_type, venture_id, pre_evaluation, saveLoading} = this.props;
    return (
      <Query
        query={averageQuery}
        variables={
          {
            venture_id,
            pre_evaluation,
            member_type,
          }
        }
        pollInterval={500}
      >
        {({loading, error, data, startPolling, stopPolling}) => {
          if (saveLoading) {
            stopPolling();
          } else {
            startPolling(500);
          }
          const shouldShowLoading = loading && !data.averageResultDiligence;
          if (shouldShowLoading) return (
            <Row style={{minHeight: '20vh'}} type="flex" align="middle" justify="center">
              <Icon style={{fontSize: 20}} type="loading"/>
            </Row>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );
          let all_results = [];

          data.averageResultDiligence.forEach(function (average, index) {

            let one_result = [];
            let color = '#cf1322';
            let icon = 'close';

            average.results.forEach(function (result, index) {
              if (result.good) {
                color = '#3f8600';
                icon = 'check';
              }
              one_result.push(
                <Col
                  key={index} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Card>
                    <Statistic
                      title={result.diligence}
                      value={result.result}
                      precision={2}
                      valueStyle={{color: color}}
                      prefix={<Icon type={icon}/>}
                      suffix={result.extra}
                    />
                  </Card>
                </Col>
              );
            });

            all_results.push(
              <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Title className="dashboard-title" level={4}>
                  {average.member}
                </Title>
                {one_result}
              </Col>
            );

          });

          return (
            <Row type="flex" justify="space-around" align="middle" gutter={100}>
              {all_results}
            </Row>
          );
        }}
      </Query>
    );
  }
}


class ResultsView extends React.Component {

  state = {
    saveLoading: false,
    current: 'personal',
    visibleInvestor: false,
    visibleMangers: false,
  };

  functionUpdateCurrent = (newValue) => {
    this.setState({
      current: newValue
    });
  };

  archiveIconLoading = (mutationFunction, ventureId, value, notification, internal) => {
    this.setState({saveLoading: true});
    let variables = {
      variables: {
        value: value,
        ventureId: ventureId,
        notification: notification,
        internal: internal,
      }
    };
    mutationFunction(variables)
      .then(res => {
        if (res.data.archiveVenture.status === 200) {
          this.setState({saveLoading: false});
          notification['success']({
            message: 'Venture Archived',
            description: "Your venture have been archived successfully",
            duration: 3.5,
          });
        } else {
          this.setState({saveLoading: false});
          notification['error']({
            message: 'An error occurred',
            duration: 3.5,
          });
        }
      })
      .catch(err => {
        if (err.graphQLErrors) {
          for (let i = 0; i < err.graphQLErrors.length; i++) {
            notification['error']({
              message: 'Network Error',
              description: err.graphQLErrors[i].message,
              duration: 3.5,
            });
          }
        }
        console.log(err);
        this.setState({saveLoading: false});
        console.log('Network error');
      });
  };

  IconLoading = (send, managers, emailMutationFunction, mutationFunction, ventureId, value, goTo, goToAction) => {
    this.setState({saveLoading: true});
    let variables = {
      variables: {
        value: value,
        ventureId: ventureId
      }
    };
    let emailVariables = {
      variables: {
        venture: ventureId,
        type: managers ? 'IM' : 'IC',
      }
    };
                  
    mutationFunction(variables)
      .then(res => {
        const [ myStatus ] = Object.keys(res.data);
        const my_data = res.data[myStatus];
        if (my_data.status === 200) {
          notification['success']({
            message: 'Venture Proceed',
            duration: 3.5,
          });
          if (send) {
            emailMutationFunction(emailVariables)
              .then(res => {
                if (res.data.sendMemberEmail.status === 200) {
                  this.setState({saveLoading: false});
                  notification['success']({
                    message: 'Members Notified',
                    duration: 3.5,
                  });
                  if (!isUndefined(goTo) && !isUndefined(goToAction)) {
                    goToAction(goTo);
                  }
                } else {
                  this.setState({saveLoading: false});
                  notification['error']({
                    message: 'An error occurred',
                    duration: 3.5,
                  });
                }
              })
              .catch(err => {
                if (err.graphQLErrors) {
                  for (let i = 0; i < err.graphQLErrors.length; i++) {
                    notification['error']({
                      message: 'Network Error',
                      description: err.graphQLErrors[i].message,
                      duration: 3.5,
                    });
                  }
                }
                console.log(err);
                this.setState({saveLoading: false});
                console.log('Network error');
              });
          } else {
            this.setState({saveLoading: false});
            if (!isUndefined(goTo) && !isUndefined(goToAction)) {
              goToAction(goTo);
            }
          }
        } else {
          notification['error']({
            message: 'An error occurred',
            duration: 3.5,
          });
        }
      })
      .catch(err => {
        if (err.graphQLErrors) {
          for (let i = 0; i < err.graphQLErrors.length; i++) {
            notification['error']({
              message: 'Network Error',
              description: err.graphQLErrors[i].message,
              duration: 3.5,
            });
          }
        }
        console.log(err);
        this.setState({saveLoading: false});
        console.log('Network error');
      });
  };

  renderArchive(notification, internal) {
    const {saveLoading} = this.state;
    const {venture} = this.props;
    let venture_id = venture.id;
    let archived = !venture.isArchived;
    let text = "Restore";
    if (archived) {
      text = "Archive";
    }

    if (archived && notification) {
      if (internal) {
        return (
          <Mutation mutation={archive}>
            {(archiveVenture, {mutationData}) => (
              <Popconfirm title="Do you also want to notify the Internal Managers?"
                          onCancel={(event) => this.archiveIconLoading(archiveVenture, venture_id, archived, false, true, event)}
                          onConfirm={(event) => this.archiveIconLoading(archiveVenture, venture_id, archived, notification, true, event)}
                          okText="Yes, notify them."
                          cancelText="No, just archive.">
                <Button
                  className="normal-button"
                  icon="folder"
                  loading={saveLoading}
                >
                  {text}
                </Button>
              </Popconfirm>
            )}
          </Mutation>
        );
      }
      return (
        <Mutation mutation={archive}>
          {(archiveVenture, {mutationData}) => (
            <Popconfirm title="Do you also want to notify the Internal Managers and Investment Committee?"
                        onCancel={(event) => this.archiveIconLoading(archiveVenture, venture_id, archived, false, false, event)}
                        onConfirm={(event) => this.archiveIconLoading(archiveVenture, venture_id, archived, notification, false, event)}
                        okText="Yes, notify them."
                        cancelText="No, just archive.">
              <Button
                className="normal-button"
                icon="folder"
                loading={saveLoading}
              >
                {text}
              </Button>
            </Popconfirm>
          )}
        </Mutation>
      );
    }

    return (
      <Mutation mutation={archive}>
        {(archiveVenture, {mutationData}) => (
          <Button
            className="normal-button"
            icon="folder"
            loading={saveLoading}
            onClick={(event) => this.archiveIconLoading(archiveVenture, venture_id, archived, false,true, event)}>
            {text}
          </Button>
        )}
      </Mutation>
    );
  }

  renderAdminProceed(goTo, goToAction) {
    const { saveLoading, visibleMangers } = this.state;
    const {venture} = this.props;
    let venture_id = venture.id;
    let archived = venture.isArchived;
    let adminEvaluation = venture.adminEvaluation;

    if (archived || adminEvaluation === 'Y') {
      return null;
    }
    return (
      <Mutation mutation={admin}>
        {(adminVenture, {mutationData}) => (
          <div>
            <Query query={managersQuery}>
              {({loading, error, data, refetch}) => {
                if (loading)
                  return (
                    <MessageComponent
                      icon="loading"
                      iconSize={50}
                      text="Loading"
                      level={4}
                    />
                  );
                if (error)
                  return (
                    <MessageComponent
                      icon="smile"
                      iconSize={50}
                      text={`${error.message}`}
                      level={4}
                    />
                  );
                return (
                  <Modal
                    title="Send Email to Managers"
                    width={820}
                    visible = {visibleMangers}
                    onCancel={this.handleCancelManagers}
                    footer={null}
                  >
                    <ManageEmail
                      type={INTERNAL_MANAGER}
                      data={data.allManagers}
                      dataSuggested={[]}
                      ventureId={venture_id}
                      extraFunction={this.afterManagerEmail}
                      functionParams={[adminVenture, venture_id, "Y", goTo, goToAction]}
                    />

                  </Modal>
                );
              }}
            </Query>
            <Mutation mutation={memberInvitation}>
              {(sendMemberEmail, {mutationDataEmail}) => (
                <Popconfirm
                  title="Do you also want to notify the Internal Managers?"
                  onConfirm={() => this.showModalManager()}
                  onCancel={(event) => this.IconLoading(false, true, sendMemberEmail, adminVenture, venture_id, "Y", event)}
                  okText="Notify them"
                  cancelText="No need to notify"
                >
                  < Button
                    className="save-button"
                    icon="arrow-right"
                    type="primary"
                    loading={saveLoading}
                  >
                    Proceed
                  </Button>
                </Popconfirm>
              )}
            </Mutation>
          </div>
        )}
      </Mutation>

    );
  }

  showModalInvestor = (sendMemberEmail, initialVenture, venture_id, value, event) => {
    this.setState({
      visibleInvestor: true,
    });
  };

  showModalManager = () => {
    this.setState({
      visibleMangers: true,
    });
  };

  afterInvestorEmail = (initialVenture, venture_id, value) => {
    this.setState({
      visibleInvestor: false,
    });
    this.IconLoading(false, false, null, initialVenture, venture_id, value);

  };

  afterManagerEmail = (adminVenture, venture_id, value, goTo, goToAction) => {
    this.setState({
      visibleMangers: false,
    });
    this.IconLoading(
      true, true, null, adminVenture, venture_id, value, goTo, goToAction)

  };

  handleCancelInvestors = () => {
    this.setState({
      visibleInvestor: false,
    });
  };

  handleCancelManagers = () => {
    this.setState({
      visibleMangers: false,
    });
  };

  renderInternalProceed() {
    const {saveLoading, visibleInvestor} = this.state;
    const {venture} = this.props;
    let venture_id = venture.id;
    let archived = venture.isArchived;
    let initialEvaluation = venture.initialEvaluation;

    if (archived || initialEvaluation === 'Y') {
      return null;
    }
    return (
      <div>
        <Mutation mutation={initial}>
          {(initialVenture, {mutationData}) => (
              <div>
              <Query query={investmentQuery} variables={{ventureId: venture_id}}>
                {({loading, error, data, refetch}) => {
                  if (loading)
                    return (
                        <MessageComponent
                            icon="loading"
                            iconSize={50}
                            text="Loading"
                            level={4}
                        />
                    );
                  if (error)
                    return (
                        <MessageComponent
                            icon="smile"
                            iconSize={50}
                            text={`${error.message}`}
                            level={4}
                        />
                    );
                  let dataNonSuggested = data.investorForVenture.nonSuggested;
                  let dataSuggested = data.investorForVenture.suggested.map(investor => {
                    return {
                      ...investor.investor,
                      score: investor.score
                    };
                  });
                  return (
                      <Modal
                          title="Send Email to Investors"
                          width={820}
                          visible={visibleInvestor}
                          onCancel={this.handleCancelInvestors}
                          footer={null}
                      >
                        <ManageEmail
                            type={INVESTMENT_COMMITTEE}
                            data={dataNonSuggested}
                            dataSuggested={dataSuggested}
                            ventureId={venture_id}
                            extraFunction={this.afterInvestorEmail}
                            functionParams={[initialVenture, venture_id, "Y"]}
                        />
                      </Modal>
                  );
                }}
              </Query>

              <Mutation mutation={memberInvitation}>
              {(sendMemberEmail, {mutationDataEmail}) => (
                <Popconfirm title="Do you also want to notify the Investment Committee?"
                            onConfirm={(event) => this.showModalInvestor(sendMemberEmail, initialVenture, venture_id, "Y", event)}
                            onCancel={(event) => this.IconLoading(false, false, sendMemberEmail, initialVenture, venture_id, "Y", event)}
                            okText="Notify them"
                            cancelText="No need to notify">
                  <Button
                    className="save-button"
                    icon="arrow-right"
                    type="primary"
                    loading={saveLoading}
                  >
                    Proceed
                  </Button>
                </Popconfirm>
              )}
            </Mutation>
            </div>
          )}
        </Mutation>
      </div>
    );
  }

  renderInvestmentProceed(goTo, goToAction) {
    const {saveLoading} = this.state;
    const {venture} = this.props;
    let venture_id = venture.id;
    let archived = venture.isArchived;
    let investmentEvaluation = venture.investmentEvaluation;

    if (archived || investmentEvaluation === 'Y') {
      return null;
    }
    return (
      <Mutation mutation={investment}>
        {(investmentVenture, {mutationData}) => (
          <Mutation mutation={memberInvitation}>
            {(sendMemberEmail, {mutationDataEmail}) => (
              < Button
                className="save-button"
                icon="arrow-right"
                type="primary"
                onClick={(event) => this.IconLoading(false, false, sendMemberEmail, investmentVenture, venture_id, "Y", goTo, goToAction, event)}
                loading={saveLoading}>
                Proceed
              </Button>
            )}
          </Mutation>
        )}
      </Mutation>

    );
  }

  renderFinalProceed() {
    const {saveLoading} = this.state;
    const {venture} = this.props;
    let venture_id = venture.id;
    let archived = venture.isArchived;
    let detailedEvaluation = venture.detailedEvaluation;

    if (archived || detailedEvaluation === 'Y') {
      return null;
    }
    return (
      <Mutation mutation={detailed}>
        {(detailedVenture, {mutationData}) => (
          <Mutation mutation={memberInvitation}>
            {(sendMemberEmail, {mutationDataEmail}) => (
              < Button
                className="save-button"
                icon="arrow-right"
                type="primary"
                onClick={(event) => this.IconLoading(false, false, sendMemberEmail, detailedVenture, venture_id, "Y", event)}
                loading={saveLoading}>
                Proceed
              </Button>
            )}
          </Mutation>
        )}
      </Mutation>

    );
  }

  changeTab = (path) => {
    this.setState({
      current: path
    });
  };

  render() {
    const {saveLoading, current} = this.state;
    const {venture, preEvaluation, member, title} = this.props;

    let venture_id = venture.id;
    let pre_evaluation = preEvaluation;
    let member_token = member;

    let internal_managers = (
      <TabPane tab="Internal Managers" key="members">
        <MembersResultsView
          member_type="IM"
          venture={venture}
          pre_evaluation={pre_evaluation}
          title={title}
        />
      </TabPane>
    );
    let internal_score = (
      <TabPane tab="Internal Average" key="average">
        <Title className="dashboard-title" level={3}>
          {title}
        </Title>
        <AverageView
          venture_id={venture_id}
          pre_evaluation={pre_evaluation}
          saveLoading={saveLoading}
          member_type="IM"
        />
        <Row style={{marginBottom: '100px'}} type="flex" justify="space-around"
             align="middle">
          {this.renderArchive(true, true)}
          {this.renderInternalProceed()}
        </Row>
      </TabPane>
    );
    let investment_committee = (
      <TabPane tab="Investment Committee" key="investment">
        <MembersResultsView
          member_type="IC"
          venture={venture}
          pre_evaluation={pre_evaluation}
          title={title}
        />
      </TabPane>
    );
    let overall_average = (
      <TabPane tab="Overall Average" key="overall">
        <Title className="dashboard-title" level={3}>
          {title}
        </Title>
        <AverageView
          venture_id={venture_id}
          pre_evaluation={pre_evaluation}
          saveLoading={saveLoading}
          member_type="IC"
        />
        <Row style={{marginTop: '100px', marginBottom: '100px'}} type="flex" justify="space-around"
             align="middle">
          {this.renderArchive(true, false)}
          {this.renderInvestmentProceed(
            !isUndefined(this.props.nextTab) ? this.props.nextTab : undefined,
            !isUndefined(this.props.changeTab) ? this.props.changeTab : undefined
          )}
        </Row>
      </TabPane>
    );

    let personal_buttons = (
      <Row style={{marginTop: '100px', marginBottom: '100px'}} type="flex" justify="space-around"
           align="middle">
        {this.renderArchive(false, true)}
        {this.renderAdminProceed("members", this.changeTab)}
      </Row>
    );

    if (!pre_evaluation) {
      internal_managers = null;
      investment_committee = null;
      personal_buttons = null;
      internal_score = (
        <TabPane tab="Overall Score" key="overall-score"
                 style={{marginBottom: '100px'}}>
          <Title className="dashboard-title" level={3}>
            {title}
          </Title>
          <AverageView
            venture_id={venture_id}
            pre_evaluation={pre_evaluation}
            saveLoading={saveLoading}
            member_type="AD"
          />
        </TabPane>
      );
      overall_average = (
        <TabPane tab="Final Decision" key="final-decision"
                 style={{marginBottom: '100px'}}>
          <Title className="dashboard-title" level={3}>
            {title}
          </Title>
          <AverageView
            venture_id={venture_id}
            pre_evaluation={pre_evaluation}
            saveLoading={saveLoading}
            member_type="FD"
          />
          <Row style={{marginBottom: '100px'}} type="flex" justify="space-around"
               align="middle">
            {this.renderArchive(true, false)}
            {this.renderFinalProceed()}
          </Row>
        </TabPane>
      );
    }

    let personal = (
      <TabPane tab="Personal" key="personal"
               style={{marginBottom: '100px'}}>
        <Title className="dashboard-title" level={3}>
          {title}
        </Title>
        <Query
          query={query}
          variables={
            {
              venture_id,
              pre_evaluation,
              member_token,
            }
          }
          pollInterval={500}
        >
          {({loading, error, data, startPolling, stopPolling}) => {
            if (saveLoading) {
              stopPolling();
            } else {
              startPolling(500);
            }
            const shouldShowLoading = loading && !data.resultDiligence;
            if (shouldShowLoading) return (
              <Row style={{minHeight: '20vh'}} type="flex" align="middle" justify="center">
                <Icon style={{fontSize: 20}} type="loading"/>
              </Row>
            );
            if (error) return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}/>
            );
            let all_results = [];
            data.resultDiligence.forEach(function (result, index) {
              let color = '#cf1322';
              let icon = 'close';
              if (result.good) {
                color = '#3f8600';
                icon = 'check';
              }
              all_results.push(
                <Col
                  style={{marginTop: '50px'}}
                  key={index} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Card>
                    <Statistic
                      title={result.diligence}
                      value={result.result}
                      precision={2}
                      valueStyle={{color: color}}
                      prefix={<Icon type={icon}/>}
                      suffix={result.extra}
                    />
                  </Card>
                </Col>
              );
            });

            return (
              <Row type="flex" justify="space-around" align="middle" gutter={100}>
                {all_results}
              </Row>
            );
          }}
        </Query>
        {personal_buttons}
      </TabPane>
    );

    return (
      <Tabs defaultActiveKey={current} activeKey={current} onChange={this.functionUpdateCurrent}>
        {personal}
        {internal_managers}
        {internal_score}
        {investment_committee}
        {overall_average}
      </Tabs>
    );
  }
}

export default ResultsView;
