import React from 'react';
import {
  Col,
  Row,
  Typography,
} from 'antd';
import { contentComplete } from '../../../../../../utils/tools';
import CircleSectionItem from '../CircleSectionItem';

const { Title, Text } = Typography;

const CircleSection = (props) => {
  const {
    itemCircleList,
    circleList,
    isEditingExtra,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    onAddItem,
    onEraseItem,
  } = props;
  const titleContent = {
    xs: { span: 18, offset: 0 },
    sm: { span: 18, offset: 0 },
    md: { span: 10, offset: 0 },
    lg: { span: 10, offset: 0 },
    xl: { span: 10, offset: 0 },
    xxl: { span: 10, offset: 0 },
  };
  const sectionTitleContent = {
    xs: { span: 18, offset: 0 },
    sm: { span: 18, offset: 0 },
    md: { span: 12, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
  };

  const createNew = () => {
    const newInfo = {
      arrayId: 0,
      number: '00',
      quantity: '€ Million +',
      description: '(Describe in detail)',
      isComplete: false,
    };

    return newInfo;
  };

  if (isEditingExtra(itemCircleList.percentageComplete
    || contentComplete(itemCircleList.content))) {
    return (
      <Col key={itemCircleList.arrayId} className="circle-column-container" span={24}>
        <Row type="flex" justify="center" align="middle">
          <Col {...titleContent}>
            <Title
              className="section-title circle-title-left"
              level={2}
            >
              {itemCircleList.title}
            </Title>
          </Col>
          <Col
            className={
              `circle-title-right ${!itemCircleList.percentageComplete ? 'incomplete-field' : ''}`
            }
            {...sectionTitleContent}
          >
            {
              (isEditingExtra(itemCircleList.percentageComplete))
              && (
              <div className="edit small-text-circle">
                <div className="number">
                  <Text
                    className="section-title"
                    level={2}
                    strong
                    onClick={(e) => displayEditable(e)}
                    editable={{
                      onChange: (event) => onChangeField(
                        null, itemCircleList.arrayId,
                        event, 'circleList', 'initPercentage',
                        undefined, 'percentageComplete',
                      ),
                    }}
                    data-cy="test-initPercentage-text-circleSection"
                  >
                    {itemCircleList.initPercentage}
                  </Text>
                  <Text className="section-title" level={2} strong>%</Text>
                  <Text className="section-title" level={2} strong> - </Text>
                  <Text
                    className="section-title"
                    level={2}
                    strong
                    onClick={(e) => displayEditable(e)}
                    editable={{
                      onChange: (event) => onChangeField(
                        null, itemCircleList.arrayId,
                        event, 'circleList', 'endPercentage',
                        undefined, 'percentageComplete',
                      ),
                    }}
                    data-cy="test-endPercentage-text-circleSection"
                  >
                    {itemCircleList.endPercentage}
                  </Text>
                  <Text className="section-title" level={2} strong>% </Text>
                </div>
                <Text
                  className="section-title"
                  level={2}
                  onClick={(e) => displayEditable(e)}
                  editable={{
                    onChange: (event) => onChangeField(
                      null, itemCircleList.arrayId,
                      event, 'circleList', 'percentageText',
                      undefined, 'percentageComplete',
                    ),
                  }}
                  data-cy="test-percentageText-text-circleSection"
                >
                  {itemCircleList.percentageText}
                </Text>
              </div>
              )
            }
          </Col>
          <Row style={{ width: '100%' }} type="flex" justify="center" align="top">
            {
                itemCircleList.content.map((item) => (
                  <CircleSectionItem
                    item={item}
                    sectionId={itemCircleList.arrayId}
                    isEditingExtra={isEditingExtra}
                    displayEditable={displayEditable}
                    onChangeField={onChangeField}
                    checkLimitsAddErase={checkLimitsAddErase}
                    circleList={circleList}
                    onEraseItem={onEraseItem}
                  />
                ))
              }
          </Row>
        </Row>
        <Row type="flex" justify="center" align="middle">
          {checkLimitsAddErase(
            itemCircleList.arrayId, true,
            circleList, onAddItem, 'plus-container-right',
            null, 'circleList', createNew,
          )}
        </Row>
      </Col>
    );
  }

  return (<Col className="mt-3" span={24} />);
};

export default CircleSection;
