import React from 'react';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';

import OnePageRenderer from '../../navigator/onepager/OnePagerRenderer';
import MessageComponent from '../../../components/Message';

import {
    Col,
    Row,
    Typography,
    Tabs,
    Tag,
} from 'antd';
import VentureStatus from '../../../components/VentureStatus/VentureStatus';

import '../../../styles/utils.scss';

const TabPane = Tabs.TabPane;
const {Title} = Typography;

const query = gql`
query QuerySections($venture_id: ID!){
  sections{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsRightCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsChecklist{
    id
    order
    minItems
    maxItems
    title
    boldTitle
  }
  sectionsProfile{
    id
    order
    minItems
    maxItems
  }
  contentSections(idVenture: $venture_id){
    id
    order
    icon
    title
    description
    isComplete
    section{
      id
    }
  }
  contentSectionsRightCircle(idVenture: $venture_id){
    id
    order
    number
    quantity
    year
    preText
    postText
    title
    isComplete
    rightCircleSection{
      id
    }
  }
  contentSectionsCircle(idVenture: $venture_id){
    id
    order
    initPercentage
    endPercentage
    percentageText
    percentageComplete
    circlesectioncontentSet{
      id
      order
      number
      quantity
      description
      isComplete
    }
    circleSection{
      id
    }
  }
  contentSectionsChecklist(idVenture: $venture_id){
    id
    order
    title
    description
    isComplete
    checklistSection{
      id
    }
  }
  contentSectionsProfile(idVenture: $venture_id){
    id
    order
    name
    linkedin
    isComplete
    profilesectioncontentSet{
      id
      order
      description
    }
    profileSection{
      id
    }

  }
  profilePhotos(idVenture: $venture_id){
    id
    order
    image
  }
  venture(id: $venture_id){
    id
    isReceived
    adminEvaluation
    initialEvaluation
    investmentEvaluation
    detailedEvaluation
    isArchived
    token
    companyName
    companyNameComplete
    founder{
      id
      firstName
      lastName
    }
    tags {
      id
      name
    }
  }
  pitchVenture(idVenture: $venture_id){
    pitch
  }
}
`;


const addKeys = (content) => {
    for (let i = 0; i < content.length; i++) {
        content[i].key = i;
    }
    return content;
};


class OnePagerStatus extends React.Component {

    state = {
        current: "one-pager",
    };

    changeTab = (newValue) => {
        this.setState({
            current: newValue
        });
    };

    getSummary = (status) => {
        const values = Object.values(status);
        if (status.archive) {
            return (<Tag color="red">On Hold</Tag>);
        }
        if (values.indexOf('N') >= 0) {
            return (<Tag color="red">On Hold</Tag>);
        } else if (values.indexOf('Y') >= 0 ) {
            let totalYes = 0;
            const maxYes = 4;
            values.forEach(item => {
                if (typeof item === "string" && item === "Y")
                    totalYes += 1;
            });
            if (totalYes === maxYes){
                return (<Tag color="green">Completed</Tag>);
            } else {
                return (<Tag color="orange">In Progress</Tag>);
            }
        }
        return (<Tag className="bg-silver_chalice">On Hold</Tag>);
    };

    render() {
        let venture_id = this.props.venture.id;
        const {current} = this.state;
        return (
            <Query query={query} variables={{venture_id}}>
                {
                    ({loading, error, data, refetch}) => {
                        const shouldShowLoading = loading && !data.venture;
                        if (shouldShowLoading) {
                            return (
                                <MessageComponent
                                    icon="loading"
                                    iconSize={50}
                                    text="Loading Preview"
                                    level={4}/>
                            );
                        }
                        if (error) return (
                            <MessageComponent
                                icon="smile"
                                iconSize={50}
                                text={`${error.message}`}
                                level={4}/>
                        );

                        data.sections = addKeys(data.sections);
                        data.sectionsRightCircle = addKeys(data.sectionsRightCircle);
                        data.sectionsCircle = addKeys(data.sectionsCircle);
                        data.sectionsChecklist = addKeys(data.sectionsChecklist);
                        data.sectionsProfile = addKeys(data.sectionsProfile);
                        data.contentSections = addKeys(data.contentSections);
                        data.contentSectionsRightCircle = addKeys(data.contentSectionsRightCircle);
                        data.contentSectionsCircle = addKeys(data.contentSectionsCircle);
                        data.contentSectionsChecklist = addKeys(data.contentSectionsChecklist);
                        data.contentSectionsProfile = addKeys(data.contentSectionsProfile);
                        data.profilePhotos = addKeys(data.profilePhotos);

                        const status = {
                            admin: data.venture.adminEvaluation,
                            manager: data.venture.initialEvaluation,
                            investor: data.venture.investmentEvaluation,
                            detailed: data.venture.detailedEvaluation,
                            archive: data.venture.isArchived,
                        };

                        return (
                            <Row type="flex" justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                    <Title className="dashboard-title" level={1}>
                                        {data.venture.companyName} {this.getSummary(status)}
                                    </Title>
                                    <Tabs
                                        className="dashboard-table"
                                        defaultActiveKey="1"
                                        activeKey={current}
                                        onChange={this.changeTab}
                                    >
                                        <TabPane tab="One Pager" key="one-pager">
                                            <OnePageRenderer
                                                sections={data.sections}
                                                sectionsRightCircle={data.sectionsRightCircle}
                                                sectionsCircle={data.sectionsCircle}
                                                sectionsChecklist={data.sectionsChecklist}
                                                sectionsProfile={data.sectionsProfile}
                                                contentSections={data.contentSections}
                                                contentSectionsRightCircle={data.contentSectionsRightCircle}
                                                contentSectionsCircle={data.contentSectionsCircle}
                                                contentSectionsChecklist={data.contentSectionsChecklist}
                                                contentSectionsProfile={data.contentSectionsProfile}
                                                profilePhotos={data.profilePhotos}
                                                pitchVenture={data.pitchVenture}
                                                venture={data.venture}
                                                preview={true}
                                            />
                                        </TabPane>
                                        <TabPane tab="Status" key="status">
                                            <VentureStatus status={status} />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        );
                    }
                }
            </Query>
        );
    }
}

export default OnePagerStatus;
