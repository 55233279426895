import React from 'react';
import { Route } from 'react-router-dom';
import BaseWrapper from '../baseWrapper';
import {
    INTERNAL_MANAGER,
    INVESTMENT_COMMITTEE
} from '../../utils/constants';

const TYPEPATHS = {};
TYPEPATHS[INVESTMENT_COMMITTEE] = '/investor/dashboard/';
TYPEPATHS[INTERNAL_MANAGER] = '/investor/dashboard/';

const PrivateRoute = (props) => {
  const { component: Component, type = ['admin'], ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        const { history } = props;
        const localType = localStorage.getItem('type');
        if (type.indexOf(localType) < 0) {
          history.push(TYPEPATHS[localType]);
        }

        return <BaseWrapper type={type} privateURL component={Component} header {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
