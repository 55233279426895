import React from 'react';
import {
  Query
} from 'react-apollo';
import {
  Table,
  Button,
  Row
} from 'antd';
import { Link } from 'react-router-dom';
import { renderBoolean } from '../../../utils/tools';
import MessageComponent from '../../../components/Message';
import { QUERIES } from '../../../utils/queries';
import InvestorInfo from '../../../components/InvestorInfo';


class Ventures extends React.Component {

  render() {
    const { currentUser, investorData } = this.props;

    const memberData = {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      emailAddress: currentUser.email,
    };

    return (
      <Query query={QUERIES.investorsVenturesByUser} fetchPolicy="network-only" pollInterval={500}>
        {({loading, error, data, refetch}) => {
          if (error) {
            return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}
              />
            );
          } else if(loading) {
            return (
              <MessageComponent
                icon="loading"
                iconSize={50}
                text="Loading"
                level={4}
              />
            );
          }
          const { investorUser } = data;
          const { memberopinionSet, token } = investorUser;
          const columns = [
            {
              title: 'Venture',
              dataIndex: 'venture',
              key: 'venture',
              align: 'center',
              children: [
                {
                  title: 'Active',
                  dataIndex: 'active',
                  key: 'active',
                  align: 'center',
                  render: (text, data) => (
                    renderBoolean(!data.venture.archived)
                  )
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                  render: (text, data) => (
                    <span>
                {data.venture.companyName}
              </span>
                  )
                },],
            },
            {
              title: 'Evaluation',
              dataIndex: 'evaluation',
              key: 'evaluation',
              align: 'center',
              children: [
                {
                  title: 'Link',
                  dataIndex: 'link',
                  key: 'link',
                  align: 'center',
                  render: (text, data) => {
                    if (data.evaluation) {
                      return renderBoolean(false);
                    } else {
                      return (
                        <Link to={`/navigator/one-pager/${data.venture.token}/member/${token}`}>
                          <Button>Start evaluation</Button>
                        </Link>
                      );
                    }
                  }
                },
                {
                  title: 'Evaluation',
                  dataIndex: 'evaluation',
                  key: 'evaluation',
                  align: 'center',
                  render: (text, data) => (
                    renderBoolean(data.evaluation)
                  )
                },
                {
                  title: 'Comment',
                  dataIndex: 'comment',
                  key: 'comment',
                  align: 'center',
                  render: (text, data) => (
                    data.comment
                  )
                },
              ],
            },
          ];
          return (
            <Row>
              <InvestorInfo
                memberTags={investorData.tags}
                memberData={memberData}
                answerSet={investorData.answerSet}
                memberId={investorData.id}
              />
              <Table
                columns={columns}
                dataSource={memberopinionSet}
                pagination={{pageSize: 50}}
                bordered
              />
            </Row>
          );
        }}

      </Query>
    );
  }

}

export default Ventures;