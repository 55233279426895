import React, {Component} from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
} from 'antd';
import {
  customFontType
} from '../../styles/customStyles';
import { isDarker } from '../../styles/tools';
import SiderWrapper from '../../components/SiderWrapper';
import SiderProvider from '../../contexts';

import './styles.scss';

const {Text} = Typography;

const {
  Content,
  Footer,
} = Layout;

class GeneralWrapper extends Component {

  render() {
    let Component = this.props.Component;
    const {
      secondaryColor,
      logoFooter,
      fontType
    } = this.props.customConfig;
    const { customConfig, withSider=true } = this.props;
    const font = customFontType(fontType);
    return (
      <SiderProvider>
        <Layout style={{minHeight: '100vh', ...font}}>
          {
            withSider &&
            <SiderWrapper customConfig={customConfig} />
          }
          <Layout className={withSider ? "layout-content-margin" : undefined}>
            <Content>
                <Component {...this.props} />
            </Content>
            <Footer
              className="custom-footer"
              style={{
                backgroundColor: secondaryColor,
                color: isDarker(secondaryColor) ? 'white' : 'black',
              }}
            >
              <Row type="flex" justify="space-around" align="middle">
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8} style={{textAlign: 'center'}}>
                    <Col span={16} offset={3} style={{textAlign: 'left'}}>
                        <Text strong className="unset-color">
                        Fincite Ventures GmbH <br />
                        </Text>
                        <Text className="unset-color">
                        Technical Co-founder <br />
                        Impressive portfolio of successful <br />
                        Fintech startups
                        </Text>
                    </Col>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong className="unset-color">
                      <p className="text-center mb-0">
                        {"COPYRIGHT 2018 FINCITE. ALL RIGHTS RESERVED"}
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://fincite.ventures/imprint-privacy/"
                        >
                          DATA PRIVACY & IMPRINT
                        </a>
                      </p>
                    </Text>
                  </Col>
                  <Col
                    xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}
                    style={{textAlign: 'center'}}
                  >
                    <img
                      className="logo"
                      src={logoFooter}
                      alt="Logo"
                    />
                  </Col>
              </Row>
            </Footer>
          </Layout>
        </Layout>
      </SiderProvider>
    );
  }
}

export default GeneralWrapper;
