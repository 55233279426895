import React from 'react';
import gql from "graphql-tag";
import {Query} from "react-apollo";

import {
  Row, Col, Typography,
  Table, Button, Icon, Breadcrumb
} from 'antd';

import {Link} from "react-router-dom";
import MessageComponent from "../../../components/Message";
import { mainContainerBody } from '../../../styles/responsive';

const {Title} = Typography;

const query = gql`
{
  allFounders{
    founder{
      id
      firstName
      lastName
      emailAddress
      createdDate
      ventureopportunitySet{
        id
        companyName
        token
        isReceived
        emailSent
      }
    }
    archived
    notArchived
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};


class FounderListView extends React.Component {

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (text, data) => (
          <Link
            to={`/dashboard/founder/id/${data.founder.id}/`}>{data.founder.firstName} {data.founder.lastName}
          </Link>
        )
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
        render: (text, data) => (
          <div>
            {data.founder.emailAddress}
          </div>
        )
      },
      {
        title: 'Active Ventures',
        dataIndex: 'active',
        key: 'active',
        render: (text, data) => (
          <div>
            {data.notArchived}
          </div>
        )
      },
      {
        title: 'Archived Ventures',
        dataIndex: 'archived',
        key: 'archived',
        render: (text, data) => (
          <div>
            {data.archived}
          </div>
        )
      },
    ];


    return (
      <Row type="flex" justify="space-around" align="middle">
        <Col {...mainContainerBody}>
          <Breadcrumb className="dashboard-breadcrumb">
            <Breadcrumb.Item>
              <Link to="/dashboard/founder/list/">
                Founders
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Title className="dashboard-title" level={1}>
            Founders
          </Title>

          <Link to="/dashboard/founder/add/">
            <Button
              className="dashboard-button"
              style={{marginLeft: '10px'}}
              type="primary"
              test-attr="test-button-add-founder"
            >
              <Icon type="plus"/> Add
            </Button>
          </Link>

          <Query query={query} pollInterval={500}>
            {({loading, error, data, refetch}) => {
              if (loading) return (
                <MessageComponent
                  icon="loading"
                  iconSize={50}
                  text="Loading"
                  level={4}/>
              );
              if (error) return (
                <MessageComponent
                  icon="smile"
                  iconSize={50}
                  text={`${error.message}`}
                  level={4}/>
              );
              if (this.props.location.state !== undefined) {
                refetch();
              }

              data.allFounders = addKeys(data.allFounders);

              return (
                <Table
                  columns={columns}
                  dataSource={data.allFounders}
                  pagination={{pageSize: 50}}
                  bordered
                />
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default FounderListView;