import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {
  ApolloLink,
  concat,
} from 'apollo-link';

import 'antd/dist/antd.css';
import './styles/overwrite.scss';
import './styles/base.scss';
import './App.scss';

import * as jwtDecode from 'jwt-decode';
import LoginView from './views/account/LoginView';
import DashboardView from './views/dashboard/DashboardView';
import ImprintView from './views/imprint';
import LandingView from './views/landing';
import SetUserPassword from './views/SetUserPassword/index';
import DashboardInvestor from './views/DashboardInvestor/index';

import NavigatorView from './views/navigator/NavigatorView';
import {
  graphqlAPI,
  refreshToken,
} from './utils/ApiEndPoints';

import OverviewView from './views/overview';
import PublicRoute from './wrappers/publicRouteWrapper';
import PrivateRoute from './wrappers/privateRouteWrapper';
import { PATHS } from './utils/paths';
import {
  API_KEY,
  INVESTMENT_COMMITTEE,
  INTERNAL_MANAGER
} from './utils/constants';

const httpLink = createHttpLink({
  uri: graphqlAPI,
  batchInterval: 10,
  opts: {
    credentials: 'same-origin',
  },
});


const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  let access = localStorage.getItem('access');
  const accessExp = localStorage.getItem('accessExp');
  const refresh = localStorage.getItem('refresh');
  const refreshExp = localStorage.getItem('refreshExp');

  if (refreshExp) {
    if (refreshExp < Date.now() / 1000) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('refreshExp');
      localStorage.removeItem('accessExp');
      window.location.replace('/login/');
    }
  }

  if (access) {
    if (accessExp - 5 < Date.now() / 1000) {
      const values = { refresh };
      fetch(refreshToken, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          res.json().then((res) => {
            if (res.access) {
              const expirationAccess = jwtDecode(res.access).exp;
              localStorage.setItem('access', res.access);
              localStorage.setItem('accessExp', expirationAccess);
            }
          });
        })
        .catch(() => {
          console.log('Network error');
        });

      access = localStorage.getItem('access');
    }
  }
  const authorizationHeader = access ? `JWT ${access}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
      'X-Api-Key': API_KEY,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <PublicRoute
        exact
        path="/"
        component={LoginView}
      />
      <PublicRoute
        exact
        path="/login/"
        component={LoginView}
      />
      <PublicRoute
        path="/navigator/one-pager/:token/"
        component={NavigatorView}
        header
      />
      <PrivateRoute
        path="/dashboard/"
        component={DashboardView}
      />
      <PrivateRoute
        path="/imprint/"
        component={ImprintView}
      />
      <PrivateRoute
        path="/overview/"
        component={OverviewView}
      />
      <PublicRoute
        path="/landing/"
        component={LandingView}
      />
      <PublicRoute
        path="/set/password/:uid/:token/"
        component={SetUserPassword}
      />
      <PrivateRoute
        path={PATHS.dashboardInvestor}
        component={DashboardInvestor}
        type={[INVESTMENT_COMMITTEE, INTERNAL_MANAGER]}
      />
    </Router>
  </ApolloProvider>
);

export default App;
