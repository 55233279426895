import React from 'react';
import { Typography } from 'antd';
import { validateDescription } from '../../tools';

const { Text } = Typography;

const ProfileSectionItem = (props) => {
  const {
    item,
    sectionId,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    profileList,
    onEraseItem,
  } = props;

  return (
    <div key={item.arrayId}>
      <div className="edit small-text">
        <Text
          onClick={(e) => displayEditable(e)}
          editable={{
            onChange: (event) => onChangeField(
              item.arrayId, sectionId, event, 'profileList', 'description', validateDescription,
            ),
          }}
          data-cy="test-description-text-profileSectionItem"
        >
          {item.description}
        </Text>
        {checkLimitsAddErase(
          sectionId, false, profileList,
          onEraseItem, 'close-container-profile', item, 'profileList',
        )}
        <br />
      </div>
    </div>
  );
};

export default ProfileSectionItem;
