import React from 'react';
import gql from 'graphql-tag';
import {Mutation} from 'react-apollo';
import {
    Table,
    Button,
    Tabs,
    Icon,
    Input,
    Row,
    notification
} from 'antd';

import '../../styles/utils.scss';
import { isUndefined } from 'util';

const { TabPane } = Tabs;

const memberInvitation = gql`
mutation Mutation($ventureId: Int!, $membersId: [MemberIdMessage]!) {
  sendMemberEmail(venture: $ventureId, membersId:$membersId) {
    status
  }
}
`;

class ManageEmail extends React.Component {
    /*
    * Use this component to display a table with member data and manage send email
    *
    * @param: {columns}: Columns to display in table following ant design columns format
    * @param: {data}: List members data to display
    * @param: {ventureId}: Venture id linked
    * @param: {dataSuggested}: List of suggested members data with score
    * @param: {extraFunction}: Function to be run after send email
    * @param: {functionParams}: Parameters for extra function
    *  */
    state = {
        specificMembers: [],
        suggestedSpecificMembers: [],
        columns: [],
        data: [],
        messages : {},
        selectedMembersRowKeys: [],
        selectedSuggestedRowKeys: [],
    };

    componentDidMount() {
        const {ventureId} = this.props;
        let {columns} = this.props;
        this.setState({
            data: this.props.data
        });
        if (typeof columns === "undefined" || columns === null){
            const closeIcon = (<Icon className="negative-color" type="close" />);
            columns = [
                {
                    title: 'First Name',
                    dataIndex: 'firstName',
                    key: 'firstName',
                    className: 'text-capitalize',
                },
                {
                    title: 'Last Name',
                    dataIndex: 'lastName',
                    key: 'lastName',
                    className: 'text-capitalize',
                },
                {
                    title: 'Email Address',
                    dataIndex: 'emailAddress',
                    key: 'emailAddress',
                },
                {
                    title: 'Email Sent',
                    dataIndex: 'memberopinionSet',
                    key: 'emailSent',
                    align: 'center',
                    render: (text, data) => {
                        const memberOpinion = data.memberopinionSet.filter(item => item.venture.id=ventureId)[0];
                        if (typeof memberOpinion === "undefined") {
                            return closeIcon;
                        }
                        const sent = memberOpinion.email;
                        if (sent) {
                            return (
                                <Icon className="positive-color" type="check" />
                            );
                        } else {
                            return closeIcon;
                        }
                    }
                },
                {
                    title: 'Evaluation Completed',
                    dataIndex: 'memberopinionSet',
                    key: 'evaluationCompleted',
                    align: 'center',
                    render: (text, data) => {
                        const memberOpinion = data.memberopinionSet.filter(item => item.venture.id=ventureId)[0];
                        if (typeof memberOpinion === "undefined") {
                            return closeIcon;
                        }
                        const sent = memberOpinion.evaluation;
                        if (sent) {
                            return (
                                <Icon className="positive-color" type="check" />
                            );
                        } else {
                            return closeIcon;
                        }
                    }
                },
                {
                    title: 'Custom Message',
                    key: 'customMessage',
                    align: 'center',
                    render: (text, data) => {
                        return (
                            <Input
                                onChange={(e) => {
                                    let newState = this.state.messages;
                                    newState[`${data.id}`] = e.target.value;
                                    this.setState({
                                        messages: newState
                                    });
                                }}
                                placeholder="Write a message"
                            />
                        );
                    }
                }
            ];
        }
        this.setState({columns});
    }

    successEmail = () => {
        notification['success']({
            message: 'Mail sent',
            duration: 3.5,
        });
        const {ventureId, extraFunction, functionParams} = this.props;
        let {data, dataSuggested} = this.props;
        const {specificMembers, suggestedSpecificMembers} = this.state;
        let selectedMembers = data.filter(item => specificMembers.indexOf(item.id) >= 0);
        if (typeof  dataSuggested !== "undefined" && dataSuggested !== null) {
            selectedMembers = selectedMembers.concat(
                dataSuggested.filter(item => suggestedSpecificMembers.indexOf(item.id) >= 0));
        }
        selectedMembers.forEach(item => {
            if(item.memberopinionSet.length === 0) {
                item.memberopinionSet.push({
                    email: true,
                    venture: {id: ventureId}
                });
            } else {
                item.memberopinionSet.filter(member => member.venture.id === ventureId)[0].email = true;
            }
        });
        this.setState({
            specificMembers: [],
            suggestedSpecificMembers: [],
            selectedMembersRowKeys: [],
            selectedSuggestedRowKeys: [],
        });
        if (typeof extraFunction !== "undefined" && extraFunction !== null) {
            if (typeof functionParams !== "undefined" && functionParams !== null) {
                extraFunction(...functionParams);
            } else {
                extraFunction();
            }
        }
    };

    prepareMessages = (data) => {
        let response = [];
        const { generalMessage } = this.state;
        data.forEach((item) => {
            const message = this.state.messages[`${item.id}`];
            let customMessage = {
                id: item.id,
                message: ""
            };
            if (!isUndefined(generalMessage) && generalMessage !== "") {
                customMessage = {
                    id: item.id,
                    message: generalMessage
                };
            }
            if (!isUndefined(message) && message !== "") {
                customMessage = {
                    id: item.id,
                    message: message
                };
            }
            response.push(customMessage);
        });
        return response;
    };

    render() {
        const { data, ventureId, extraButton, dataSuggested, type } = this.props;
        const { suggestedSpecificMembers, columns } = this.state;
        let {specificMembers} = this.state;
        const showSuggested = type !== 'IM';
        const rowSelection = {
            selectedRowKeys: this.state.selectedMembersRowKeys,
            selections: true,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    specificMembers: selectedRows.map(item => {
                        return { id: item.id, message: isUndefined(item.customMessage) ? "" : item.customMessage };
                    }),
                    selectedMembersRowKeys: selectedRowKeys
                });
            },
        };
        const suggestedRowSelection = {
            selectedRowKeys: this.state.selectedSuggestedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    suggestedSpecificMembers: selectedRows.map(item => {
                        return { id: item.id, message: isUndefined(item.customMessage) ? "" : item.customMessage };
                    }),
                    selectedSuggestedRowKeys: selectedRowKeys
                });
            },
        };
        const existSuggest = typeof dataSuggested !== "undefined" && dataSuggested !== null && dataSuggested.length > 0;
        let defaultActiveKey = "all";
        let allMembersId = data.map((item) => item.id);
        let allMembersJson = data.map((item) => {
            return { id: item.id };
        });
        let suggestedMembersId = [];
        let suggestedMembersJson = [];
        let columnsSuggested = [...columns];
        columnsSuggested.push(
            {
                title: 'Match Score',
                dataIndex: 'score',
                key: 'score',
                align: 'center',
                render: (text, data) => {
                    return (<p>{text} %</p>);
                }
            },
        );
        if (existSuggest){
            suggestedMembersId = dataSuggested.map((item) => item.id);
            defaultActiveKey = "suggested";
            allMembersId = allMembersId.concat(suggestedMembersId);
            suggestedMembersJson = suggestedMembersId.map((item) => {
                return { id: item };
            });
            allMembersJson = allMembersId.map((item) => {
                return { id: item };
            });
            specificMembers = specificMembers.concat(suggestedSpecificMembers);
        }
        return (
            <div>
                {typeof extraButton !== "undefined" && extraButton}
                <Mutation mutation={memberInvitation} onCompleted={() => this.successEmail()}
                          onError={() => notification['error']({
                              message: 'Network Error',
                              duration: 3.5,
                          })}
                          variables={{
                              ventureId,
                              membersId: this.prepareMessages(allMembersJson)
                          }}
                >
                    {(sendMemberEmail, {loading}) => (
                        <Button icon="mail" onClick={sendMemberEmail} loading={loading}>
                            Send all
                        </Button>
                    )}
                </Mutation>
                <Mutation mutation={memberInvitation} onCompleted={() => this.successEmail()}
                          onError={(error) => notification['error']({
                              message: 'Network Error',
                              duration: 3.5,
                          })}
                          variables={{
                              ventureId,
                              membersId: this.prepareMessages(suggestedMembersJson)
                          }}
                >
                    {(sendMemberEmail, {loading}) => (
                        showSuggested &&
                        <Button
                            icon="mail"
                            className="mx-10px"
                            onClick={sendMemberEmail}
                            loading={loading}
                            disabled={!existSuggest}
                        >
                            Send to suggested members
                        </Button>
                    )}
                </Mutation>
                <Mutation mutation={memberInvitation} onCompleted={() => this.successEmail()}
                          onError={(error) => notification['error']({
                              message: 'Network Error',
                              duration: 3.5,
                          })}
                          variables={{
                              ventureId,
                              membersId: this.prepareMessages(specificMembers)
                          }}
                >
                    {(sendMemberEmail, {loading}) => (
                        <Button
                            icon="mail"
                            className="mx-10px"
                            onClick={sendMemberEmail}
                            loading={loading}
                            disabled={specificMembers.length === 0}
                        >
                            {`Send to ${specificMembers.length} items selected`}
                        </Button>
                    )}
                </Mutation>
                {
                    this.props.handleGeneralMessage &&
                    <Row className="mt-1 mb-1">
                        <Input
                            placeholder="Add General Custom Message"
                            onChange={(e) => {
                                this.setState({
                                    generalMessage: e.target.value
                                });
                            }}
                        />
                    </Row>
                }
                <Tabs defaultActiveKey={defaultActiveKey}>
                    {
                        showSuggested &&
                        <TabPane tab="Suggested" key="suggested">
                            <Table
                                className="my-1"
                                columns={columnsSuggested}
                                dataSource={dataSuggested}
                                pagination={{pageSize: 50}}
                                bordered
                                rowSelection={suggestedRowSelection}
                                rowKey="id"
                            />
                        </TabPane>
                    }
                    <TabPane tab="All" key="all">
                        <Table
                            className="my-1"
                            columns={columns}
                            dataSource={data}
                            pagination={{pageSize: 50}}
                            bordered
                            rowSelection={rowSelection}
                            rowKey="id"
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default ManageEmail;