import React from 'react';
import queryString from 'query-string';

import {
  authToken,
  homeView
} from '../../utils/ApiEndPoints';

import {
  Form,
  Icon,
  Input,
  Button,
  Col,
  Row,
  Layout
} from 'antd';

import './account.scss';
import {
  customBackgroundColor,
  customFontType
} from '../../styles/customStyles';
import { loginProcess } from '../../utils/tools';

const {Content} = Layout;


class NormalLoginForm extends React.Component {
  state = {
    error: false,
  };

  componentDidMount() {
    let access = localStorage.getItem('access');
    if (access) {
      window.location.replace(homeView);
    }
  }

  handleSubmit = (next, e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        fetch(authToken, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            if (res.ok) {
              res.json().then(res => {
                  if (res.access) {
                    const { access, refresh, type } = res;
                    loginProcess(access, refresh, type, this.props.next);
                  }
                }
              );
            } else {
              this.setState({error: true});
              console.log("Bad credentials");
            }
          })
          .catch(err => {
            console.log('Network error');
          });
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {error} = this.state;
    return (
      <Form onSubmit={(event) => this.handleSubmit(this.props.next, event)}>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{required: true}],
          })(
            <Input
              prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
              placeholder="Username"
              test-attr="test-username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{required: true}],
          })(
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Password"
              test-attr="test-password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {error ? <div className="has-error">
            <div
              className="ant-form-explain"
              test-attr="test-invalid-data-login"
            >
              You have entered an invalid username or password
            </div>
          </div> : null}
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            test-attr="test-login-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(NormalLoginForm);

export default class LoginView extends React.Component {
  render() {
    const {
      logoHeader,
      secondaryColor,
      fontType
    } = this.props.customConfig;
    const values = queryString.parse(this.props.location.search);
    return (
      <Layout style={customFontType(fontType)}>
        <Content className="login-custom-content" style={customBackgroundColor(secondaryColor)}>
          <Row className="login-custom-content" type="flex" justify="center"
               align="middle">
            <Col xs={20} sm={8} md={8} lg={8} xl={6} xxl={4}>
              <Col span={24} style={{textAlign: 'center'}}>
                <img className="logo-login" src={logoHeader} alt="Logo" />
              </Col>
              <WrappedNormalLoginForm next={values.next} />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}
