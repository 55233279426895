import React from 'react';
import {
  Row,
  Button,
} from 'antd';
import { API_URL } from '../../utils/constants';

const DownloadPdf = (props) => {
  const { pdf, title } = props;
  const havePdf = pdf !== null;

  return (
    <Row className="text-center">
      <Button
        icon="download"
        href={havePdf ? `${API_URL}/media/${pdf}` : undefined}
        target={havePdf ? `${API_URL}/media/${pdf}` : undefined}
        disabled={!havePdf}
      >
        {title}
      </Button>
    </Row>
  );
};

export default DownloadPdf;
