import React from 'react';
import { Modal, Button, Form, Row, Checkbox, Col } from 'antd';
import { isUndefined } from '../../../../../../utils/tools';

import './styles.scss';

const policies = [
  {
    description: "I consent to having Fincite Ventures to collect my name, e-mail address and the details I have shared within the form.",
  },
  {
    description: "I understand that Fincite Ventures shall use the data provided by me to assess the venture opportunity.",
  },
  {
    description: "I understand that Fincite Ventures shall share the particular data within it's network of advisors and investors.",
  },
];

const ModalDisclaimer = (props) => {
  const { visible, handleOk, form, handleCancel } = props;
  const { getFieldDecorator } = form;


  return (
    <Modal
      title="Terms and Conditions"
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
            key="submit"
            type="primary"
            onClick={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { accept_terms: acceptTerms } = values;
                  handleOk(acceptTerms.length === policies.length);
                }
              });
            }}
        >
          Send
        </Button>,
      ]}
    >
      <Row>
        <Form>
          <Row>
            You are about to send your profile. After this you will not be able to edit this anymore.
          </Row>
          <Row className="mt-1">
            This form collects your name and email so that we can correspond with you.
            Check out our
            {' '}
            <a
              target="_blank"
              href="https://fincite.ventures/imprint-privacy/"
              rel="noopener noreferrer"
            >
              imprint and data privacy policies
            </a>
            {' '}
            for more information.
          </Row>
          <Row className="mt-1">
            <Col span={22} offset={1}>
              <Form.Item>
                {getFieldDecorator('accept_terms', {
                  rules: [{
                    validator: (rule, value, callback) => {
                      if (isUndefined(value) || value.length !== policies.length) {
                        callback('Please accept all terms to send this form');
                      }
                      callback();
                    }
                  }],
                  validateTrigger: '',
                })(
                  <Checkbox.Group>
                    {
                      policies.map((item, index) => (
                        <Checkbox value={index} className="terms-box">{item.description}</Checkbox>
                      ))
                    }
                  </Checkbox.Group>,
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </Modal>
  );
};

export default Form.create({ name: 'modalDisclaimer' })(ModalDisclaimer);
