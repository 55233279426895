import { API_URL } from './constants';

const graphqlAPI = `${API_URL}/graphiql/`;
const authToken = `${API_URL}/api/token/`;
const refreshToken= `${API_URL}/api/token/refresh/`;
const verifyToken= `${API_URL}/api/token/verify/`;
const homeView = `/dashboard/venture/list/`;
const uploadPhoto = `${API_URL}/one_pager/photo/`;
const mediaUrl = `${API_URL}/media/`;
const uploadPitch = `${API_URL}/one_pager/pitch/`;

export {
  graphqlAPI,
  authToken,
  refreshToken,
  verifyToken,
  homeView,
  uploadPhoto,
  mediaUrl,
  uploadPitch,
}
