import React from 'react';
import {
  Button,
  Tag,
  notification,
  Typography,
} from "antd";
import { Mutation } from 'react-apollo';
import {
  mutationFounder,
  mutationInvestor
} from './mutation';
import {
  isUndefined,
  isNull,
} from '../../../utils/tools';
import { DEFAULT_EMPTY } from '../../../utils/constants';

const { Text } = Typography;

export const founderColumns = (refetch) => (
  [
    {
      title: <Text strong>Suggested By</Text>,
      dataIndex: 'suggestedBy',
      key: 'suggestedByFounder',
      align: 'center',
      render: (data) => {
        let suggestedByText = DEFAULT_EMPTY;
        if (!isUndefined(data) && !isNull(data)) {
          suggestedByText = `${data.firstName} ${data.lastName}`;
        }
        return (<Text strong>{suggestedByText}</Text>)
      }
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstNameFounder',
      align: 'center',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastNameFounder',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddressFounder',
      align: 'center',
    },
    {
      title: 'Validate',
      dataIndex: 'id',
      key: 'validateFounder',
      align: 'center',
      render: (founderId) => (
        <Mutation
          mutation={mutationFounder}
          onCompleted={() => {
            notification.success({
              message: 'Success!',
              description: 'Founder was validate succesfully',
              duration: 3.5,
              }
            );
            refetch();
          }}
          onError={(e) => {
            notification['error']({
              message: 'Network Error',
              duration: 3.5,
              }
            );
          }
          }
          variables={{
            founderIds: [founderId],
          }}
        >
          {(validateFounder, {loading}) => (
            <Button onClick={validateFounder} loading={loading}>
                Validate
            </Button>
          )}
        </Mutation>
      )
    },
  ]
);

export const investorColumns = (refetch) => (
  [
    {
      title: <Text strong>Suggested By</Text>,
      dataIndex: 'suggestedBy',
      key: 'suggestedBy',
      align: 'center',
      render: (data) => {
        let suggestedByText = DEFAULT_EMPTY;
        if (!isUndefined(data) && !isNull(data)) {
          suggestedByText = `${data.firstName} ${data.lastName}`;
        }
        return (<Text strong>{suggestedByText}</Text>)
      }
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'center',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      align: 'center',
    },
    {
      title: 'Interests',
      dataIndex: 'answerSet',
      key: 'answerSet',
      align: 'center',
      render: (data) => {
        return data.map((item) => (
          item.answerextratextSet.map((tag) => {
            const { extraText } = tag;
            const { name, id } = tag.choice;
            return (
              <Tag
                key={`answer${item.id}-${id}`}
                color="orange"
              >
                { extraText !== null ? extraText : name}
              </Tag>
            );
          })
        ));
      }
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      align: 'center',
      render: (data) => {
        return data.map((tag) => (
          <Tag
            key={`tag${tag.id}`}
            color="orange"
          >
            {tag.name}
          </Tag>
        ));
      }
    },
    {
      title: 'Validate',
      dataIndex: 'id',
      key: 'validate',
      align: 'center',
      render: (investorId) => (
        <Mutation
          mutation={mutationInvestor}
          onCompleted={() => {
            notification.success({
              message: 'Success!',
              description: 'Investor was validate succesfully',
              duration: 3.5,
              }
            );
            refetch();
          }}
          onError={(e) => {
            notification['error']({
              message: 'Network Error',
              duration: 3.5,
              }
            );
          }
          }
          variables={{
            memberIds: [investorId],
          }}
        >
          {(validateInvestor, {loading}) => (
            <Button onClick={validateInvestor} loading={loading}>
                Validate
            </Button>
          )}
        </Mutation>
      )
    },
  ]
);
