import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import {
  Row,
  Col,
  Typography,
  Table,
  Button,
  Icon,
  Breadcrumb
} from 'antd';

import {Link} from 'react-router-dom';
import MessageComponent from '../../../components/Message';
import { mainContainerBody } from '../../../styles/responsive';

const {Title} = Typography;


const query = gql`
{
  allManagers{
    id
    firstName
    lastName
    emailAddress
    memberopinionSet {
      id
      venture{
        id
        companyName
      }
    }
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};


class ManagerListView extends React.Component {

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (text, data) => (
          <Link
            to={`/dashboard/member/manager/id/${data.id}/`}>{text} {data.lastName}
          </Link>
        )
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
    ];


    return (
      <Row type="flex" justify="space-around" align="middle">
        <Col {...mainContainerBody}>
          <Breadcrumb className="dashboard-breadcrumb">
            <Breadcrumb.Item>
              <Link to="/dashboard/member/manager/list/">
                Managers
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Title className="dashboard-title" level={1}>
            Managers
          </Title>

          <Link to="/dashboard/member/manager/add/">
            <Button className="dashboard-button" style={{marginLeft: '10px'}} type="primary">
              <Icon type="plus"/> Add
            </Button>
          </Link>

          <Query query={query}>
            {({loading, error, data, refetch}) => {
              if (loading) return (
                <MessageComponent
                  icon="loading"
                  iconSize={50}
                  text="Loading"
                  level={4}/>
              );
              if (error) return (
                <MessageComponent
                  icon="smile"
                  iconSize={50}
                  text={`${error.message}`}
                  level={4}/>
              );
              if (this.props.location.state !== undefined) {
                refetch();
              }

              data.allManagers = addKeys(data.allManagers);

              return (
                    <Table
                      columns={columns}
                      dataSource={data.allManagers}
                      pagination={{pageSize: 50}}
                      bordered
                    />
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default ManagerListView;