import React from 'react';
import {
  Col,
  Typography,
  Row,
} from 'antd';
import { contentComplete } from '../../../../../../utils/tools';
import RightSectionItem from '../RightSectionItem';

const { Title } = Typography;

const RightSection = (props) => {
  const {
    itemRightList,
    isEditingExtra,
    checkLimitsAddErase,
    rightList,
    onAddItem,
    displayEditable,
    onChangeField,
    onEraseItem,
  } = props;
  const itemContent = {
    xs: { span: 14, offset: 5 },
    sm: { span: 12, offset: 6 },
    md: { span: 18, offset: 3 },
  };

  const createNew = () => {
    const newInfo = {
      arrayId: 0,
      number: '00',
      quantity: '€ Billion +',
      year: '2019',
      preText: '',
      postText: '',
      title: 'Specific Region within 2 yrs',
      isComplete: false,
    };

    return newInfo;
  };

  return (
    (isEditingExtra(contentComplete(itemRightList.content)))
      && (
      <Col
        key={itemRightList.arrayId}
        className="right-column-container"
        span={24}
      >
        <Title
          className="section-title"
          level={2}
        >
          {itemRightList.title}
        </Title>
        <Col
          {...itemContent}
        >
          {
            itemRightList.content.map((item) => (
              <RightSectionItem
                item={item}
                sectionId={itemRightList.arrayId}
                isEditingExtra={isEditingExtra}
                displayEditable={displayEditable}
                onChangeField={onChangeField}
                checkLimitsAddErase={checkLimitsAddErase}
                rightList={rightList}
                onEraseItem={onEraseItem}
              />
            ))
          }
          <Row type="flex" justify="center" align="middle">
            {checkLimitsAddErase(
              itemRightList.arrayId, true,
              rightList, onAddItem, 'plus-container-right', null, 'rightList', createNew,
            )}
          </Row>
        </Col>
      </Col>
      )
  );
};

export default RightSection;
