import React from 'react';

import MessageComponent from '../Message';
import DueDiligenceComponent from '../DueDiligence';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';

const due_query = gql`
query QueryDiligences(
  $pre_evaluation: Boolean!,
  $admin: Boolean!,
  $manager: Boolean!,
  $investment: Boolean!,
) {
  allDuesAdmin(
    preEvaluation: $pre_evaluation,
    admin: $admin,
    manager: $manager,
    investment: $investment,
  ){
    id
    name
    questionSet {
      id
      name
      description
      order
      showToAdmin
      showToManager
      showToInvestment
      options {
        id
        name
      }
      subsection {
        id
        name
      }
    }
  }
  allSubsections {
    id
    name
    order
  }
}
`;

export default class QuestionnaireComponent extends React.Component {
  render() {
    const {pre_evaluation, admin, manager, investment, ventureData, changeTab, newTab} = this.props;
    return (
      <Query
        query={due_query}
        variables={
          {
            pre_evaluation,
            admin,
            manager,
            investment
          }
        }
      >
        {
          ({loading, error, data, refetch}) => {
            const shouldShowLoading = loading && !data.allDuesAdmin;
            if (shouldShowLoading) {
              return (
                <MessageComponent
                  icon="loading"
                  iconSize={50}
                  text="Loading Due Diligence"
                  level={4}/>
              );
            }
            if (error) return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}/>
            );
            return (
              <DueDiligenceComponent
                pre_evaluation={pre_evaluation}
                admin={admin}
                manager={manager}
                investment={investment}
                data={data.allDuesAdmin}
                member=""
                venture={ventureData}
                changeTab={changeTab}
                newTab={newTab}
                subsections={data.allSubsections}
              />
            );
          }
        }
      </Query>
    );
  }
}
