import React from 'react';

import {Query} from 'react-apollo';
import gql from 'graphql-tag';

import OnePageRenderer from './OnePagerRenderer';
import MessageComponent from "../../../components/Message";

const query = gql`
query Query($venture_id: ID!){
  sections{
    id
    order
    minItems
    maxItems
    title
    itemTitleExample
    itemDescriptionExample
    defaultsectionitemSet{
      title
      description
      order
    }
  }
  sectionsRightCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsChecklist{
    id
    order
    minItems
    maxItems
    title
    boldTitle
  }
  sectionsProfile{
    id
    order
    minItems
    maxItems
  }
  contentSections(idVenture: $venture_id){
    id
    order
    icon
    title
    description
    isComplete
    section{
      id
    }
  }
  contentSectionsRightCircle(idVenture: $venture_id){
    id
    order
    number
    quantity
    year
    preText
    postText
    title
    isComplete
    rightCircleSection{
      id
    }
  }
  contentSectionsCircle(idVenture: $venture_id){
    id
    order
    initPercentage
    endPercentage
    percentageText
    percentageComplete
    circlesectioncontentSet{
      id
      order
      number
      quantity
      description
      isComplete
    }
    circleSection{
      id
    }
  }
  contentSectionsChecklist(idVenture: $venture_id){
    id
    order
    title
    description
    isComplete
    checklistSection{
      id
    }
  }
  contentSectionsProfile(idVenture: $venture_id){
    id
    order
    name
    linkedin
    isComplete
    profilesectioncontentSet{
      id
      order
      description
    }
    profileSection{
      id
    }

  }
  profilePhotos(idVenture: $venture_id){
    id
    order
    image
  }
  pitchVenture(idVenture: $venture_id){
    id
    pitch
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};

class OnePagerView extends React.Component {

  render() {
    let venture_id = this.props.venture.id;
    return (
      <Query query={query} variables={{venture_id}}>
        {({loading, error, data, refetch}) => {
          if (loading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text=""
              level={4}/>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );

          data.sections = addKeys(data.sections);
          data.sectionsRightCircle = addKeys(data.sectionsRightCircle);
          data.sectionsCircle = addKeys(data.sectionsCircle);
          data.sectionsChecklist = addKeys(data.sectionsChecklist);
          data.sectionsProfile = addKeys(data.sectionsProfile);
          data.contentSections = addKeys(data.contentSections);
          data.contentSectionsRightCircle = addKeys(data.contentSectionsRightCircle);
          data.contentSectionsCircle = addKeys(data.contentSectionsCircle);
          data.contentSectionsChecklist = addKeys(data.contentSectionsChecklist);
          data.contentSectionsProfile = addKeys(data.contentSectionsProfile);
          data.profilePhotos = addKeys(data.profilePhotos);
          return (
            <OnePageRenderer
              sections={data.sections}
              sectionsRightCircle={data.sectionsRightCircle}
              sectionsCircle={data.sectionsCircle}
              sectionsChecklist={data.sectionsChecklist}
              sectionsProfile={data.sectionsProfile}
              contentSections={data.contentSections}
              contentSectionsRightCircle={data.contentSectionsRightCircle}
              contentSectionsCircle={data.contentSectionsCircle}
              contentSectionsChecklist={data.contentSectionsChecklist}
              contentSectionsProfile={data.contentSectionsProfile}
              profilePhotos={data.profilePhotos}
              pitchVenture={data.pitchVenture}
              venture={this.props.venture}
              preview={false}
            />
          );
        }}
      </Query>

    );
  }
}

export default OnePagerView;