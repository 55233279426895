import React from 'react';
import {
  Col,
  List,
  Typography,
  Row,
} from 'antd';
import { contentComplete } from '../../../../../../utils/tools';
import ChecklistSectionItem from '../ChecklistSectionItem';

const { Text } = Typography;

const ChecklistSection = (props) => {
  const {
    itemCheckList,
    checkList,
    isEditingExtra,
    checkLimitsAddErase,
    onAddItem,
    displayEditable,
    onChangeField,
    onEraseItem,
  } = props;
  const checkListContent = {
    xs: { span: 22, offset: 0 },
    sm: { span: 22, offset: 0 },
    md: { span: 10, offset: 1 },
    lg: { span: 10, offset: 1 },
    xl: { span: 10, offset: 1 },
    xxl: { span: 10, offset: 1 },
  };
  const createNew = () => {
    const newInfo = {
      arrayId: 0,
      title: 'Add some benefit',
      description: '(Add short description)',
      isComplete: false,
    };

    return newInfo;
  };

  return (
    (isEditingExtra(contentComplete(itemCheckList.content)))
    && [
      <Col key={itemCheckList.arrayId} className="check-column-container" {...checkListContent}>
        <List itemLayout="horizontal">
          <List.Item>
            <List.Item.Meta
              title={(
                <div className="text-center">
                  <Text className="check-section-title">
                    {`${itemCheckList.title} `}
                  </Text>
                  {itemCheckList.boldTitle
                      && (
                      <Text
                        className="check-section-title"
                        strong
                      >
                        {' '}
                        -
                        {' '}
                        {itemCheckList.boldTitle}
                      </Text>
                      )}
                </div>
                )}
              description={(
                <div className="check-list-item-content">
                  {
                      itemCheckList.content.map((item) => (
                        <ChecklistSectionItem
                          item={item}
                          sectionId={itemCheckList.arrayId}
                          isEditingExtra={isEditingExtra}
                          displayEditable={displayEditable}
                          onChangeField={onChangeField}
                          checkLimitsAddErase={checkLimitsAddErase}
                          checkList={checkList}
                          onEraseItem={onEraseItem}
                        />
                      ))
                    }
                </div>
                )}
            />
          </List.Item>
        </List>
        <Row type="flex" justify="center" align="middle">
          {checkLimitsAddErase(
            itemCheckList.arrayId,
            true, checkList, onAddItem,
            'plus-container-checklist',
            null, 'checkList', createNew,
          )}
        </Row>
      </Col>,
      <Col xs={0} sm={0} md={1} lg={1} xl={1} xxl={1} />,
    ]
  );
};

export default ChecklistSection;
