import React from 'react'
import {
  Affix,
  Dropdown,
  Icon,
  Menu
} from "antd";

import SiderButton from "../SiderButton";
import {isUndefined, logoutProcess} from "../../utils/tools";
import {isDarker} from "../../styles/tools";

const TopMenu = (props) => {
  const { customConfig } = props;
  const darkTheme = isDarker(customConfig.secondaryColor);
  const logOutMenu = (
    <Menu className="custom-logout">
      <Menu.Item
        key="account:1"
        onClick={() => logoutProcess()}
        test-attr="test-logout-button"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return(
    <Affix offsetTop={0}>
      <Menu
        mode="horizontal"
        style={{
          backgroundColor: customConfig.secondaryColor,
          color: darkTheme ? 'white' : 'black',
        }}
      >
        <SiderButton darkTheme={darkTheme} />
        <Dropdown overlay={logOutMenu} className="float-r edge-r">
              <span className="ml-1"><Icon type="user" className="header-user-icon" style={{
                borderColor: darkTheme ? 'white' : 'black',
                color: darkTheme ? 'white' : 'black',
              }} /></span>
        </Dropdown>
        <span className="float-r">
              Welcome {!isUndefined(props.data.currentUser) ? `, ${props.data.currentUser.username}` : ""}
            </span>
      </Menu>
    </Affix>
  )
};

export default TopMenu;
