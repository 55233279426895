import React from 'react'
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Typography,
  Breadcrumb,
  notification
} from "antd";

import {
  Link,
  withRouter
} from "react-router-dom";

const {Title} = Typography;

const mutation = gql`
mutation Mutation(
  $emailAddress: String!,
  $firstName: String!,
  $lastName: String!,
  $type: String!
) {
  createMember(
    emailAddress: $emailAddress,
    firstName: $firstName,
    lastName: $lastName,
    type: $type,
    enableLogin: true,
  ) {
    status
    member{
      id
      firstName
      lastName
      emailAddress
      type
    }
  }
}
`;


class AddManagerForm extends React.Component {
  state = {
    saveLoading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saveLoading: true});
        this.props
          .mutate({
            variables: {
              firstName: values['First Name'],
              lastName: values['Last Name'],
              emailAddress: values['Email Address'],
              type: 'IM',
            }
          })
          .then(res => {
            this.setState({saveLoading: false});
            if (res.data.createMember.status === 200) {
              this.props.history.push({
                pathname: '/dashboard/member/manager/list',
                state: {reload: true}
              });
              notification['success']({
                message: 'Manager created!',
                duration: 3.5,
              });
            }
            if (res.data.errors) {
              notification['error']({
                message: 'An error have occurred',
                duration: 3.5,
              });
              console.log(res.data.errors);
            }
          })
          .catch(err => {
            this.setState({saveLoading: false});
            if (err.graphQLErrors) {
              for (let i = 0; i < err.graphQLErrors.length; i++) {
                notification['error']({
                  message: 'Network Error',
                  description: err.graphQLErrors[i].message,
                  duration: 3.5,
                });
              }
            }
            console.log('Network error');
          })
      }
    });
  };


  render() {
    const {getFieldDecorator} = this.props.form;
    const {saveLoading} = this.state;
    return (
      <div>
        <Title level={1}>New Manager</Title>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item
            label="First Name"
          >
            {getFieldDecorator('First Name', {
              rules: [{
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="Manager first name"/>
            )}
          </Form.Item>
          <Form.Item
            label="Last Name"
          >
            {getFieldDecorator('Last Name', {
              rules: [{
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="Manager last name"/>
            )}
          </Form.Item>
          <Form.Item
            label="Email Address"
          >
            {getFieldDecorator('Email Address', {
              rules: [{
                type: 'email',
              }, {
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="example@mail.com"/>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit"
                    className="login-form-button"
                    loading={saveLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>

    );
  }
}

AddManagerForm = withRouter(AddManagerForm);

AddManagerForm = graphql(mutation)(AddManagerForm);
const WrappedAddManagerForm = Form.create({name: 'manager_add'})(AddManagerForm);


export default class ManagerAddView extends React.Component {
  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
            <Breadcrumb className="dashboard-breadcrumb">
              <Breadcrumb.Item>
                <Link to="/dashboard/member/manager/list/">
                  Managers
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Add
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10} className="dashboard-table">
            <WrappedAddManagerForm/>
          </Col>
        </Row>
      </div>
    )
  }
}
