export const API_URL = typeof process.env.REACT_APP_DEV_API_URL !== 'undefined' ?
    process.env.REACT_APP_DEV_API_URL : ''; /** global: API_URL */
export const LINKEDIN_USER_URL = "https://www.linkedin.com/in/"; /** global: LINKEDIN_USER_URL */
export const INTERNAL_MANAGER = 'IM'; /** global: INTERNAL_MANAGER */
export const INVESTMENT_COMMITTEE = 'IC'; /** global: INVESTMENT_COMMITTEE */
export const ADMIN = 'admin'; /** global: ADMIN */
export const API_KEY = typeof process.env.REACT_APP_DEV_API_KEY !== 'undefined' ?
process.env.REACT_APP_DEV_API_KEY : ''; /** global: API_KEY */

export const DEFAULT_PRIMARY_COLOR = '#0b66cc';
export const DEFAULT_SECONDARY_COLOR = '#13344a';
export const DEFAULT_TERTIARY_COLOR = '#fec011';

export const DEFAULT_EMPTY = '----------';
