import React from "react";
import {Col, Row, Icon, Typography} from "antd";

const {Title} = Typography;

export default class MessageComponent extends React.Component {
  render() {
    const {icon, iconSize, text, subText, level, subLevel, theme, twoToneColor} = this.props;

    return (
      <Row style={{minHeight: '60vh'}} type="flex" align="middle" justify="center">
        <Col span={12} style={{textAlign: 'center'}}>
          <Icon theme={theme} twoToneColor={twoToneColor} style={{fontSize: iconSize}} type={icon}/>
          <Title style={{marginTop: '40px'}} level={subLevel}>{subText}</Title>
          <Title style={{marginTop: '40px'}} level={level}>{text}</Title>
        </Col>
      </Row>
    );
  }
}