import { notification } from "antd";

export const validateDescription = (description) => {
  const valid = description.length <= 255;
  if(!valid){
    notification['error']({
      message: 'Description error',
      description: 'The description must not exceed 256 characters.',
      duration: 3.5,
    });
  }
  return valid;
};

export const validateTitle = (title) => {
  const valid = title.length <= 80;
  if(!valid){
    notification['error']({
      message: `Title error`,
      description: `The title must not exceed 80 characters.`,
      duration: 3.5,
    });
  }
  return valid;
};

export const validateNumber = (number) => {
  const valid = number.length <= 10;
  if(!valid){
    notification['error']({
      message: 'Number error',
      description: 'This number must not exceed 10 digits.',
      duration: 3.5,
    });
  }
  return valid;
};

export const validateName = (name) => {
  const valid = name.length <= 80;
  if(!valid){
    notification['error']({
      message: `Name error`,
      description: `The name must not exceed 80 characters.`,
      duration: 3.5,
    });
  }
  return valid;
};
