import React, { useContext } from 'react';
import { Drawer } from 'antd';
import SiderContent from '../SiderContent';
import { SiderContext } from '../../../contexts';

import './styles.scss';

const SiderDrawer = ({ customConfig }) => {
  const siderContext = useContext(SiderContext);

  return (
    <Drawer
      className="custom-drawer"
      width={240}
      closable={false}
      placement="left"
      visible={siderContext.drawerVisible}
      onClose={() => {
        siderContext.setDrawerVisible(false);
      }}
      destroyOnClose
    >
      <SiderContent customConfig={customConfig} />
    </Drawer>
  );
};

export default SiderDrawer;