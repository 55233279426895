import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Tag,
  Typography,
  Icon,
  Row,
  Modal,
  notification,
} from 'antd';
import CustomTag from '../CustomTag';

const {
  Text,
  Title,
} = Typography;

const mutation = gql`
mutation AddTags($memberId: ID!, $tags:[Int!]) {
  addTagsMember(memberId: $memberId, tags: $tags) {
    status
    member {
      tags {
        id
        name
      }
    }
  }
}
`;
const query = gql`
{
  tags(showInvestor: true) {
    id
    name
  }
}
`;

class InvestorInfo extends Component {
  constructor(props) {
    super(props);

    const tagsList = props.memberTags.map((tag) => tag.id);
    this.state = {
      visible: false,
      defined: tagsList,
      selected: null,
      memberTags: props.memberTags,
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onSelectTag = (checked, tagId) => {
    const { defined, selected } = this.state;
    let checkedTags = [];
    if (selected !== null) {
      checkedTags = selected;
    } else {
      checkedTags = defined;
    }
    if (checked) {
      checkedTags.push(tagId);
    } else {
      const index = checkedTags.indexOf(tagId);
      checkedTags.splice(index, 1);
    }
    this.setState({ selected: checkedTags });
  };

  handleOk = () => {
    const { memberId, mutate } = this.props;
    const { selected } = this.state;
    mutate({ variables: { memberId, tags: selected } })
      .then((res) => {
        const data = res.data.addTagsMember;
        if (data.status === 200) {
          const memberTags = data.member.tags;
          const currentTags = memberTags.map((item) => item.id);
          this.setState({ selected: currentTags, memberTags, defined: currentTags });
          notification.success({
            message: 'Tags changed to this investor successfully',
            duration: 3.5,
          });
          this.setState({ visible: false });
        } else {
          notification.error({
            message: 'Network error',
            duration: 3.5,
          });
          this.setState({ visible: false });
        }
      })
      .catch(() => {
        notification.error({
          message: 'Network error',
          duration: 3.5,
        });
        this.setState({ visible: false });
      });
  };

  render() {
    const {
      memberData, answerSet, data,
    } = this.props;
    const { visible, defined, memberTags } = this.state;
    const { tags } = data;
    const generalTags = tags || [];

    return (
      <Row>
        <Modal
          title="Modify tags for this investor"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="text-center">
            {generalTags.map((tag) => (
              <CustomTag
                value={tag.id}
                checked={defined.indexOf(tag.id) >= 0}
                onSelectTag={this.onSelectTag}
              >
                {tag.name}
              </CustomTag>
            ))}
          </div>
        </Modal>
        <Title className="dashboard-title" level={1}>
          {memberData.firstName}
          {' '}
          {memberData.lastName}
        </Title>
        <Title
          className="dashboard-title dashboard-button"
          level={4}
        >
          {memberData.emailAddress}
        </Title>
        {
          answerSet.map((extra) => (
            <div
              className="dashboard-title dashboard-button"
              key={`answerSet${extra.question.name}`}
            >
              <Text strong>
                {extra.question.name}
                {' '}
                {
                  extra.choices.map((choice, choiceIndex) => {
                    return (
                      <Tag
                        key={choiceIndex}
                        color="orange"
                      >
                        {choice.name}
                      </Tag>
                    );
                  })
                }
              </Text>
            </div>
          ))
        }
        <div className="dashboard-title dashboard-button">
          <Text strong>
            Tags
            {' '}
            <Icon
              type="edit"
              onClick={() => {
                this.setState({
                  visible: true,
                });
              }}
            />
          </Text>
          <Text strong>
            {memberTags.map((tag) => (<Tag key={tag.id} color="orange">{tag.name}</Tag>))}
          </Text>
        </div>
      </Row>
    );
  }
}

export default graphql(query)(graphql(mutation)(InvestorInfo));
