import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import {
  Row,
  Col,
  Statistic,
  Icon,
  Card,
  Tag,
  Comment
} from 'antd';

import MessageComponent from '../../../components/Message';

import {Typography} from 'antd';

const {Title} = Typography;


const query = gql`
query Query(
  $venture_id: Int!,
  $pre_evaluation: Boolean!,
  $member_type: String!,
) {
  membersResultDiligence(
    ventureId: $venture_id,
    preEvaluation: $pre_evaluation,
    memberType:$member_type,
  ){
    results{
      diligence
      result
      extra
      good
    }
    member{
      id
      firstName
      lastName
    }
  }
}
`;


const OpinionQuery = gql`
query OpinionDetailsQuery(
  $member_id: Int!,
  $venture_id: Int!,
) {
  opinionId(
    memberId: $member_id,
    ventureId: $venture_id,
  ){
    id
    email
    linkUsed
    evaluation
    comment
  }
}
`;


class MembersResultsView extends React.Component {

  getDetailsDisplay(value, text) {
    if (value === true) {
      return (
        <Tag
          color="green">
          {text}
        </Tag>
      );

    } else {
      return (
        <Tag
          color="red">
          {text}
        </Tag>
      );
    }
  }

  getCommentDisplay(text) {
    if (!text) {
      return null;
    }
    return (
      <Comment
        style={{marginLeft: '20px'}}
        avatar={
          <Icon type="message"/>
        }
        content={text}
      />
    );
  }

  render() {
    const {venture, pre_evaluation, member_type, title} = this.props;
    let venture_id = venture.id;
    let thisContainer = this;

    return (
      <div>
        <Title className="dashboard-title" level={3}>
          {title}
        </Title>
        <Query
          query={query}
          variables={
            {
              venture_id,
              pre_evaluation,
              member_type,
            }
          }
          pollInterval={500}
        >
          {({loading, error, data}) => {
            const shouldShowLoading = loading && !data.membersResultDiligence;
            if (shouldShowLoading) return (
              <Row style={{minHeight: '20vh'}} type="flex" align="middle" justify="center">
                <Icon style={{fontSize: 20}} type="loading"/>
              </Row>
            );
            if (error) return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}/>
            );

            let finalResult = [];

            data.membersResultDiligence.forEach(function (memberResult, memberIndex) {
              let allResults = [];

              memberResult.results.forEach(function (result, index) {
                let color = '#cf1322';
                let icon = 'close';
                if (result.good) {
                  color = '#3f8600';
                  icon = 'check';
                }
                if (result.result === 0) {
                  color = '';
                  icon = 'minus';
                }
                allResults.push(
                  <Col
                    style={{marginTop: '20px'}}
                    key={index} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Card>
                      <Statistic
                        title={result.diligence}
                        value={result.result}
                        precision={2}
                        valueStyle={{color: color}}
                        prefix={<Icon type={icon}/>}
                        suffix={result.extra}
                      />
                    </Card>
                  </Col>
                );
              });

              let member_id = memberResult.member.id;

              finalResult.push(
                <Col
                  key={memberIndex} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Title className="dashboard-title" level={4}>
                    {memberResult.member.lastName} {memberResult.member.firstName}
                    <Query
                      query={OpinionQuery}
                      variables={
                        {
                          venture_id,
                          member_id,
                        }
                      }
                      pollInterval={500}
                    >
                      {({loading, error, data}) => {
                        const shouldShowLoading = loading && !data.opinionId;
                        if (shouldShowLoading) return (
                          <Row style={{minHeight: '20vh'}} type="flex" align="middle" justify="center">
                            <Icon style={{fontSize: 20}} type="loading"/>
                          </Row>
                        );
                        if (error) return (
                          <MessageComponent
                            icon="smile"
                            iconSize={50}
                            text={`${error.message}`}
                            level={4}/>
                        );

                        let email = thisContainer.getDetailsDisplay(data.opinionId.email, 'Email Received');
                        let linkUsed = thisContainer.getDetailsDisplay(data.opinionId.linkUsed, 'Link used');
                        let evaluation = thisContainer.getDetailsDisplay(data.opinionId.evaluation, 'Evaluation sent');
                        let comment = thisContainer.getCommentDisplay(data.opinionId.comment);

                        return (
                          <div>
                            {email}
                            {linkUsed}
                            {evaluation}
                            {comment}
                          </div>
                        );
                      }}
                    </Query>
                  </Title>
                  {allResults}
                </Col>
              );

            });

            return (
              <Row style={{marginBottom: '100px'}} type="flex" justify="space-around" align="middle" gutter={100}>
                {finalResult}
              </Row>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default MembersResultsView;
