import React from 'react';
import {
  Row,
  Typography,
} from 'antd';
import { validateNumber, validateTitle } from '../../tools';

const { Title, Text } = Typography;

const RightSectionItem = (props) => {
  const {
    item,
    sectionId,
    isEditingExtra,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    rightList,
    onEraseItem,
  } = props;
  const {
    isComplete,
    arrayId,
    number,
    quantity,
    preText,
    year,
    title,
    postText,
  } = item;

  return (
    (isEditingExtra(isComplete))
    && (
    <div
      key={arrayId}
      className={!isComplete ? 'incomplete-field' : ''}
      data-cy="test-container-div-rightSectionItem"
    >
      <div
        className="circle-right-container"
      >
        <div
          className="edit big-number"
          data-cy="test-number-title-rightSectionItem"
        >
          <Title
            className="pd-0 mg-0 big-number"
            onClick={(e) => displayEditable(e)}
            editable={{
              onChange: (event) => onChangeField(
                arrayId, sectionId, event, 'rightList', 'number', validateNumber,
              ),
            }}
          >
            {number}
          </Title>
        </div>
        <div
          className="edit median-text"
          data-cy="test-quantity-title-rightSectionItem"
        >
          <Title
            level={4}
            className="pd-0 mg-0"
            onClick={(e) => displayEditable(e)}
            editable={{
              onChange: (event) => onChangeField(
                arrayId, sectionId, event, 'rightList', 'quantity',
              ),
            }}
          >
            {quantity}
          </Title>
        </div>
        <div
          className="edit small-number"
          data-cy="test-year-text-rightSectionItem"
        >
          <Text>{preText}</Text>
          <Text
            onClick={(e) => displayEditable(e)}
            editable={{
              onChange: (event) => onChangeField(
                arrayId, sectionId, event, 'rightList', 'year',
              ),
            }}
          >
            {year}
          </Text>
          <Text>{postText}</Text>
        </div>
      </div>
      <div
        className="edit small-text-center"
        data-cy="test-title-text-rightSectionItem"
      >
        <Text
          onClick={(e) => displayEditable(e)}
          editable={{
            onChange: (event) => onChangeField(
              arrayId, sectionId, event, 'rightList', 'title', validateTitle,
            ),
          }}
        >
          {title}
        </Text>
      </div>
      <Row type="flex" justify="center" align="bottom">
        {checkLimitsAddErase(
          sectionId, false, rightList, onEraseItem, 'close-container', item, 'rightList',
        )}
      </Row>
    </div>
    )
  );
};

export default RightSectionItem;
