import React from 'react';
import VentureComponent from "../../../components/Venture";


class VentureListView extends React.Component {
  render() {
    return (
      <VentureComponent
        id_founder={false}
      />
    );
  }
}

export default VentureListView;