import React from "react";
import { Tag } from "antd";

import './styles.scss';

const {CheckableTag} = Tag;

class CustomTag extends React.Component {
    /*
    * Custom Tag Component to check and update value to parent
    *
    * @param: {checked}: Initial boolean checked
    * @param: {onSelectTag}: Function to do something like update parent state, taking account checked and value
    * */
    state = {checked: false};

    componentDidMount() {
        this.setState({checked: this.props.checked});
    }

    handleChange = checked => {
        this.setState({checked});
        if (typeof this.props.onSelectTag !== "undefined") {
            this.props.onSelectTag(checked, this.props.value);
        }
    };

    render() {
        return (
            <CheckableTag
                {...this.props}
                checked={this.state.checked}
                onChange={this.handleChange}
                className="custom-tag"
            />
        );
    }
}

export default CustomTag;
