import React from 'react';
import {
  Icon,
  Col,
  Row,
  Upload,
  Modal,
  Typography,
  Button,
  Card,
} from 'antd';
import { uploadPhoto } from '../../../../../../utils/ApiEndPoints';
import ProfileSectionItem from '../ProfileSectionItem';
import { validateName } from '../../tools';
import { API_KEY } from '../../../../../../utils/constants';

const { Text, Title } = Typography;
const { Meta } = Card;

const ProfileSection = (props) => {
  const {
    profileItems, profileList, editing,
    ventureId, isEditingExtra, displayEditable,
    checkLimitsAddErase, onAddItem,
    checkLimitsAddEraseProfile, onChangeField,
    onEraseItem, state, updateState,
  } = props;

  const handlePreviewCancel = (sectionIndex) => {
    const stateCopy = { ...state };
    const { profileList } = stateCopy;
    const toUpdateItem = profileList[sectionIndex].image;
    toUpdateItem.previewVisible = false;
    profileList[sectionIndex].image = toUpdateItem;// update array
    updateState(stateCopy);
  };

  const handleSocialCancel = (sectionIndex) => {
    const stateCopy = { ...state };
    const { profileList } = stateCopy;
    const toUpdateItem = profileList[sectionIndex];
    toUpdateItem.linkedinVisible = false;
    profileList[sectionIndex] = toUpdateItem;// update array
    updateState(stateCopy);
  };

  const handlePreview = (sectionIndex, file) => {
    const stateCopy = { ...state };
    const { profileList } = stateCopy;
    const toUpdateItem = profileList[sectionIndex].image;
    toUpdateItem.previewImage = file.url || file.thumbUrl;
    toUpdateItem.previewVisible = true;
    profileList[sectionIndex].image = toUpdateItem;// update array
    updateState(stateCopy);
  };

  const showSocialModal = (sectionIndex) => {
    const stateCopy = { ...state };
    const { profileList } = stateCopy;
    const toUpdateItem = profileList[sectionIndex];
    toUpdateItem.linkedinVisible = true;
    profileList[sectionIndex] = toUpdateItem;// update array
    updateState(stateCopy);
  };

  const handleChange = (sectionIndex, { fileList }) => {
    const stateCopy = { ...state };
    const { profileList } = stateCopy;
    const toUpdateItem = profileList[sectionIndex].image;
    toUpdateItem.fileList = fileList;
    if (fileList.length > 0) {
      profileList[sectionIndex].isComplete = true;
    }
    profileList[sectionIndex].image = toUpdateItem;// update array
    updateState(stateCopy);
  };

  const { previewVisible, previewImage, fileList } = profileItems.image;
  const { linkedinVisible } = profileItems;
  const pictureContainer = {
    xs: { span: 10, offset: 7 },
    sm: { span: 10, offset: 7 },
    md: { span: 10, offset: 1 },
    lg: { span: 8, offset: 1 },
    xl: { span: 8, offset: 1 },
    xxl: { span: 8, offset: 1 },
  };
  const profileContainer = {
    xs: { span: 16, offset: 4 },
    sm: { span: 16, offset: 4 },
    md: { span: 13, offset: 0 },
    lg: { span: 13, offset: 0 },
    xl: { span: 13, offset: 0 },
    xxl: { span: 13, offset: 0 },
  };
  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">
          Upload your photo
      </div>
    </div>
  );
  const createNew = () => {
    const newInfo = {
      arrayId: 0,
      description: 'Add some description',
    };

    return newInfo;
  };

  const linkedinUrl = editing ? undefined : (`https://www.linkedin.com/in/${profileItems.linkedin}`);

  return (
    (isEditingExtra(profileItems.isComplete))
      && (
      <div>
        <Col key={profileItems.arrayId} className="mb-2" span={24}>
          <Col
            {...pictureContainer}
          >
            <div className="clearfix">
              <Row type="flex" justify="center">
                <Upload
                  action={uploadPhoto}
                  className="profile-picture"
                  accept="image/*"
                  data={{
                    venture: ventureId,
                    order: profileItems.order,
                  }}
                  headers={{
                    'X-Api-Key': API_KEY,
                  }}
                  name="image"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={(event) => handlePreview(profileItems.arrayId, event)}
                  onChange={(event) => handleChange(profileItems.arrayId, event)}
                  data-cy="test-imageProfile-upload-profileSection"
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Row>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={(event) => handlePreviewCancel(profileItems.arrayId, event)}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Col>
          <Col
            className={
              `profile-content pt-2v ${!profileItems.isComplete ? 'incomplete-field' : ''}`
            }
            {...profileContainer}
          >
            <div
              className="edit median-subtitle"
              data-cy="test-profileItems-text-profileSection"
            >
              <Text
                onClick={(e) => displayEditable(e)}
                strong
                editable={{
                  onChange: (event) => onChangeField(
                    null, profileItems.arrayId, event, 'profileList', 'name', validateName,
                  ),
                }}
              >
                {profileItems.name}
              </Text>
            </div>
            <br />
            {
              profileItems.content.map((item) => (
                <ProfileSectionItem
                  item={item}
                  sectionId={profileItems.arrayId}
                  displayEditable={displayEditable}
                  onChangeField={onChangeField}
                  checkLimitsAddErase={checkLimitsAddErase}
                  profileList={profileList}
                  onEraseItem={onEraseItem}
                />
              ))
            }
            <Row type="flex" justify="center" align="middle">
              {checkLimitsAddErase(
                profileItems.arrayId, true,
                profileList, onAddItem, 'plus-container-profile',
                null, 'profileList', createNew,
              )}
            </Row>
            <a
              target={(editing ? undefined : '_blank')}
              href={linkedinUrl}
            >
              <Icon
                className="social-colored"
                type="linkedin"
                onClick={
                  (editing ? (event) => showSocialModal(profileItems.arrayId, event) : null)
                }
                data-cy="test-linkedin-icon-profileSection"
              />
            </a>

            <Modal
              visible={linkedinVisible}
              footer={(
                <Button
                  key="submit"
                  onClick={(event) => handleSocialCancel(profileItems.arrayId, event)}
                >
                  Ok
                </Button>
              )}
              onCancel={(event) => handleSocialCancel(profileItems.arrayId, event)}
              title={(
                <Title level={4} className="pd-0 mg-0">
                  Set your linkedin url account
                </Title>
              )}
              destroyOnClose
            >
              <Card
                cover={(
                  <Icon
                    type="linkedin"
                    className="icon-container-modal"
                  />
                  )}
              >
                <Meta
                  style={{ textAlign: 'center' }}
                  title="Linkedin"
                  description={(
                    <div>
                      <Text>
                        https://www.linkedin.com/in/
                      </Text>
                      <Text
                        onClick={(e) => displayEditable(e)}
                        editable={{
                          onChange: (event) => onChangeField(
                            null, profileItems.arrayId, event, 'profileList', 'linkedin',
                          ),
                        }}
                        data-cy="test-linkedin-text-profileSection"
                      >
                        {profileItems.linkedin}
                      </Text>
                      <Row style={{ marginTop: '10px' }}>
                        <Button
                          icon="search"
                          href={(`https://www.linkedin.com/in/${profileItems.linkedin}`)}
                          target="_blank"
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          Test your link
                        </Button>
                      </Row>
                    </div>
                  )}
                />
              </Card>
            </Modal>
          </Col>
          <Col span={2}>
            {checkLimitsAddEraseProfile(profileItems.arrayId, false, 'close-container-profile')}
          </Col>
        </Col>
      </div>
      )
  );
};

export default ProfileSection;
