import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_More from 'highcharts/highcharts-more';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import MessageComponent from '../../components/Message';
import {
    Col,
    Row,
    Icon,
    Select,
    Button,
    Card
} from 'antd';
import { chartContainer, guideContainer, guideItems, searchContainer, selectContainer, buttonsContainer } from './responsive';

import riskLegend from '../../images/risk_legend.png';
import upsideLegend from '../../images/upside_legend.png';

import '../../styles/utils.scss';
import './styles.scss';


const { Option } = Select;

const allVentures = gql`
query {
    allVenturesOverview {
        notArchived {
          id
          companyName
          initialEvaluation
        }
    }
}
`;

const overview = gql`
query Query(
    $allVentures: Boolean,
    $ventures: [Int],
) {
    venturesPortfolio(
        allVentures: $allVentures, 
        ventures: $ventures
    ) {
        id
        companyName
        dealProbability
        evaluationAverage
        riskMitigation
        upsidePotential
    }
}
`;

if (typeof Highcharts === 'object') {
    HC_More(Highcharts);
}


class OverviewView extends React.Component {
    state = {
        ventures: [],
        all: false,
        selectedVentures: [],
    };

    colorChart = (chart) => {
        const width = chart.plotBox.width / 2.0;
        const height = chart.plotBox.height / 2.0 + 1;

        chart.renderer.rect(chart.plotBox.x,
            chart.plotBox.y, width, height, 1)
            .attr({
                fill: 'lightblue',
                zIndex: 0
            })
            .add();

        chart.renderer.rect(chart.plotBox.x + width,
            chart.plotBox.y, width, height, 1)
            .attr({
                fill: 'lightgreen',
                zIndex: 0
            })
            .add();

        chart.renderer.rect(chart.plotBox.x,
            chart.plotBox.y + height, width, height, 1)
            .attr({
                fill: '#FF8989',
                zIndex: 0
            })
            .add();

        chart.renderer.rect(chart.plotBox.x + width,
            chart.plotBox.y + height, width, height, 1)
            .attr({
                fill: '#F2CB68',
                zIndex: 0
            })
            .add();
    };

    onChange = (value) => {
        this.setState({selectedVentures: value});
    };

    getAll = (e) => {
        e.preventDefault();
        this.setState({all:true});
    };

    getSelected = (e) => {
        const {selectedVentures} = this.state;
        e.preventDefault();
        this.setState({ventures: selectedVentures, all: false});
    };

    clear = (e) => {
        e.preventDefault();
        this.setState({
            ventures: [],
            all: false,
            selectedVentures: [],
        });
    };

    formatData = (list) => {
        return list.map(item => {
            return [item.dealProbability, item.evaluationAverage, item.riskMitigation, item.upsidePotential,
                item.companyName, item.id];
        });
    };

    redirect = (event) => {
        this.props.history.push(`/dashboard/founder/venture/${event.point.id}/`);
    };

    render() {
        const {all, ventures, selectedVentures} = this.state;
        return (
            <Row>
                <Row>
                    <Col span={20} offset={2} className="dashboard-breadcrumb">
                    <Query query={allVentures}>
                        {({loading, error, data, refetch}) => {
                            const shouldShowLoading = loading && !data.allVenturesOverview;
                            if (shouldShowLoading) {
                                return (
                                    <MessageComponent
                                        icon="loading"
                                        iconSize={50}
                                        text="Loading Preview"
                                        level={4}
                                    />
                                );
                            }
                            if (error) return (
                                <MessageComponent
                                    icon="smile"
                                    iconSize={50}
                                    text={`${error.message}`}
                                    level={4}
                                />
                            );
                            const options = data.allVenturesOverview.notArchived.map(item => (
                                <Option
                                    key={item.id}
                                    value={item.id}
                                    label={item.companyName}
                                >
                                    {item.companyName}
                                </Option>
                            ) );
                            return (
                                <Row type="flex" align="middle" gutter={16} className="mt-1">
                                    <Col {...searchContainer} className="mb-1">
                                        <Icon type="filter" theme="filled" /> Company Name:
                                    </Col>
                                    <Col {...selectContainer} className="mb-1">
                                            <Select
                                                mode="multiple"
                                                placeholder="Find company"
                                                className="w-100"
                                                optionFilterProp="label"
                                                onChange={(value) => this.onChange(value)}
                                                value={selectedVentures}
                                            >
                                                {options}
                                            </Select>
                                    </Col>
                                    <Col {...buttonsContainer} className="mb-1">
                                        <Button
                                            type="primary"
                                            onClick={(e) => this.getSelected(e)}
                                            disabled={selectedVentures.length === 0}
                                        >
                                            Show selected
                                        </Button>
                                        <Button type="primary" className="mx-10px" onClick={(e) => this.getAll(e)}>
                                            Show all
                                        </Button>
                                        <Button onClick={(e) => this.clear(e)}>
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>
                            );
                        }}
                    </Query>
                    </Col>
                </Row>
                <Row className="dashboard-breadcrumb">
                    <Col {...chartContainer} className="mb-1">
                        {/*Chart Section*/}
                        <Query query={overview} variables={{allVentures: all, ventures}}>
                            {({loading, error, data, refetch}) => {
                                const shouldShowLoading = loading && !data.venturesPortfolio;
                                if (shouldShowLoading) {
                                    return (
                                        <MessageComponent
                                            icon="loading"
                                            iconSize={50}
                                            text="Loading Preview"
                                            level={4}
                                        />
                                    );
                                }
                                if (error) return (
                                    <MessageComponent
                                        icon="smile"
                                        iconSize={50}
                                        text={`${error.message}`}
                                        level={4}
                                    />
                                );
                                const low_risk = this.formatData(data.venturesPortfolio.filter(
                                    item => item.upsidePotential < 1.6));
                                const average_risk = this.formatData(data.venturesPortfolio.filter(
                                    item => 1.5 < item.upsidePotential && item.upsidePotential < 3.2));
                                const high_risk = this.formatData(data.venturesPortfolio.filter(
                                    item => item.upsidePotential > 3.1));
                                const options = {
                                    chart: {
                                        type: 'bubble',
                                        plotBorderWidth: 1,
                                        zoomType: 'xy',
                                        height: 420,
                                    },
                                    title: {
                                        text: 'Overview of Investment Opportunities'
                                    },

                                    xAxis: {
                                        title:{
                                            text:'Deal Probability'
                                        },
                                        min:0,
                                        max:100,
                                        tickInterval:50,
                                        tickLength:0,
                                        minorTickLength:0,
                                        gridLineWidth:1,
                                        showLastLabel:true,
                                        showFirstLabel:false,
                                        lineColor:'#ccc',
                                        lineWidth:1
                                    },

                                    yAxis: {
                                        startOnTick: false,
                                        endOnTick: false,
                                        title:{
                                            text:'Founder Evaluation Average Scores',
                                        },
                                        min:0,
                                        max:100,
                                        tickInterval:50,
                                        tickLength:3,
                                        minorTickLength:0,
                                        lineColor:'#ccc',
                                        lineWidth:1
                                    },

                                    series: [{
                                        name: 'Low Upside Potential',
                                        data: low_risk,
                                        marker: {
                                            fillColor: {
                                                radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                                                stops: [
                                                    [0, 'rgba(242, 120, 46, 1)'],
                                                ]
                                            }
                                        },
                                        events: {
                                            click: this.redirect
                                        }
                                    }, {
                                        name: 'Average Upside Potential',
                                        data: average_risk,
                                        marker: {
                                            fillColor: {
                                                radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                                                stops: [
                                                    [0, 'rgba(112, 164, 231, 1)'],
                                                ]
                                            }
                                        },
                                        events: {
                                            click: this.redirect
                                        }
                                    }, {
                                        name: 'High Upside Potential',
                                        data: high_risk,
                                        marker: {
                                            fillColor: {
                                                radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                                                stops: [
                                                    [0, 'rgba(92, 172, 74, 1)'],
                                                ]
                                            }
                                        },
                                        events: {
                                            click: this.redirect
                                        }
                                    }],
                                    credits: {
                                        enabled:false
                                    },
                                    tooltip: {
                                        useHTML: true,
                                        headerFormat: '<table>',
                                        pointFormat: '<tr><th colspan="2"><h2 style="text-align: center; font-weight: bold">{point.name}</h2></th></tr>' +
                                            '<tr><th>Deal probability:</th><td>{point.x}</td></tr>' +
                                            '<tr><th>Evaluation scores:</th><td>{point.y}</td></tr>' +
                                            '<tr><th>Risks mitigation:</th><td>{point.z}</td></tr>' +
                                            '<tr><th>Upside potential:</th><td>{point.upside}</td></tr>',
                                        footerFormat: '</table>',
                                        followPointer: true
                                    },
                                    plotOptions: {
                                        series: {
                                            keys: ['x', 'y', 'z', 'upside', 'name', 'id'],
                                        }
                                    },
                                };
                                return (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                        callback={this.colorChart}
                                    />
                                );
                            }}
                        </Query>
                    </Col>
                    <Col {...guideContainer}>
                        <Row gutter={16}>
                            <Col {...guideItems}>
                                <Card className="mb-1 text-center">
                                    <img src={riskLegend} className="guide-image" alt="risk" />
                                    Risk Mitigation
                                </Card>
                            </Col>
                            <Col {...guideItems}>
                                <Card className="text-center last-guide">
                                    <img src={upsideLegend} className="guide-image" alt="upside" />
                                    Upside Potential
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        );
    }
}

export default OverviewView;