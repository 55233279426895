import React, {Component} from 'react';
import {
  Menu,
  Icon,
  Row,
  Button,
  Divider
} from 'antd';

import { Link } from 'react-router-dom';
import {
  ADMIN,
  INTERNAL_MANAGER,
  INVESTMENT_COMMITTEE,
} from '../../../utils/constants';
import { PATHS } from "../../../utils/paths";

const { SubMenu } = Menu;

class SiderContent extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.state = {
      current: '',
    };
  }

  componentDidMount() {
    let url = window.location.pathname;

    if (url.includes("/dashboard/venture/") || url.includes(PATHS.dashboardInvestorVentures)) {
      this.setState({
        current: 'venture',
      });
    }
    if (url.includes("/dashboard/overview/")) {
      this.setState({
        current: 'overview',
      });
    }
    if (url.includes("/dashboard/founder/") || url.includes(PATHS.investorAddFounder)) {
      this.setState({
        current: 'team:1',
      });
    }
    if (url.includes("/dashboard/member/manager/")) {
      this.setState({
        current: 'team:2',
      });
    }
    if (url.includes("/dashboard/member/investment/") || url.includes(PATHS.investorAddInvestor)) {
      this.setState({
        current: 'team:3',
      });
    }
  }

  handleClick(e) {
    this.setState({
      current: e.key,
    });
  };

  render() {
    const {customConfig} = this.props;
    const {logoHeader} = customConfig;
    let menu = {};
    menu[ADMIN] = {
      ventureLink: PATHS.dashboardAdmin,
      menuItems: (
      [
        <Menu.Item key="overview">
          <Link to="/dashboard/overview/">
            <Icon type="dot-chart"/> Overview
          </Link>
        </Menu.Item>,
        <SubMenu
          title={<span className="submenu-title-wrapper" key="member">
            <Icon type="team"/>Members</span>}
          test-attr="test-submenu-member"
        >
          <Menu.Item key="team:1">
            <Link
              to="/dashboard/founder/list/"
              test-attr="test-founder-link"
            >
              Founders
            </Link>
          </Menu.Item>
          <Menu.Item key="team:2">
            <Link to="/dashboard/member/manager/list">
              Internal Managers
            </Link>
          </Menu.Item>
          <Menu.Item key="team:3">
            <Link to="/dashboard/member/investment/list/">
              Investment Committee
            </Link>
          </Menu.Item>
          <Menu.Item key="team:4">
            <Link to="/dashboard/member/suggested/list">
              Suggested
            </Link>
          </Menu.Item>
        </SubMenu>,
        <Menu.Item>
          <Divider/>
        </Menu.Item>,
        <Menu.Item>
          <div>
            <Button
              href="/admin/"
              className="w-100"
              type="primary"
            >
              To Admin
            </Button>
          </div>
        </Menu.Item>
      ]
    )
  };
    menu[INVESTMENT_COMMITTEE] = {
      ventureLink: PATHS.dashboardInvestorVentures,
      menuItems: (
        [
          <SubMenu
            title={<span className="submenu-title-wrapper" key="suggest">
            <Icon type="team"/>Suggest</span>}
            test-attr="test-submenu-member"
          >
            <Menu.Item key="team:1">
              <Link
                to={PATHS.investorAddFounder}
                test-attr="test-founder-link"
              >
                Founders
              </Link>
            </Menu.Item>
            <Menu.Item key="team:3">
              <Link to={PATHS.investorAddInvestor}>
                Investment Committee
              </Link>
            </Menu.Item>
          </SubMenu>,
        ]
      )
    };
    menu[INTERNAL_MANAGER] = menu[INVESTMENT_COMMITTEE];

    return (
      <div className="sider-content">
        <Row type="flex" justify="center" align="middle" className="my-2">
          <img
            className="logo"
            src={logoHeader}
            alt="Logo"
          />
        </Row>
        <Divider/>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="inline"
        >
          <Menu.Item
            key="venture"
          >
            <Link
              to={menu[localStorage.getItem('type')].ventureLink}
              test-attr="test-ventures-link"
            >
              <Icon type="rise"/> Ventures
            </Link>
          </Menu.Item>
          {menu[localStorage.getItem('type')].menuItems}
        </Menu>
      </div>
    );
  }
}

export default SiderContent;
