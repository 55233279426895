import React from 'react';
import {
    Card,
    Col,
    Icon,
    Row
} from "antd";

import '../../styles/utils.scss';
import './styles.scss';

class VentureStatus extends React.Component {
    /*
    * Venture Status
    *
    * Component to show status of venture for a founder
    * @param: {status}: Object with status for show data
    *
    *  */

    getColor = (statusValue) => {
        switch (statusValue) {
            case 'Y':
                return "green";
            case 'N':
                return "red";
            default:
                return "default";
        }
    };


    render() {
        const {status} = this.props;
        const adminColor = this.getColor(status.admin);
        const managerColor = this.getColor(status.manager);
        const investorColor = this.getColor(status.investor);
        const detailedColor = this.getColor(status.detailed);
        return (
            <Row gutter={16} type="flex" justify="center" align="middle">
                <Col span={5}>
                    <Card title={null} bordered={false} className={`status-content ${adminColor}`}>
                        Admin Evaluation
                    </Card>
                </Col>
                <Col span={1}>
                    <Icon type="right" />
                </Col>
                <Col span={5}>
                    <Card title={null} bordered={false} className={`status-content ${managerColor}`}>
                        Internal Managers Evaluation
                    </Card>
                </Col>
                <Col span={1}>
                    <Icon type="right" />
                </Col>
                <Col span={5}>
                    <Card title={null} bordered={false} className={`status-content ${investorColor}`}>
                        Investment Committee Evaluation
                    </Card>
                </Col>
                <Col span={1}>
                    <Icon type="right" />
                </Col>
                <Col span={5}>
                    <Card title={null} bordered={false} className={`status-content ${detailedColor}`}>
                        Detailed Evaluation
                    </Card>
                </Col>
            </Row>
        );
    };
}

export default VentureStatus