import React, {Component} from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';

import FounderView from './founder/FounderView';
import MemberView from './member/MemberView';
import VentureView from './venture/VentureView';
import OverviewView from "../overview";
import MessageComponent from "../../components/Message";
import TopMenu from '../../components/TopMenu';

import gql from 'graphql-tag';
import { graphql } from "react-apollo";

import '../../styles/utils.scss';
import './dashboard.scss';

const query = gql`
{
  currentUser{
    id
    username
  }
}
`;


class DashboardView extends Component {

  componentWillUpdate(nextProps) {
    nextProps.data.refetch();
    const {location} = this.props;
    if (!nextProps.data.loading && nextProps.data.currentUser === null) {
      if (location.pathname !== '/login/') {
        window.location.replace('/login/?next=' + location.pathname);
      }
    }
  }

  render() {
    let {data, location} = this.props;
    const shouldShowLoading = data.loading && !data.currentUser;
    if (shouldShowLoading) {
      if (location.pathname === '/login/') {
        return null;
      }
      return (
        <MessageComponent
          icon="loading"
          iconSize={50}
          text=""
          level={4}
        />
      );
    }
    return (
      <div>
        <TopMenu {...this.props} />
        <div style={{margin: '0 16px'}}>
          <Switch>
            <Route path="/dashboard/venture/" component={VentureView}/>
            <Route path="/dashboard/founder/" component={FounderView}/>
            <Route path="/dashboard/member/" component={MemberView}/>
            <Route path="/dashboard/overview/" component={OverviewView}/>
          </Switch>
        </div>
      </div>
    );
  }
}

DashboardView = graphql(query)(DashboardView);
export default DashboardView;
