import React, { Component } from 'react';
import {
  Row,
  BackTop,
  Col,
  Typography,
  Icon,
  Popconfirm,
} from 'antd';
import ProgressActionBar from '../ProgressActionBar';
import ChecklistSection from '../ChecklistSection';
import UploadPitchDeck from '../UploadPitchDeck';
import Section from '../Section';
import RightSection from '../RightSection';
import CircleSection from '../CircleSection';
import {
  contentComplete,
  isUndefined,
} from '../../../../../../utils/tools';
import ProfileSection from '../ProfileSection';

import userIcon from '../../../../../../images/user.svg';
import PdfButton from '../PdfButton';

const { Title } = Typography;

const updateIds = (content) => {
  const copyContent = content;
  if (copyContent) {
    for (let i = 0; i < copyContent.length; i += 1) {
      copyContent[i].arrayId = i;
    }
  }
};

class OnePagerBody extends Component {
  constructor(props) {
    super(props);

    const { pitchVenture, preview } = this.props;
    this.state = {
      existPitchVenture: pitchVenture !== null,
      editing: !preview,
    };
  }

  onAddItem = (sectionIndex, formName, getNewItem) => {
    const { updateState, state } = this.props;
    const stateCopy = { ...state };
    const form = stateCopy[formName];
    let toUpdateItem = form[sectionIndex].content;
    const dummyInfo = getNewItem(sectionIndex);
    toUpdateItem = [...toUpdateItem, dummyInfo];
    updateIds(toUpdateItem);
    form[sectionIndex].content = toUpdateItem;// update array
    updateState(stateCopy);
  }

  onEraseItem = (item, sectionIndex, formName) => {
    const { updateState, state } = this.props;
    const stateCopy = { ...state };
    const form = stateCopy[formName];
    let toUpdateItem = form[sectionIndex].content;
    toUpdateItem = [...toUpdateItem];
    toUpdateItem.splice(item.arrayId, 1);
    updateIds(toUpdateItem);
    form[sectionIndex].content = toUpdateItem;// update array
    updateState(stateCopy);
  }

  onChangeField = (
    index, formIndex, value, formName, valueName, validator, completeProp = 'isComplete',
  ) => {
    if (validator && !validator(value)) return;
    const { updateState, state } = this.props;
    const stateCopy = { ...state };
    const form = stateCopy[formName];
    let toUpdateItem = form[formIndex];
    if (index !== null) {
      toUpdateItem = toUpdateItem.content[index];
    }
    toUpdateItem[valueName] = value;
    if (isUndefined(toUpdateItem[completeProp])) {
      form[formIndex][completeProp] = true;
    } else {
      toUpdateItem[completeProp] = true;
      form[formIndex].content[index] = toUpdateItem;
    }
    updateState(stateCopy);
  }

  changeEdit = (bool) => {
    this.setState({
      editing: bool,
    });
  }

  displayEditable = (e) => {
    e.preventDefault();
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    let editButton = e.target.querySelector('i');
    if (editButton !== null) {
      editButton.click();
    } else {
      editButton = e.target.parentNode.querySelector('i');
      if (editButton !== null) editButton.click();
    }
  };

  changeExistingPitch = (list) => {
    this.setState({
      existPitchVenture: list.length > 0,
    });
  }

  renderNoData = (dataList) => {
    for (let item = 0; item < dataList.length; item += 1) {
      if (contentComplete(dataList[item].content)) return true;
    }

    return false;
  };

  isEditingExtra = (extra) => {
    const { editing } = this.state;

    return (!editing && (extra)) || (editing);
  };

  checkLimitsAddErase = (
    id, bool, section, sectionFunction, classStyle, item, formName, extraFunc,
  ) => {
    const { editing } = this.state;
    if (bool) {
      if (section[id].maxItems > section[id].content.length) { // allow
        return (
          <Icon
            type="plus"
            className={(editing ? classStyle : 'hidden-button')}
            onClick={(event) => sectionFunction(id, formName, extraFunc, event)}
            data-cy="test-add-item-title-icon-onePagerBody"
          />
        );
      }

      return null;
    }
    if (section[id].minItems < section[id].content.length) {
      return (
        <Popconfirm
          title="Are you sure delete this item?"
          onConfirm={(event) => sectionFunction(item, id, formName, event)}
          okText="Yes"
          cancelText="No"
        >
          <Icon
            type="close"
            className={(editing ? classStyle : 'hidden-button')}
          />
        </Popconfirm>
      );
    }

    return null;
  };

  onAddProfileSection = (event, profileSectionIndex) => {
    const { profileList, updateState, sectionsProfile } = this.props;
    const profile = sectionsProfile[profileSectionIndex];
    const dummyData = {
      id: profile.id,
      sectionProfileIndex: profileSectionIndex,
      order: profileList.length,
      minItems: profile.minItems,
      maxItems: profile.maxItems,
      name: 'Name',
      linkedin: 'No data',
      linkedinVisible: false,
      isComplete: false,
      image: {
        previewVisible: false,
        previewImage: '',
        fileList: [{
          uid: '-1',
          name: 'user.png',
          status: 'done',
          url: userIcon,
        }],
      },
      content: [
        {
          arrayId: 0,
          description: 'No data',
        },
      ],
    };
    profileList.push(dummyData);
    updateIds(profileList);
    updateState({ profileList });
  };

  onEraseProfileSection = (arrayId) => {
    const { profileList, updateState } = this.props;
    profileList.splice(arrayId, 1);
    updateIds(profileList);
    updateState({ profileList });
  };

  checkLimitsAddEraseProfile = (id, bool, classStyle, profileSectionIndex) => {
    const maxProfiles = 5;
    const minProfiles = 1;
    const { profileList } = this.props;
    const { editing } = this.state;
    if (bool) { // adding
      if (maxProfiles > profileList.length) { // allow
        return (
          <Icon
            type="plus"
            className={(editing ? classStyle : 'hidden-button')}
            onClick={(event) => this.onAddProfileSection(event, profileSectionIndex)}
          />
        );
      }

      return null;
    }
    if (minProfiles < profileList.length) { // allow
      return (
        <Popconfirm
          title="Are you sure delete this item?"
          onConfirm={(event) => this.onEraseProfileSection(id, event)}
          okText="Yes"
          cancelText="No"
        >
          <Icon
            type="close"
            className={(editing ? classStyle : 'hidden-button')}
          />
        </Popconfirm>
      );
    }

    return null;
  };

  renderProfileSectionList = (index) => {
    const {
      profileList: profileListOrg,
      ventureId, state, updateState,
    } = this.props;
    const { editing } = this.state;
    const profileList = profileListOrg.filter((profile) => profile.sectionProfileIndex === index);

    return (
      <Col span={24} className="profile-container pb-2">
        {
            profileList.map((item) => (
              <ProfileSection
                profileItems={item}
                profileList={profileList}
                editing={editing}
                ventureId={ventureId}
                isEditingExtra={this.isEditingExtra}
                displayEditable={this.displayEditable}
                checkLimitsAddErase={this.checkLimitsAddErase}
                onAddItem={this.onAddItem}
                checkLimitsAddEraseProfile={this.checkLimitsAddEraseProfile}
                onChangeField={this.onChangeField}
                onEraseItem={this.onEraseItem}
                state={state}
                updateState={updateState}
              />
            ))
        }
        {this.checkLimitsAddEraseProfile(
          profileList.arrayId, true, 'plus-container-profile', index,
        )}
      </Col>
    );
  };

  render() {
    const {
      preview, sectionList,
      rightList, circleList, checkList,
      profileList, ventureId, companyName,
      companyNameComplete,
      pitchVenture, setComplete,
      onChangeCompanyName, sections,
      sectionsProfile,
      state,
    } = this.props;
    const { existPitchVenture, editing } = this.state;
    const {
      displayEditable, changeEdit,
      onChangeField, onEraseItem, onAddItem,
      changeExistingPitch,
    } = this;
    const defaultCompanyName = 'Company Name';

    let mainContent = {
      xs: { span: 24 },
      md: { span: 20 },
      xl: { span: 18 },
      xxl: { span: 13 },
    };

    if (preview) {
      mainContent = {
        xs: { span: 24 },
        xl: { span: 20 },
      };
    }
    const sectionColumn = {
      xs: { span: 24 },
      md: { span: 16 },
    };
    const rightSection = {
      xs: { span: 24 },
      md: { span: 8 },
    };

    return (
      <Row className={`${(editing ? 'mr-2' : 'hidden-edit-icon mr-2')} mt-3`}>
        {
          !preview
          && [
            <BackTop />,
            <ProgressActionBar
              preview={preview}
              sectionList={sectionList}
              rightList={rightList}
              circleList={circleList}
              checkList={checkList}
              profileList={profileList}
              ventureId={ventureId}
              companyName={companyName}
              companyNameComplete={companyNameComplete}
              setComplete={setComplete}
              existPitchVenture={existPitchVenture}
              editing={editing}
              changeEdit={changeEdit}
            />,
          ]
        }
        <Row
          className="custom-content"
          type="flex"
          justify="center"
          align="middle"
        >
          <Col
            className="mt-2"
            {...mainContent}
          >
            <Row
              type="flex"
              justify="end"
              align="middle"
            >
              <Col
                className={`company-name ${!companyNameComplete ? 'incomplete-field' : ''}`}
                xs={24}
                sm={24}
                md={10}
                lg={10}
                style={{ zIndex: '5' }}
                xl={10}
                xxl={10}
                data-cy="test-company-name-title-onePagerBody"
              >
                <Title
                  className="pd-0 mg-0 section-title"
                  onClick={(e) => displayEditable(e)}
                  editable={{ onChange: onChangeCompanyName }}
                >
                  {(companyName !== '') ? companyName : defaultCompanyName}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col
                className="column-container"
                {...sectionColumn}
              >
                {
                  sectionList.map((item) => (
                    <Section
                      itemSection={item}
                      checkLimitsAddErase={this.checkLimitsAddErase}
                      sectionList={sectionList}
                      onAddItem={onAddItem}
                      isEditingExtra={this.isEditingExtra}
                      editing={editing}
                      displayEditable={displayEditable}
                      onChangeField={onChangeField}
                      onEraseItem={onEraseItem}
                      sections={sections}
                      preview={preview}
                    />
                  ))
                }
                {
                  (!editing && !this.renderNoData(sectionList))
                  && (
                  <Title
                    className="section-title text-center"
                    data-cy="section-no-data-title-onePagerBody"
                  >
                    No Data
                  </Title>
                  )
                }
              </Col>
              <Col
                {...rightSection}
              >
                {
                  rightList.map((item) => (
                    <RightSection
                      itemRightList={item}
                      isEditingExtra={this.isEditingExtra}
                      checkLimitsAddErase={this.checkLimitsAddErase}
                      rightList={rightList}
                      onAddItem={onAddItem}
                      displayEditable={displayEditable}
                      onChangeField={onChangeField}
                      onEraseItem={onEraseItem}
                    />
                  ))
                }
              </Col>
            </Row>
            <Row>
              {
                circleList.map((item) => (
                  <CircleSection
                    itemCircleList={item}
                    circleList={circleList}
                    isEditingExtra={this.isEditingExtra}
                    displayEditable={displayEditable}
                    onChangeField={onChangeField}
                    checkLimitsAddErase={this.checkLimitsAddErase}
                    onAddItem={onAddItem}
                    onEraseItem={onEraseItem}
                  />
                ))
              }
            </Row>
            <Row type="flex" justify="center">
              {
                checkList.map((item) => (
                  <ChecklistSection
                    itemCheckList={item}
                    checkList={checkList}
                    isEditingExtra={this.isEditingExtra}
                    checkLimitsAddErase={this.checkLimitsAddErase}
                    onAddItem={onAddItem}
                    displayEditable={displayEditable}
                    onChangeField={onChangeField}
                    onEraseItem={onEraseItem}
                  />
                ))
              }
            </Row>
            {
              (this.isEditingExtra(contentComplete(profileList)))
              && (
              <Row className="profile-container">
                <Title
                  className="section-title title-team"
                  data-cy="test-title-team-title-onePagerBody"
                >
                    Team
                </Title>
                {sectionsProfile.map((item, index) => this.renderProfileSectionList(index))}
              </Row>
              )
            }
            <Row type="flex" justify="center" align="middle" className="upload-pitch mt-2 mb-2">
              <UploadPitchDeck
                editing={editing}
                ventureId={ventureId}
                pitchVenture={pitchVenture}
                changeExistingPitch={changeExistingPitch}
              />
            </Row>
            <Row type="flex" justify="center" align="middle" className="upload-pitch mt-2 mb-2">
              <Col span={15}>
                <Title className="text-center mt-2 section-title title-team">
                  One-Pager as PDF
                </Title>
              </Col>
              <Col span={15} className="text-center mb-2">
                <PdfButton values={state} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default OnePagerBody;
