import React from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Typography,
} from 'antd';
import {
  Link,
} from "react-router-dom";
import InvestorTable from './InvestorTable';
import FounderTable from './FounderTable';
import { mainContainerBody } from '../../../styles/responsive';

const { Title } = Typography;

const SuggestedView = () => (
  <Row type="flex" justify="space-around" align="middle" className="mb-2">
    <Col {...mainContainerBody}>
      <Breadcrumb className="dashboard-breadcrumb">
        <Breadcrumb.Item>
          <Link to="/dashboard/venture/list/">
            Ventures
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Title className="dashboard-title" level={1}>
        Suggested Members
      </Title>
      <FounderTable />
      <InvestorTable />
    </Col>
  </Row>
);

export default SuggestedView;
