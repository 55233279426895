import React from 'react';
import gql from 'graphql-tag';
import {
  graphql,
  Query
} from 'react-apollo';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Typography,
  Breadcrumb,
  Select,
  notification,
  Checkbox
} from 'antd';

import {
  Link,
  withRouter
} from 'react-router-dom';
import MessageComponent from '../../../components/Message';
import { ADMIN } from '../../../utils/constants';

const {Title} = Typography;
const {Option} = Select;
const {TextArea} = Input;

const mutation = gql`
mutation Mutation(
  $emailAddress: String!,
  $firstName: String!,
  $lastName: String!,
  $type: String!
  $answers: [MemberAnswersInput!],
  $isGuest: Boolean,
  $idVenture: Int,
  $memberToken: String,
  $tags: [Int],
  $enableLogin : Boolean,
  $suggestedBy: Int,
) {
  createMember(
    emailAddress: $emailAddress,
    firstName: $firstName,
    lastName: $lastName,
    type: $type,
    answers: $answers,
    isGuest: $isGuest,
    idVenture: $idVenture,
    memberToken: $memberToken,
    tags: $tags,
    enableLogin: $enableLogin,
    suggestedBy: $suggestedBy
  ) {
    status
    member {
      id
      firstName
      lastName
      emailAddress
      type
    }
  }
}
`;

const query = gql`
query averageQuery(
  $show_to_manager: Boolean!,
  $show_to_investment: Boolean!,
) {
  allQuestionsMember(
    showToManager: $show_to_manager,
    showToInvestment: $show_to_investment,
  ){
    id
    name
    options{
      id
      name
      hasExtraText
    }
  }
  tags(showInvestor: $show_to_investment) {
    id
    name
  }
}
`;

function hasNumber(myString) {
  return /\d/.test(myString);
}

const prepareData = (values) => {
  let key;
  const allQuestionsTmp = [];
  for (key in values) {
    if (hasNumber(key)) {
      if (key.includes("-")) {
        if (values[key] && values[key].length > 0) {
          values[key] = [values[key]];
        }
      }
      if (values[key] && values[key].length > 0) {
        const questionTmp = {
          idQuestion: key,
          idCriterias: values[key]
        };
        allQuestionsTmp.push(questionTmp);
      }
    }
  }

  return allQuestionsTmp;
};


class AddInvestmentForm extends React.Component {
  state = {
    saveLoading: false,
    tags: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saveLoading: true});
        //TODO: check if investorId is correctly passed to this props (error was telling investorId was not declared on
        // yarn build)
        let {isGuest=false, idVenture=null, investorId=null, afterSave, memberToken=null} = this.props;
        const allQuestionsTmp = prepareData(values);

        this.props
          .mutate({
            variables: {
              firstName: values['First Name'],
              lastName: values['Last Name'],
              emailAddress: values['Email Address'],
              type: 'IC',
              answers: allQuestionsTmp,
              tags: values.tags,
              idVenture,
              isGuest,
              memberToken,
              enableLogin: values.enable_login,
              suggestedBy: investorId
            }
          })
          .then(res => {
            this.setState({saveLoading: false});
            if (res.data.createMember.status === 200) {
              if(typeof afterSave !== "undefined"){
                afterSave(res.data.createMember.member.id, values.message);
              }
              else {
                this.props.history.push({
                  pathname: '/dashboard/member/investment/list',
                  state: {reload: true}
                });
              }
              notification['success']({
                message: 'Investment member created!',
                duration: 3.5,
              });
            }
            if (res.data.errors) {
              notification['error']({
                message: 'An error have occurred',
                duration: 3.5,
              });
              console.log(res.data.errors);
            }
          })
          .catch(err => {
            this.setState({saveLoading: false});
            if (err.graphQLErrors) {
              for (let i = 0; i < err.graphQLErrors.length; i++) {
                notification['error']({
                  message: 'Network Error',
                  description: err.graphQLErrors[i].message,
                  duration: 3.5,
                });
              }
            }
            console.log('Network error');
          });
      }
    });
  };

  receiveQuery = (all_choices) => {
    const result = [];
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {span: 4},
      wrapperCol: {span: 20},
    };
    for (let i = 0; i < all_choices.length; i++) {
      let options = [];
      let extra_inputs = [];
      all_choices[i].options.forEach(function (option, optionIndex) {
        if (option.hasExtraText) {
          extra_inputs.push(
            <Row type="flex" justify="end" align="middle" key={option.id}>
              <Col span={24}>
                <Form.Item
                  label={option.name}
                  {...formItemLayout}
                >
                  {getFieldDecorator(all_choices[i].id + '-' + option.id, {})(
                    <Input
                      placeholder={option.name}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
          );
        } else {
          options.push(
            <Option key={option.id} value={option.id}>{option.name}</Option>
          );
        }
      });
      if (options.length > 0) {
        result.push(
          <Form.Item
            key={all_choices[i].id}
            label={all_choices[i].name}
          >
            {getFieldDecorator(all_choices[i].id, {})(
              <Select
                mode="multiple"
                placeholder={`Select a ${all_choices[i].name}`}
              >
                {options}
              </Select>
            )}
          </Form.Item>
        );
      }
      if (extra_inputs.length > 0) {
        extra_inputs.forEach(function (extra, optionIndex) {
          result.push(extra);
        });
      }
    }

    return result;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { saveLoading } = this.state;
    let show_to_manager = false;
    let show_to_investment = true;
    let { isGuest=false, type } = this.props;

    return (
      <div>
        <Title level={1}>{isGuest ? "Invite" : "New"} Investor</Title>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item
            label="First Name"
          >
            {getFieldDecorator('First Name', {
              rules: [{
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="Manager first name"/>
            )}
          </Form.Item>
          <Form.Item
            label="Last Name"
          >
            {getFieldDecorator('Last Name', {
              rules: [{
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="Manager last name"/>
            )}
          </Form.Item>
          <Form.Item
            label="Email Address"
          >
            {getFieldDecorator('Email Address', {
              rules: [{
                type: 'email',
              }, {
                required: true,
              }],
            })(
              <Input
                prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="example@mail.com"/>
            )}
          </Form.Item>
          < Query
            query={query}
            variables={
              {
                show_to_manager,
                show_to_investment,
              }
            }
          >
            {
              ({loading, error, data, refetch}) => {
                if (loading) {
                  return (
                    <MessageComponent
                      icon="loading"
                      iconSize={50}
                      text="Loading Form"
                      level={4}/>
                  );
                }
                if (error) return (
                  <MessageComponent
                    icon="smile"
                    iconSize={50}
                    text={`${error.message}`}
                    level={4}/>
                );
                let all_choices = data.allQuestionsMember;
                const result = all_choices ? this.receiveQuery(all_choices) : [];
                if (this.state.tags !== data.tags) {
                  this.setState({tags: data.tags});
                }

                return result;
              }
            }
          </Query>
          <Form.Item label="Tags">
            {getFieldDecorator('tags')(
                <Select mode="multiple" placeholder="Select a tag">
                  {this.state.tags.map(item => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
            )}
          </Form.Item>
          {isGuest && (
              <Form.Item label="Personal message">
                {getFieldDecorator('message')(
                    <TextArea defaultValue="" />
                )}
              </Form.Item>
          )}
          {
            ((type === ADMIN) &&
            <Form.Item>
              {getFieldDecorator('enable_login',{
                valuePropName: 'checked',
                initialValue: false,
                })(<Checkbox>Enable login</Checkbox>)
              }
            </Form.Item>)
          }
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={saveLoading}>
              {isGuest ? "Invite": "Create"}
            </Button>
          </Form.Item>
        </Form>
      </div>

    );
  }
}

AddInvestmentForm = withRouter(AddInvestmentForm);

AddInvestmentForm = graphql(mutation)(AddInvestmentForm);
export const WrappedAddInvestmentForm = Form.create({name: 'investment_add'})(AddInvestmentForm);


export default class InvestmentAddView extends React.Component {
  render() {
    const { type=ADMIN, investorId } = this.props;

    return (
      <div>
        {
          (
            (type === ADMIN) &&
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                <Breadcrumb className="dashboard-breadcrumb">
                  <Breadcrumb.Item>
                    <Link to="/dashboard/member/manager/list/">
                      Investor
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    Add
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          )
        }
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10} className="dashboard-table">
            <WrappedAddInvestmentForm type={type} investorId={investorId} />
          </Col>
        </Row>
      </div>
    );
  }
}
