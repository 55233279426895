import React from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Typography,
  Layout
} from "antd";
import {
  withRouter
} from 'react-router-dom';
import { graphql } from 'react-apollo';
import {
  loginProcess,
  manageGraphqlErrors
} from '../../utils/tools';
import { MUTATIONS } from '../../utils/mutations';

import {
  customBackgroundColor,
  customFontType
} from '../../styles/customStyles';


const {Title} = Typography;
const {Content} = Layout;

const mutationName = 'setPassword';

class SetUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      saveLoading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, match, mutate } = this.props;
    const { params } = match;
    const { uid, token } = params;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ saveLoading: true });
        mutate({
          variables: {
            ...values,
            uid,
            token,
          }
        }).then((res) => {
          this.setState({saveLoading: false});
          const { data } = res;
          const { status } = data[mutationName];
          if (status === 200) {
            const { auth } = data[mutationName];
            const { access, refresh, type } = auth;
            loginProcess(access, refresh, type);
          }
          if (res.data.errors) {
            notification['error']({
              message: 'An error have occurred',
              duration: 3.5,
            });
            console.log(res.data.errors);
          }

        }).catch((error) => {
          this.setState({saveLoading: false});
          const { graphQLErrors } = error;
          if (graphQLErrors) {
            manageGraphqlErrors(graphQLErrors)
          }
          console.log('Network error');
        });
      }
    });
  };

  render() {
    const { saveLoading } = this.state;
    const { form, customConfig } = this.props;
    const { getFieldDecorator } = form;
    const {
      logoHeader,
      secondaryColor,
      fontType
    } = customConfig;
    return (
      <Layout style={customFontType(fontType)}>
        <Content className="login-custom-content" style={customBackgroundColor(secondaryColor)}>
          <Row className="login-custom-content" type="flex" justify="center"
               align="middle">
            <Col xs={20} sm={8} md={8} lg={8} xl={6} xxl={4}>
              <Col span={24} style={{textAlign: 'center'}}>
                <img className="logo-login" src={logoHeader} alt="Logo" />
              </Col>
                  <Title level={2} className="text-center">Set your password</Title>
                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item label="Password">
                      {getFieldDecorator('password1', {
                        rules: [{required: true}],
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
                          type="password"
                          placeholder="Password"
                          test-attr="test-password"
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Repeat your password" >
                      {getFieldDecorator('password2', {
                        rules: [{required: true}],
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
                          type="password"
                          placeholder="Repeat your password"
                          test-attr="test-password"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" className="login-form-button" loading={saveLoading}>
                        Set my password
                      </Button>
                    </Form.Item>
                  </Form>
            </Col>
          </Row>
        </Content>
      </Layout>

    );
  }
}

SetUserPassword = withRouter(SetUserPassword);

SetUserPassword = graphql(MUTATIONS[mutationName])(SetUserPassword);

const SetUserPasswordForm = Form.create({name: 'set_password'})(SetUserPassword);
export default SetUserPasswordForm;
