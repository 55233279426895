import React from 'react';
import { Query } from 'react-apollo';
import { founderColumns } from './tableColumns';
import {
  Table,
  Typography,
  Row,
} from 'antd';
import { foundersQuery } from './query';

const { Title } = Typography;

const FounderTable = () => (
  <Query query={foundersQuery}>
    {
      ({ data, refetch }) => {
        const { founderSuggestedNotActive } = data;

        return (
          <Row>
            <Title className="dashboard-title" level={2}>
              Founder
            </Title>
            <Table
              columns={founderColumns(refetch)}
              dataSource={founderSuggestedNotActive}
              pagination={{pageSize: 50}}
              bordered
              rowKey={(record) => {
                return record.id;
              }}
            />
          </Row>
        );
      }
    }
  </Query>
);

export default FounderTable;
