import React from 'react';
import {
  Col,
  Row,
  Tabs,
  Form,
  Button,
  Select,
  Tooltip,
  Icon,
  notification,
  Typography
} from 'antd';
import gql from 'graphql-tag';

import {withRouter} from 'react-router-dom';
import {
  graphql,
  Query
} from 'react-apollo';
import MessageComponent from '../Message';

import './styles.scss';
import Classify from '../Classify';

const TabPane = Tabs.TabPane;
const {Option} = Select;
const {Title} = Typography;

const mutation = gql`
mutation Mutation(
  $idOpinion: Int!,
  $idDiligence: Int!,
  $tokenVenture: String!,
  $allQuestions: [AnswersInput!],
  $memberToken: String!,
)
  {
  createAnswerAdmin(
    idOpinion: $idOpinion,
    tokenVenture: $tokenVenture,
    allQuestions: $allQuestions,
    idDiligence: $idDiligence,
    memberToken: $memberToken
  )
  {
    status
    opinion{
      id
      member{
        id
        emailAddress
      }
      venture {
        id
        companyName
      }
      answerSet{
        id
        question{
          id
          name
        }
        answers{
          name
        }
      }
    }
  } 
}
`;

const query = gql`
query Query(
  $venture_id: Int!,
  $diligence_id: Int!,
  $member_token: String!
) {
  diligenceAnswersAdmin(
    ventureId: $venture_id,
    diligenceId: $diligence_id,
    memberToken: $member_token
  ){
    question{
      id
      name
    }
    answers{
      id
      name
    }
  }
}
`;

class DueDiligenceForm extends React.Component {
  state = {
    saveLoading: false,
    lazyEvaluation: this.props.investment,
  };

  bodyConstructor = (questions) => {
    const res = [];
    const {data, venture, member} = this.props;
    const {getFieldDecorator} = this.props.form;

    const  venture_id = venture.id;
    const  diligence_id = data.id;
    const  member_token = member;

    questions.forEach(function (question, index) {
      const text = <span>{question.description}</span>;
      let options = [];

      question.options.forEach(function (option, optionIndex) {
        options.push(
          <Option key={option.id} value={option.id}>{option.name}</Option>
        );
      });

      res.push(
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Query
            query={query}
            fetchPolicy="network-only"
            variables={{
              venture_id,
              diligence_id,
              member_token
            }}
          >
            {
              ({loading, error, data, refetch}) => {
                if (loading) {
                  return (
                    <MessageComponent
                      icon="loading"
                      iconSize={50}
                      text="Loading Form"
                      level={4}
                    />
                  );
                }
                if (error) return (
                  <MessageComponent
                    icon="smile"
                    iconSize={50}
                    text={`${error.message}`}
                    level={4}
                  />
                );
                const all_choices = data.diligenceAnswersAdmin;
                const choices = [];
                for (let i = 0; all_choices && i < all_choices.length; i++) {
                  if (all_choices[i].question.id === question.id) {
                    for (let j = 0; j < all_choices[i].answers.length; j++) {
                      choices.push(all_choices[i].answers[j].id);
                    }
                  }
                }
                return (
                  <Form.Item
                    label={
                      <span>
                        <Tooltip placement="top" title={text}>
                          <Button className="help-button" type="dashed" shape="circle">?</Button>
                        </Tooltip>
                        {question.name}
                      </span>
                    }
                    hasFeedback
                  >
                    {getFieldDecorator(question.id, {
                      initialValue: choices.length > 0 ? choices : undefined,
                      rules: [{
                        required: true,
                        message: "Please select a response to this mandatory field"
                      }],
                    })(
                        <Select
                            placeholder={`Select a ${question.name}`}
                        >
                          {options}
                        </Select>
                    )}
                  </Form.Item>
                );
              }
            }
          </Query>
        </Col>
      );
    });

    return res;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {last, data, venture, member, current, functionUpdateCurrent, changeTab, newTab} = this.props;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saveLoading: true});
        let allQuestionsTmp = [];
        let key;
        for (key in values) {
          let questionTmp = {
            idQuestion: key,
            idCriterias: values[key]
          };
          allQuestionsTmp.push(questionTmp);
        }

        this.props
          .mutate({
            variables: {
              idOpinion: 0,
              idDiligence: data.id,
              tokenVenture: venture.token,
              allQuestions: allQuestionsTmp,
              memberToken: member
            }
          })
          .then(res => {
            this.setState({saveLoading: false});
            if (res.data.createAnswerAdmin.status === 200) {
              notification['success']({
                message: 'Due Diligence saved!',
                duration: 3.5,
              });
              if (!last) {
                functionUpdateCurrent(Number(current) + 1);
              } else {
                changeTab(newTab);
              }
            }
            if (res.data.errors) {
              notification['error']({
                message: 'An error have occurred',
                duration: 3.5,
              });
              console.log(res.data.errors);
            }
          })
          .catch(err => {
            this.setState({saveLoading: false});
            if (err.graphQLErrors) {
              for (let i = 0; i < err.graphQLErrors.length; i++) {
                notification['error']({
                  message: 'Network Error',
                  description: err.graphQLErrors[i].message,
                  duration: 3.5,
                });
              }
            }
            console.log('Network error');
          });
        this.setState({saveLoading: false});
      }
    });
  };

  changeLazy = () => {
    this.setState({lazyEvaluation: !this.state.lazyEvaluation});
  };

  render() {
    const {data, admin, manager, investment, last, subsections} = this.props;
    const {saveLoading, lazyEvaluation} = this.state;

    let res = [];

    let newQuestionSet = [];
    if (investment && lazyEvaluation) {
      newQuestionSet = data.questionSet.filter((question) => (question.name === 'Personal Opinion'));
    } else {
      data.questionSet.forEach(function (question, index) {
        const toAdmin = admin && question.showToAdmin;
        const toManager = manager && question.showToManager;
        const toInvestment = investment && question.showToInvestment;
        if (toAdmin || toManager || toInvestment) {
          newQuestionSet.push(question);
        }
      });
    }

    let questionGroupSet = [];
    for (let index = 0; index < subsections.length; index += 1) {
      const item = subsections[index];
      const questionSet = newQuestionSet.filter(question => question.subsection !== null && question.subsection.id === item.id);
      if (questionSet.length)
        questionGroupSet.push(questionSet);
    }
    let temQuestions = newQuestionSet.filter(question => question.subsection === null);
    if (temQuestions.length)
      questionGroupSet.push(temQuestions);
    questionGroupSet.forEach(questions => {
      if (questionGroupSet.length > 1){
        res.push(
            <Col span={24}>
              <Title level={3}>
                {
                  questions[0].subsection !== null
                    ? questions[0].subsection.name
                    : "Others"
                }
              </Title>
            </Col>
        );
      }
      res = res.concat(this.bodyConstructor(questions));
    });

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">

        <Row type="flex" justify="space-around" align="middle"
             gutter={48}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div>{res}</div>
          </Col>
        </Row>
        <Row style={{marginBottom: '50px', marginTop: '30px'}} type="flex" justify="space-around" align="middle"
             gutter={48}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item>
              {
                investment &&
                <Button type="default"
                className="login-form-button"
                onClick={this.changeLazy}
                >
                  {lazyEvaluation ? 'Do the full evaluation' : 'Keep it simple'}
                </Button>
              }
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={saveLoading}>
                {last ? 'Finish' : 'Save and Next'}
                {
                  last ?
                  undefined
                  : <Icon type="right"/>
                }
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}


DueDiligenceForm = withRouter(DueDiligenceForm);
DueDiligenceForm = graphql(mutation)(DueDiligenceForm);
const WrappedDueDiligenceForm = Form.create({name: 'founder_add'})(DueDiligenceForm);

export default class DueDiligenceComponent extends React.Component {

  state = {
    current: 0,
  };

  functionUpdateCurrent = (newValue) => {
    this.setState({
      current: newValue
    });
  };

  render() {
    const {data, venture, admin, manager, investment, member, changeTab, newTab, subsections, pre_evaluation} = this.props;
    const {current} = this.state;
    const allow_classify = admin && pre_evaluation;
    let functionUpdateCurrent = this.functionUpdateCurrent;
    let res = [];
    let last = false;
    if (data) {
      data.forEach(function (due, index) {
        if (index === data.length - 1) {
          last = true;
        }
        res.push(
          <TabPane tab={due.name} key={allow_classify? index + 1 : index}>
            <WrappedDueDiligenceForm
                data={due}
                venture={venture}
                admin={admin}
                manager={manager}
                member={member}
                investment={investment}
                current={current}
                last={last}
                functionUpdateCurrent={functionUpdateCurrent}
                changeTab={changeTab}
                newTab={newTab}
                subsections={subsections}
            />
          </TabPane>
        );
      });
    }
    let active = current.toString();
    return (
      <Tabs defaultActiveKey={active} activeKey={active} onChange={functionUpdateCurrent}>
        {allow_classify && (
            <TabPane tab="Classify" key="0">
              <Classify venture={venture} current={current} functionUpdateCurrent={functionUpdateCurrent} />
            </TabPane>
        )}
        {res}
      </Tabs>
    );


  }
}
