import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import {
  Row,
  Col,
  Table,
  Icon,
  Breadcrumb
} from 'antd';
import MessageComponent from '../../../components/Message';

import {Typography} from 'antd';
import {Link} from 'react-router-dom';

import '../../../styles/utils.scss';

const {Title} = Typography;


const query = gql`
query DetailView($id: ID!) {
  member(id: $id) {
    id
    firstName
    lastName
    emailAddress
    memberopinionSet {
      id
      venture{
        id
        companyName
      }
      email
      linkUsed
      evaluation
      comment
    }
  }
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};


class ManagerDetailView extends React.Component {

  renderBoolean(data) {
    if (data) {
      return <Icon
        className="positive-color font-15"
        type="check"/>;
    } else {
      return <Icon
        className="negative-color font-15"
        type="close"/>;
    }
  }

  render() {
    const columns = [
      {
        title: 'Venture',
        dataIndex: 'venture',
        key: 'venture',
        align: 'center',
        children: [
          {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (text, data) => (
              this.renderBoolean(!data.venture.archived)
            )
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, data) => (
              <Link
                to={`/dashboard/founder/venture/${data.venture.id}/`}>
                {data.venture.companyName}
              </Link>
            )
          },],
      },
      {
        title: 'Evaluation',
        dataIndex: 'evaluation',
        key: 'evaluation',
        align: 'center',
        children: [
          {
            title: 'Email Received',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            render: (text, data) => (
              this.renderBoolean(data.email)
            )
          },
          {
            title: 'Link Used',
            dataIndex: 'link',
            key: 'link',
            align: 'center',
            render: (text, data) => (
              this.renderBoolean(data.linkUsed)
            )
          },
          {
            title: 'Evaluation',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            render: (text, data) => (
              this.renderBoolean(data.evaluation)
            )
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            align: 'center',
            render: (text, data) => (
              data.comment
            )
          },
        ],
      },
    ];

    let id = this.props.match.params.id;

    return (
      <Query
        query={query}
        variables={
          {
            id
          }
        }
        pollInterval={500}
      >
        {({loading, error, data, refetch}) => {
          if (loading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text="Loading"
              level={4}/>
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );

          addKeys(data.member.memberopinionSet);

          return (
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>

                <Breadcrumb className="dashboard-breadcrumb">
                  <Breadcrumb.Item>
                    <Link to="/dashboard/member/manager/list/">
                      Managers
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {data.member.firstName} {data.member.lastName}
                  </Breadcrumb.Item>
                </Breadcrumb>

                <Title className="dashboard-title" level={1}>{data.member.firstName} {data.member.lastName}</Title>

                <Title className="dashboard-title dashboard-button" level={4}>{data.member.emailAddress}</Title>

                <Table
                  columns={columns}
                  dataSource={data.member.memberopinionSet}
                  pagination={{pageSize: 50}}
                  bordered
                />

              </Col>
            </Row>

          );
        }}
      </Query>

    );
  }
}

export default ManagerDetailView;