import React from 'react';
import SiderContent from './SiderContent';
import { Layout } from 'antd';
import SiderDrawer from './SiderDrawer';

import './styles.scss';

const { Sider } = Layout;

const SiderWrapper = ({ customConfig }) => (
  [
    <Sider key="siderWrapperContent" className="custom-sider" width={240} style={{ background: '#fff' }}>
      <SiderContent customConfig={customConfig} />
    </Sider>,
    <SiderDrawer key="siderWrapperDrawer" customConfig={customConfig} />,
  ]
);

export default SiderWrapper;
