import gql from 'graphql-tag';

export const query = gql`
{
  configuration {
    logoHeader,
    logoFooter,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    titleSize,
    subtitleSize,
    textSize,
    fontType
  }
}
`;