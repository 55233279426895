import React from 'react';
import {
  Col,
  Row,
  Typography,
} from 'antd';
import { validateNumber, validateDescription } from '../../tools';

const { Title, Text } = Typography;

const CircleSectionItem = (props) => {
  const {
    item,
    sectionId,
    isEditingExtra,
    displayEditable,
    onChangeField,
    checkLimitsAddErase,
    circleList,
    onEraseItem,
  } = props;
  const circleContent = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 14 },
    lg: { span: 14 },
    xl: { span: 14 },
    xxl: { span: 14 },
  };

  return (
    (isEditingExtra(item.isComplete))
      && (
      <Col
        key={item.arrayId}
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={8}
      >
        <Row type="flex" justify="center" align="middle">
          <Col
            className={`text-center ${!item.isComplete ? 'incomplete-field' : ''}`}
            {...circleContent}
            data-cy="test-container-col-circleSectionItem"
          >
            <div
              className="circle-container"
              style={{ textAlign: 'center' }}
            >
              <div
                className="edit median-number"
                data-cy="test-number-title-circleSectionItem"
              >
                <Title
                  className="pd-0 mg-0"
                  onClick={(e) => displayEditable(e)}
                  editable={{
                    onChange: (event) => onChangeField(
                      item.arrayId, sectionId, event, 'circleList', 'number', validateNumber,
                    ),
                  }}
                >
                  {item.number}
                </Title>
              </div>
              <div
                className="edit median-text"
                data-cy="test-quantity-title-circleSectionItem"
              >
                <Title
                  level={4}
                  className="pd-0 mg-0"
                  onClick={(e) => displayEditable(e)}
                  editable={{
                    onChange: (event) => onChangeField(
                      item.arrayId, sectionId, event, 'circleList', 'quantity',
                    ),
                  }}
                >
                  {item.quantity}
                </Title>
              </div>
            </div>
            <div
              className="edit small-text"
              data-cy="test-description-text-circleSectionItem"
            >
              <Text
                onClick={(e) => displayEditable(e)}
                editable={{
                  onChange: (event) => onChangeField(
                    item.arrayId, sectionId,
                    event, 'circleList', 'description', validateDescription,
                  ),
                }}
              >
                {item.description}
              </Text>
            </div>
            <Row
              style={{ marginTop: '10px' }}
              type="flex"
              justify="center"
              align="bottom"
            >
              {checkLimitsAddErase(
                sectionId, false, circleList, onEraseItem, 'close-container', item, 'circleList',
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      )
  );
};

export default CircleSectionItem;
