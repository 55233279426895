import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import ManagerListView from './ManagerListView';
import ManagerDetailView from "./ManagerDetailView";
import ManagerAddView from "./ManagerAddView";
import InvestmentListView from './InvestmentListView';
import InvestmentDetailView from "./InvestmentDetailView";
import InvestmentAddView from "./InvestmentAddView";
import GuestListView from './GuestListView';
import SuggestedView from '../suggested';

class FounderView extends Component {

  render() {
    return (
      <Switch>
        <Route path="/dashboard/member/manager/list/" component={ManagerListView} />
        <Route path="/dashboard/member/manager/id/:id" component={ManagerDetailView} />
        <Route path="/dashboard/member/manager/add/" component={ManagerAddView} />
        <Route path="/dashboard/member/investment/list/" component={InvestmentListView} />
        <Route path="/dashboard/member/investment/id/:id" component={InvestmentDetailView} />
        <Route path="/dashboard/member/investment/add/" component={InvestmentAddView} />
        <Route path="/dashboard/member/guest/:id/list/" component={GuestListView} />
        <Route path="/dashboard/member/suggested/list" component={SuggestedView}/>
      </Switch>
    );
  }
}

export default FounderView;
