import React, { Component } from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import { graphql } from 'react-apollo';

import Ventures from './Ventures/ventures';
import MessageComponent from '../../components/Message';
import FounderAddView from '../dashboard/founder/FounderAddView';
import InvestmentAddView from '../dashboard/member/InvestmentAddView';
import TopMenu from '../../components/TopMenu';

import { QUERIES } from '../../utils/queries';
import { PATHS } from '../../utils/paths';
import { INVESTMENT_COMMITTEE } from '../../utils/constants';

import '../../styles/utils.scss';
import '../dashboard/dashboard.scss';


class DashboardInvestor extends Component {
  componentWillUpdate(nextProps) {
    nextProps.data.refetch();
    const { location } = this.props;
    const { data, currentUser } = nextProps;
    if (!data.loading && currentUser === null) {
      if (location.pathname !== '/login/') {
        window.location.replace(`/login/?next=${location.pathname}`);
      }
    }
  }

  render() {
    const { data, location } = this.props;
    const { currentUser, loading, investorUser } = data;
    const shouldShowLoading = loading && !currentUser;
    if (shouldShowLoading) {
      if (location.pathname === '/login/') {
        return null;
      }

      return (
        <MessageComponent
          icon="loading"
          iconSize={50}
          text=""
          level={4}
        />
      );
    }

    return (
      <div>
        <TopMenu {...this.props} />
        <div style={{ margin: '0 16px' }}>
          <Switch>
            <Route
              exact
              path={PATHS.dashboardInvestorVentures}
              render={(props) => {
                return <Ventures investorData={investorUser} currentUser={currentUser} {...props} />;
              }}
            />
            <Route
              exact
              path={PATHS.investorAddFounder}
              render={(props) => {
                return <FounderAddView investorId={investorUser.id} type={INVESTMENT_COMMITTEE} {...props} />;
              }}
            />
            <Route
              exact
              path={PATHS.investorAddInvestor}
              render={(props) => {
                return <InvestmentAddView investorId={investorUser.id} type={INVESTMENT_COMMITTEE} {...props} />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default graphql(QUERIES.dashboardInvestor)(DashboardInvestor);
