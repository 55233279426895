import {
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import montserrat from '../../../fonts/Montserrat/Montserrat-Regular.ttf';
import montserratBold from '../../../fonts/Montserrat/Montserrat-Bold.ttf';

Font.register({
  family: '"Montserrat", sans-serif',
  fonts: [
    {
      src: montserrat
    },
    {
      src: montserratBold,
      fontWeight: 'bold'
    }
  ]
});

const titleFontSize = 24;
const normalFontSize = 10;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#f0f2f5',
    fontFamily: '"Montserrat", sans-serif'
  },
  margin: {
    width: '100%',
    paddingHorizontal: 25,
    paddingVertical: 25
  },
  companyNameSection: {
    backgroundColor: 'white',
    width: 250,
    height: 50,
    marginLeft: 300
  },
  centerTitle: {
    color : '#5486CF',
    textAlign: 'center',
    marginTop: 10,
    fontSize: titleFontSize,
    fontWeight: 'bold'
  },
  twoColumn: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: -3
  },
  columnTitle: {
    color : '#5486CF',
    marginLeft: 50,
    marginTop: 15,
    fontSize: titleFontSize,
    fontWeight: 'bold'
  },
  firstColumn: {
    width: 344,
    border: '3pt solid white'
  },
  firstColumnNoRender: {
    width: 344,
  },
  sideIcon: {
    backgroundColor: '#3657A0',
    textAlign: 'center',
    marginLeft: -25,
    width: 50,
    height: 50,
    paddingTop: 15
  },
  contentFirstColumn: {
    marginLeft: 25,
    marginTop: 15,
    width: 250,
    fontSize: normalFontSize
  },
  secondColumn: {
    marginTop: 50,
    width: 200,
    height: 'auto',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: 20,
    paddingTop: 20
  },
  secondColumnNoRender: {
    width: 200
  },
  circle: {
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    backgroundColor: '#F0F2F5',
    textAlign: 'center',
    paddingTop: 25,
    marginTop: 30,
    marginLeft: 30,
    marginBottom: 15,
    height: 140,
    width: 140
  },
  revenueModelSection: {
    border: '3pt solid white',
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: 40
  },
  revenueNoRender: {
    marginBottom: 30
  },
  revenueHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  revenueTitle: {
    width: 245,
    color: '#5486CF',
    fontSize: titleFontSize,
    fontWeight: 'bold',
    marginBottom: 15
  },
  revenuePercentage: {
    width: 239,
    textAlign: 'right',
    color: '#5486CF',
    fontSize: normalFontSize,
    marginTop: 10
  },
  semiCircleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  semiCircle: {
    borderTopStyle: 'solid',
    borderTopWidth: 2,
    borderTopColor: 'white',
    textAlign: 'center',
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    height: 125,
    width: 125
  },
  checklistSection: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 20,
    marginTop: -10,
    paddingBottom: 15
  },
  checklist: {
    width: 225,
    backgroundColor: '#5486CF',
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
    paddingTop: 20,
    paddingBottom: 20
  },
  checklistContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15
  },
  checklistIcon: {
    width: 40,
    height: 40,
    fontSize: 10,
    textAlign: 'center'
  },
  checklistList: {
    width: 150,
    marginLeft: 15,
    textAlign: "left"
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  profileSection: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  profileData: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  imageProfile: {
    width: 100,
    height: 100,
    border: '1pt solid black',
    marginLeft: 50
  },
  cover: {
    objectPosition: '0pt 0pt',
    objectFit: 'cover',
    width: '100%'
  },
  profileDescription: {
    width: 300,
    fontSize: normalFontSize,
    marginLeft: 30,
    marginTop: 20
  },
  bigNumber: {
    fontSize: 40,
    marginBottom: 3,
    fontWeight: 'bold'
  },
  medianText: {
    fontSize: 15,
    marginBottom: 4,
    fontWeight: 'bold'
  },
  normalText: {
    fontSize: normalFontSize
  },
  smallText: {
    fontSize: 10
  },
  bold: {
    fontWeight: 'bold'
  },
  icon: {
    width: 25,
    height: 25
  }
});

export default styles;