import React, { Component } from 'react';
import {
  Button,
  Icon,
  Col,
  Typography,
  Upload,
} from 'antd';
import {
  mediaUrl,
  uploadPitch,
} from '../../../../../../utils/ApiEndPoints';
import { isUndefined } from '../../../../../../utils/tools';
import { API_KEY } from '../../../../../../utils/constants';

const { Title } = Typography;

class UploadPitchDeck extends Component {
  constructor(props) {
    super(props);

    const { pitchVenture } = this.props;
    let pitchId = false;
    const pitchDefault = [];
    if (pitchVenture !== null) {
      pitchDefault.push({
        uid: '-1',
        name: 'Pitch',
        status: 'done',
        url: `${mediaUrl}${pitchVenture.pitch}`,
      });
      pitchId = pitchVenture.id;
    }

    this.handlePitchChange = this.handlePitchChange.bind(this);
    this.deletePitch = this.deletePitch.bind(this);
    this.state = {
      pitchVenture: pitchDefault,
      pitchId,
    };
  }

  handlePitchChange({ fileList }) {
    const { changeExistingPitch } = this.props;
    this.setState({ pitchVenture: fileList });
    changeExistingPitch(fileList);
  }

  deletePitch() {
    const { pitchId } = this.state;
    if (pitchId) {
      fetch(uploadPitch, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: pitchId,
      });
    }
  }

  render() {
    const {
      editing,
      ventureId,
    } = this.props;
    const { pitchVenture } = this.state;
    const existFile = pitchVenture.length;
    if (pitchVenture.length > 0 && !isUndefined(pitchVenture[0].originFileObj)) {
      pitchVenture[0].url = URL.createObjectURL(pitchVenture[0].originFileObj);
    }
    const uploadButton = (
      <Button>
        <Icon type="upload" />
        {' '}
Select File
      </Button>
    );

    return (
      <Col span={12} className="text-center mt-2 mb-2 clearfix">
        <Title className="mb-0 section-title title-team pt-0">
            Upload your Pitch Deck
        </Title>
        { editing ? (
          [
            <Col span={24} className="mb-1">
              <Icon
                type="check-circle"
                className={`pitch-icon-${
                  pitchVenture.length > 0 ? 'complete' : 'incomplete'
                }`}
              />
            </Col>,
            <Col span={24}>
              <Upload
                accept=".pdf"
                action={uploadPitch}
                name="pitch"
                data={{
                  venture: ventureId,
                }}
                headers={{
                  'X-Api-Key': API_KEY,
                }}
                fileList={pitchVenture}
                listType="text"
                disabled={!editing}
                onChange={(event) => this.handlePitchChange(event)}
                onRemove={() => {
                  this.deletePitch();
                }}
                className="upload-pitch-file"
              >
                {pitchVenture.length >= 1 ? null : uploadButton}
              </Upload>
            </Col>,
          ]
        ) : (
          <Button
            icon="download"
            href={existFile ? pitchVenture[0].url : null}
            target={existFile ? pitchVenture[0].url : null}
            disabled={!existFile}
          >
                Download Venture Pitch
          </Button>
        )}
      </Col>
    );
  }
}

export default UploadPitchDeck;
