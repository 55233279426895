import gql from "graphql-tag";

export const foundersQuery = gql`
{
  founderSuggestedNotActive {
    id,
    firstName,
    lastName,
    emailAddress,
    suggestedBy {
      firstName,
      lastName,
    }
  }
}
`;

export const membersQuery = gql`
{
  memberSuggestedNotActive {
    id,
    firstName,
    lastName,
    emailAddress,
    suggestedBy {
      firstName,
      lastName,
    },
    tags {
      name,
      id,
    },
    answerSet {
      id,
      question {
				name
      }
      answerextratextSet {
        choice {
          id,
          name
        }
        extraText
      }
    }
  }
}
`;
