import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { isUndefined } from 'util';
import { query } from './gql';
import {
  API_URL,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_TERTIARY_COLOR,
} from '../../utils/constants';
import logo from '../../images/default_logo.png';
import whiteLogo from '../../images/default_logo.png';
import GeneralWrapper from '../generalWrapper';
import { isNull } from '../../utils/tools';
import { isDarker } from '../../styles/tools';


const getLogo = (customLogo, defaultLogo) => {
  let src = defaultLogo;
  if (!isUndefined(customLogo) && customLogo !== '') {
    src = `${API_URL}/media/${customLogo}`;
  }

  return (src);
};

let hasCustomFonts = false;
let hasPrimaryColor = false;

class BaseWrapper extends Component {

  setFontSize = (data) => {
    if (!hasCustomFonts) {
      const titleFontSize = document.createElement('style');
      titleFontSize.innerHTML = `
        h1.ant-typography, .ant-typography h1 {font-size: ${data.configuration.titleSize}px;}`;
      const subtitleFontSize = document.createElement('style');
      subtitleFontSize.innerHTML = `
        h2.ant-typography, .ant-typography h2 {font-size: ${data.configuration.subtitleSize}px;}`;
      const textFontSize = document.createElement('style');
      textFontSize.innerHTML = `body {font-size: ${data.configuration.textSize}px;}`;
      document.body.appendChild(subtitleFontSize);
      document.body.appendChild(titleFontSize);
      document.body.appendChild(textFontSize);
      hasCustomFonts = true;
    }
  };

  setPrimaryColor = ({ configuration }) => {
    if (!hasPrimaryColor) {
      const { primaryColor } = configuration;
      const modify = {
        '@primary-color': primaryColor,
      };
      window.less.modifyVars(modify).then(() => {
        const styles = document.createElement('style');
        const darkPrimary = isDarker(primaryColor);
        styles.innerHTML = `
          .ant-btn-primary { color: ${darkPrimary ? 'white' : 'black'};}`;
        document.body.appendChild(styles);  
      });
      hasPrimaryColor = true;
    }
  }

  render() {
    const { component, header } = this.props;
    const setHeader = !isUndefined(header) ? header : true;
    const InsideComponent = component;

    return (
      <Query
        query={query}
      >
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) {
            const customConfig = {
              logoHeader: logo,
              logoFooter: whiteLogo,
              primaryColor: DEFAULT_PRIMARY_COLOR,
              secondaryColor: DEFAULT_SECONDARY_COLOR,
              tertiaryColor: DEFAULT_TERTIARY_COLOR,
              fontType: undefined,
            };
            if (setHeader) {
              return (
                <GeneralWrapper Component={component} customConfig={customConfig} {...this.props} />
              );
            }

            return (
              <InsideComponent
                customConfig={customConfig}
                {...this.props}
              />
            );
          }
          const primary = data.configuration.primaryColor;
          const secondary = data.configuration.secondaryColor;
          const tertiary = data.configuration.tertiaryColor;
          const customConfig = {
            logoHeader: getLogo(data.configuration.logoHeader, logo),
            logoFooter: getLogo(data.configuration.logoFooter, whiteLogo),
            primaryColor: isNull(primary) ? DEFAULT_PRIMARY_COLOR : primary,
            secondaryColor: isNull(secondary) ? DEFAULT_SECONDARY_COLOR: secondary,
            tertiaryColor: isNull(tertiary) ? DEFAULT_TERTIARY_COLOR: tertiary,
            fontType: data.configuration.fontType,
          };
          this.setFontSize(data);
          this.setPrimaryColor(data);
          if (setHeader) {
            return (
              <GeneralWrapper Component={component} customConfig={customConfig} {...this.props} />
            );
          }

          return (
            <InsideComponent
              customConfig={customConfig}
              {...this.props}
            />
          );
        }}
      </Query>
    );
  }
}

export default BaseWrapper;
