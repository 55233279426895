import React from 'react';
import {
    Layout,
    Menu,
    Carousel,
    Row,
    Col,
    Typography,
    Steps,
    Form,
    Input,
    Checkbox,
    Tooltip,
    Icon,
    Button
} from 'antd';
import ReactDOM from 'react-dom';
import './styles.scss';
import '../../styles/utils.scss';
import logo from '../../images/logo.png';
import who from '../../images/who_we_are.png';
import founder from '../../images/founder.png';
import investor from '../../images/investor.png';
import landing from '../../images/landing.jpg';

const {Header, Content, Footer} = Layout;
const {Title, Paragraph} = Typography;
const {Step} = Steps;
const {TextArea} = Input;

class LandingView extends React.Component {
    handleClickMenuItem = e => {
        const position = ReactDOM.findDOMNode(this.refs[e.key]);
        window.scrollTo(0, position.offsetTop - 70);
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Layout className="layout">
                <Header className="header-content">
                    <div className="logo-content">
                        <img src={logo} className="logo" alt="logo" />
                    </div>
                    <Menu
                        mode="horizontal"
                        className="menu-content"
                        onClick={this.handleClickMenuItem}
                    >
                        <Menu.Item key="who_we_are">WHO WE ARE?</Menu.Item>
                        <Menu.Item key="who_we_support">HOW WE SUPPORT?</Menu.Item>
                        <Menu.Item key="why_work">WHY WORK WITH US?</Menu.Item>
                        <Menu.Item key="contact_us">CONTACT US</Menu.Item>
                    </Menu>
                </Header>
                <Content className="content">
                    <div ref="carousel" className="mb-2">
                        <Carousel autoplay>
                            <div>
                                <img src={landing} className="w-100" alt="landing" />
                                <h1>A SMARTER WAY TO RAISE CAPITAL</h1>
                            </div>
                        </Carousel>
                    </div>
                    {/*Who we are section*/}
                    <div ref="who_we_are" className="my-2 section">
                        <Row type="flex" align="middle" gutter={18}>
                            <Col span={14}>
                                <img className="w-100" src={who} alt="who" />
                            </Col>
                            <Col span={10}>
                                <Title level={2}>Who we are?</Title>
                                <Paragraph className="description-text">
                                    FVS Navigator offers an ecosystem closing the gap between entrepreneurs and investors. Whether you are a founder seeking capital to shape your venture, or an investor diversifying your portfolio with attractive investment opportunities,
                                    FVS Navigator can help.
                                </Paragraph>
                            </Col>
                        </Row>
                    </div>
                    {/*Who we support section*/}
                    <div ref="who_we_support" className="my-2 section">
                        <Row className="text-center">
                                    <Title level={2}>How we support?</Title>
                                    <Col span={12}>
                                        <img className="w-50" src={founder} alt="founder" />
                                        <Title level={4}>
                                            Prospective Founders
                                        </Title>
                                        <Steps className="text-left" direction="vertical" size="small" current={3}>
                                            <Step title="Fill in an online one-pager" />
                                            <Step title="Upload your pitch decks" />
                                            <Step title="Receive our feedback" />
                                            <Step title="Ready to raise fund!" />
                                        </Steps>
                                    </Col>
                                    <Col span={12}>
                                        <img className="w-50" src={investor} alt="investor" />
                                        <Title level={4}>
                                            Prospective Investors
                                        </Title>
                                        <Steps className="text-left" direction="vertical" size="small" current={3}>
                                            <Step title="Inform us your Investment preferences" />
                                            <Step title="Get evaluated investment recommendations" />
                                            <Step title="Contacted by our investment specialists" />
                                            <Step title="Ready to invest!" />
                                        </Steps>
                                    </Col>
                                </Row>
                    </div>
                    <div className="my-2 section">
                        <Row type="flex" justify="center" className="w-100">
                            <Col span={22}>
                                <Title level={3} className="text-center">FVS Navigator</Title>
                                <Row type="flex" align="middle">
                                    <Col span={4} className="text-center">
                                        <Icon type="check" className="check-icon" />
                                    </Col>
                                    <Col span={20}>
                                        <Paragraph>Our validated valuation metrics and sounding board ensure high quality venture opportunities</Paragraph>
                                    </Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col span={4} className="text-center">
                                        <Icon type="check" className="check-icon" />
                                    </Col>
                                    <Col span={20}>
                                        <Paragraph>Our add-on services help strengthen your team</Paragraph>
                                    </Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col span={4} className="text-center">
                                        <Icon type="check" className="check-icon" />
                                    </Col>
                                    <Col span={20}>
                                        <Paragraph>Our tested matching algorithm brings the right partners</Paragraph>
                                    </Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col span={4} className="text-center">
                                        <Icon type="check" className="check-icon" />
                                    </Col>
                                    <Col span={20}>
                                        <Paragraph>Our strong network accelerates the funding process</Paragraph>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {/*Why work section*/}
                    <div ref="why_work" className="my-2 section">
                        <div>
                            <Title className="text-center" level={2}>Why work with us?</Title>
                            <Row gutter={18} type="flex" align="middle">
                                <Col span={6}>
                                    <Title level={4} className="text-center">Time-saving on repetitive processes</Title>
                                </Col>
                                <Col span={6}>
                                    <Title level={4} className="text-center">Reliable evaluation</Title>
                                </Col>
                                <Col span={6}>
                                    <Title level={4} className="text-center">Cost-efficient approach</Title>
                                </Col>
                                <Col span={6}>
                                    <Title level={4} className="text-center">We leverage the ecosystem</Title>
                                </Col>
                            </Row>
                            <Row gutter={18}>
                                <Col span={6}>
                                    <Paragraph className="text-justify">
                                        Stay on top of each deal by replacing inefficient communication with a standardized digital one-pager that can be sent automatically to investors. The validated match algorithm reduces searching costs for all parties by bringing the ideal partners.
                                    </Paragraph>
                                </Col>
                                <Col span={6}>
                                    <Paragraph className="text-justify">
                                        Our curated expert teams conducts thorough evaluation of your company based on real experience from successfully founding and growing tech companies. We understand the key elements for a successful start-up.
                                    </Paragraph>
                                </Col>
                                <Col span={6}>
                                    <Paragraph className="text-justify">
                                        Our platform as a service is free of charge. Founders and investors do not pay any subscription fee or management fee. On average, we ask for 1% of equity for every 100k invested in start-up.
                                    </Paragraph>
                                </Col>
                                <Col span={6}>
                                    <Paragraph className="text-justify">
                                        Our extensive network of investors, accelerators, and entrepreneurs is a crucial part of the solution to boost engagement across all domains.
                                    </Paragraph>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/*Contact us section*/}
                    <div ref="contact_us" className="my-2 section">
                        <Form onSubmit={this.handleSubmit} className="w-100">
                            <Row gutter={32}>
                                <Col span={12}>
                                    <Title level={2}>Contact us</Title>
                                    <Form.Item label="Your Name">
                                        {getFieldDecorator('name', {
                                            rules: [{
                                                required: true,
                                                message: 'Required'
                                            }]
                                        })(
                                            <Input placeholder="Name" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Your Email">
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Required'
                                                }
                                            ]
                                        })(
                                            <Input placeholder="Email" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Your Phone Number">
                                        {getFieldDecorator('number')(
                                            <Input placeholder="Phone Number" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="I am">
                                        {getFieldDecorator('type', {
                                            rules:[{
                                                required: true,
                                                message: 'Required'
                                            }]
                                        })(
                                            <Checkbox.Group>
                                                <Checkbox value="founder">
                                                    <span>Founder</span>
                                                    <Tooltip
                                                        title="Founders are interested in getting in touch with changemakers"
                                                        className="space-pl"
                                                    >
                                                        <Icon type="info-circle" />
                                                    </Tooltip>
                                                </Checkbox>
                                                <br />
                                                <Checkbox value="investor">
                                                    <span>Investor</span>
                                                    <Tooltip
                                                        title="Professional investors searching for exposure in high growth start-ups"
                                                        className="space-pl"
                                                    >
                                                        <Icon type="info-circle" />
                                                    </Tooltip>
                                                </Checkbox>
                                                <br />
                                                <Checkbox value="multiplicators">
                                                    <span>Multiplicators</span>
                                                    <Tooltip
                                                        title="Institutions or Individuals having interest in cooperating with FVS Navigator ecosystem"
                                                        className="space-pl"
                                                    >
                                                        <Icon type="info-circle" />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Checkbox.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Your message">
                                        {getFieldDecorator('message', {
                                            rules:[{
                                                required: true,
                                                message: 'required'
                                            }]
                                        })(
                                            <TextArea rows={12} />
                                        )}
                                    </Form.Item>
                                    <small>
                                        This form collects your name and email so that we can correspond with you.
                                        Check out our imprint and data privacy policies for more information.
                                    </small>
                                    <Form.Item className="mb-0">
                                        {getFieldDecorator('permission', {
                                            rules: [{
                                                required: true,
                                                message: 'required'
                                            }]
                                        })(
                                            <Checkbox>
                                                I consent to having FVS Navigator to collect my name and email
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                    <Form.Item className="mb-0">
                                        {getFieldDecorator('subscription')(
                                            <Checkbox>
                                                Subscribe me to FVS Navigator mailing list
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Send your inquiry
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Content>
                <Footer className="footer-content">
                    <Row type="flex" align="middle">
                        <Col span={12}>
                            COPYRIGHT 2019 FINCITE, ALL RIGHTS RESERVED
                        </Col>
                        <Col span={12} className="text-right">
                            <Button className="mx-10px">Contact us</Button>
                            <Button>Data privacy & imprint</Button>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        );
    };
}

const WrappedLandingViewForm = Form.create({name: 'landing'})(LandingView);
export default WrappedLandingViewForm;