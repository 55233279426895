import React from 'react';
import {
  Button,
  Upload,
  Icon,
} from 'antd';
import { uploadPitch } from '../../utils/ApiEndPoints';
import { API_KEY } from '../../utils/constants';

const UploadPitch = (props) => {
  const { disabled, ventureId } = props;

  return (
    <Upload
      accept='.pdf'
      action={uploadPitch}
      name='pitch'
      data={{
        venture: ventureId,
      }}
      headers={{
        'X-Api-Key': API_KEY
      }}
      listType='text'
      className="upload-pitch-file"
      showUploadList={false}
    >
      <Button disabled={disabled} className="mt-1">
        <Icon type="upload" /> Upload Pitch
      </Button>
    </Upload>
  );
};

export default UploadPitch;
