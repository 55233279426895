import React from 'react';

import {
  Mutation,
  Query
} from 'react-apollo';
import gql from 'graphql-tag';

import OnePageRenderer from '../../navigator/onepager/OnePagerRenderer';
import MessageComponent from '../../../components/Message';
import QuestionnaireComponent from '../../../components/Questionnaire';

import {
  Col,
  Row,
  Typography,
  Tabs,
  BackTop,
  Breadcrumb,
  Tag,
  notification,
} from 'antd';
import {Link} from 'react-router-dom';
import ResultsView from './ResultsView';
import PitchDeck from '../../../components/PitchDeck';
import { mainContainerBody } from '../../../styles/responsive';

const TabPane = Tabs.TabPane;
const {Title} = Typography;

const query = gql`
query QuerySections($venture_id: ID!){
  sections{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsRightCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsCircle{
    id
    order
    minItems
    maxItems
    title
  }
  sectionsChecklist{
    id
    order
    minItems
    maxItems
    title
    boldTitle
  }
  sectionsProfile{
    id
    order
    minItems
    maxItems
  }
  contentSections(idVenture: $venture_id){
    id
    order
    icon
    title
    description
    isComplete
    section{
      id
    }
  }
  contentSectionsRightCircle(idVenture: $venture_id){
    id
    order
    number
    quantity
    year
    preText
    postText
    title
    isComplete
    rightCircleSection{
      id
    }
  }
  contentSectionsCircle(idVenture: $venture_id){
    id
    order
    initPercentage
    endPercentage
    percentageText
    percentageComplete
    circlesectioncontentSet{
      id
      order
      number
      quantity
      description
      isComplete
    }
    circleSection{
      id
    }
  }
  contentSectionsChecklist(idVenture: $venture_id){
    id
    order
    title
    description
    isComplete
    checklistSection{
      id
    }
  }
  contentSectionsProfile(idVenture: $venture_id){
    id
    order
    name
    linkedin
    isComplete
    profilesectioncontentSet{
      id
      order
      description
    }
    profileSection{
      id
    }

  }
  profilePhotos(idVenture: $venture_id){
    id
    order
    image
  }
  venture(id: $venture_id){
    id
    isReceived
    adminEvaluation
    initialEvaluation
    investmentEvaluation
    detailedEvaluation
    isArchived
    token
    companyName
    emailSent
    companyNameComplete
    founder{
      id
      firstName
      lastName
    }
    tags {
      id
      name
    }
  }
  pitchVenture(idVenture: $venture_id){
    pitch
  }
}
`;


const archive = gql`
mutation Mutation(
  $ventureId: Int!,
  $value: Boolean!,
)
  {
  archiveVenture(
    ventureId: $ventureId,
    value: $value,
  )
  {
    status
    venture{
      id
      isArchived
    }
  } 
}
`;

const addKeys = (content) => {
  for (let i = 0; i < content.length; i++) {
    content[i].key = i;
  }
  return content;
};


class OnePagerPreview extends React.Component {

  state = {
    current: "one-pager",
  };

  getEvaluationDisplay(value, text) {
    if (value === 'Y') {
      return (
        <Tag
          color="green">
          {text}
        </Tag>
      );

    }
    if (value === 'N') {
      return (
        <Tag
          color="red">
          {text}
        </Tag>
      );
    }
    if (value === 'ND') {
      return (
        <Tag
          color="#a9a9a9">
          {text}
        </Tag>
      );
    }
    if (value) {
      return (
        <Tag
          color="green">
          {text}
        </Tag>
      );
    }
    return (
      <Tag
        color="#a9a9a9">
        {text}
      </Tag>
    );
  }

  restoreArchive = (mutationFunction, ventureId, value) => {
    this.setState({saveLoading: true});
    let variables = {
      variables: {
        value: value,
        ventureId: ventureId
      }
    };
    mutationFunction(variables)
      .then(res => {
        if (res.data.archiveVenture.status === 200) {
          this.setState({saveLoading: false});
          notification['success']({
            message: 'Venture Restored',
            duration: 3.5,
          });
        } else {
          this.setState({saveLoading: false});
          notification['error']({
            message: 'An error have occurred',
            duration: 3.5,
          });
        }
      })
      .catch(err => {
        if (err.graphQLErrors) {
          for (let i = 0; i < err.graphQLErrors.length; i++) {
            notification['error']({
              message: 'Network Error',
              description: err.graphQLErrors[i].message,
              duration: 3.5,
            });
          }
        }
        console.log(err);
        this.setState({saveLoading: false});
        console.log('Network error');
      });
  };

  changeTab = (newValue) => {
    this.setState({
      current: newValue
    });
  };


  render() {
    let venture_id = this.props.match.params.id;
    const {current} = this.state;
    let admin = true;
    let manager = false;
    let investment = false;
    return (
      < Query
        query={query}
        variables={
          {
            venture_id
          }
        }
        pollInterval={500}
      >
        {
          ({loading, error, data, refetch}) => {
            const shouldShowLoading = loading && !data.venture;
            if (shouldShowLoading) {
              return (
                <MessageComponent
                  icon="loading"
                  iconSize={50}
                  text="Loading Preview"
                  level={4}/>
              );
            }
            if (error) return (
              <MessageComponent
                icon="smile"
                iconSize={50}
                text={`${error.message}`}
                level={4}/>
            );

            data.sections = addKeys(data.sections);
            data.sectionsRightCircle = addKeys(data.sectionsRightCircle);
            data.sectionsCircle = addKeys(data.sectionsCircle);
            data.sectionsChecklist = addKeys(data.sectionsChecklist);
            data.sectionsProfile = addKeys(data.sectionsProfile);
            data.contentSections = addKeys(data.contentSections);
            data.contentSectionsRightCircle = addKeys(data.contentSectionsRightCircle);
            data.contentSectionsCircle = addKeys(data.contentSectionsCircle);
            data.contentSectionsChecklist = addKeys(data.contentSectionsChecklist);
            data.contentSectionsProfile = addKeys(data.contentSectionsProfile);
            data.profilePhotos = addKeys(data.profilePhotos);

            let ventureData = data.venture;

            let tag = null;
            if (data.venture.isArchived) {
              tag =
                <Mutation mutation={archive}>
                  {(archiveVenture, {mutationData}) => (
                    <Tag
                      closable
                      onClose={(event) => this.restoreArchive(archiveVenture, venture_id, false, event)}
                      color="red">
                      archived
                    </Tag>
                  )}
                </Mutation>;
            }

            let received = this.getEvaluationDisplay(data.venture.isReceived, "Received");
            let admin_display = this.getEvaluationDisplay(data.venture.adminEvaluation, "Admin Evaluation");
            let initial = this.getEvaluationDisplay(data.venture.initialEvaluation, "Internal Evaluation");
            let investors = this.getEvaluationDisplay(data.venture.investmentEvaluation, "Investors Evaluation");
            let detailed = this.getEvaluationDisplay(data.venture.detailedEvaluation, "Detailed Evaluation");
            const { venture } = data;
            const pitch = data.pitchVenture !== null ? data.pitchVenture.pitch : null;
            const pitchUpload =  !venture.emailSent && pitch !== null;

            return (
              <Row type="flex" justify="space-around" align="middle">
                <Col {...mainContainerBody}>
                  <Breadcrumb className="dashboard-breadcrumb">
                    <Breadcrumb.Item>
                      <Link to="/dashboard/founder/list/">
                        Founders
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/dashboard/founder/id/${data.venture.founder.id}/`}>
                        {data.venture.founder.firstName} {data.venture.founder.lastName}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {data.venture.companyName}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <BackTop/>

                  <Title className="dashboard-title" level={1}>
                    {data.venture.companyName} {tag}
                    <div>{received} {admin_display} {initial} {investors} {detailed}</div>
                  </Title>

                  <Tabs
                    className="dashboard-table"
                    defaultActiveKey="1"
                    activeKey={current}
                    onChange={this.changeTab}
                  >
                    <TabPane tab={pitchUpload ? "Pitch Deck" : "One Pager"} key="one-pager">
                      {
                        !pitchUpload ?
                        <OnePageRenderer
                          sections={data.sections}
                          sectionsRightCircle={data.sectionsRightCircle}
                          sectionsCircle={data.sectionsCircle}
                          sectionsChecklist={data.sectionsChecklist}
                          sectionsProfile={data.sectionsProfile}
                          contentSections={data.contentSections}
                          contentSectionsRightCircle={data.contentSectionsRightCircle}
                          contentSectionsCircle={data.contentSectionsCircle}
                          contentSectionsChecklist={data.contentSectionsChecklist}
                          contentSectionsProfile={data.contentSectionsProfile}
                          profilePhotos={data.profilePhotos}
                          pitchVenture={data.pitchVenture}
                          venture={data.venture}
                          preview={true}
                        /> : <PitchDeck pitch={pitch} />
                      }
                    </TabPane>
                    <TabPane tab="Initial Evaluation" key="initial">
                      <QuestionnaireComponent
                        pre_evaluation={true}
                        admin={admin}
                        manager={manager}
                        investment={investment}
                        ventureData={ventureData}
                        changeTab={this.changeTab}
                        newTab={"results"}
                      />
                    </TabPane>
                    <TabPane tab="Initial Score" key="results">
                      <ResultsView
                        venture={ventureData}
                        preEvaluation={true}
                        member=""
                        title={"Initial Evaluation"}
                        nextTab="detailed"
                        changeTab={this.changeTab}
                      />
                    </TabPane>

                    <TabPane tab="Detailed Evaluation" key="detailed">
                      <QuestionnaireComponent
                        pre_evaluation={false}
                        admin={admin}
                        manager={manager}
                        investment={investment}
                        ventureData={ventureData}
                        changeTab={this.changeTab}
                        newTab={"final-results"}
                      />
                    </TabPane>

                     <TabPane tab="Final Score" key="final-results">
                      <ResultsView
                        venture={ventureData}
                        preEvaluation={false}
                        member=""
                        title={"Detailed Evaluation"}
                      />
                    </TabPane>

                  </Tabs>
                </Col>
              </Row>

            );
          }
        }
      </Query>
    );
  }
}

export default OnePagerPreview;
