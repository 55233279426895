import { isUndefined } from "../utils/tools";

export const hexToRgb = (hex) => {
  /**
    *Function to convert hex to rgb format
    *@param: {hex}: any
  */
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const getContrast = (rbgColor, returnRgb = false) => {
  /**
   * Function to get color black or white color according to color
   * @param: {rgbColor}: string
   * @param: {returnRgb}: bool
   */
  if (rbgColor === null) return undefined;
  let luminance = (0.299 * rbgColor.r + 0.587 * rbgColor.g + 0.114 * rbgColor.b) / 255;

  let contrast;
  if (luminance > 0.5)
    contrast = 0;
  else
    contrast = 255;

  if (returnRgb)
    return {
      r: contrast,
      g: contrast,
      b: contrast
    };

  return 'rgb(' + contrast + ',' + contrast + ',' + contrast + ')';
};

export const isDarker = (color) => {
  /**
   * Return if color is dark or not
   * @param: {color}: string
   */
  if (isUndefined(color)) return true;
  const rbg_color = hexToRgb(color);
  const black_or_white = getContrast(rbg_color, true);
  return black_or_white.r > (255 / 2);
};