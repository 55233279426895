import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const SiderContext = React.createContext();

class SiderProvider extends Component {
  constructor(props) {
    super(props);
    this.setDrawerVisible = this.setDrawerVisible.bind(this);

    this.state = {
      drawerVisible: false,
    };
  }

  setDrawerVisible(drawerVisible) {
    this.setState({
      drawerVisible,
    });
  }

  render() {
    const { drawerVisible } = this.state;
    const { children } = this.props;

    return (
      <SiderContext.Provider
        value={{
          drawerVisible,
          setDrawerVisible: this.setDrawerVisible,
        }}
      >
        { children }
      </SiderContext.Provider>
    );
  }
}

SiderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SiderProvider;