import React, { useContext } from 'react';
import { Button } from 'antd';
import { SiderContext } from '../../contexts';

import './styles.scss';

const SiderButton = React.memo((props) => {
  const siderContext = useContext(SiderContext);
  const { darkTheme } = props;

  return (
    <Button
      style={{
        backgroundColor: darkTheme ? 'white' : 'black',
        color: darkTheme ? 'black' : 'white',
      }}
      className="sider-button-content"
      icon="menu"
      onClick={() => {
        siderContext.setDrawerVisible(true);
      }}
    />
  );
}, () => true);

export default SiderButton;