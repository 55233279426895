export const sectionsRightCircleDefault = () => (
  [
    {
      arrayId: 0,
      number: '24',
      quantity: '€ Billion +',
      year: '2017',
      preText: 'Worldwide (',
      postText: ')',
      title: 'Total Market Available',
      isComplete: false,
    },
    {
      arrayId: 1,
      number: '5',
      quantity: '€ Billion +',
      year: '2017',
      preText: 'In Europe (',
      postText: ')',
      title: 'Serviceable Available Market',
      isComplete: false,
    },
    {
      number: '25',
      arrayId: 2,
      quantity: '€ Million +',
      year: 'Specific Region within 2 yrs',
      preText: '',
      postText: '',
      title: 'Serviceable Obtainable Market',
      isComplete: false,
    },
  ]
);

export const sectionsRightCircleEmpty = () => (
  [
    {
      arrayId: 0,
      number: '00',
      quantity: '€ Billion +',
      year: '0000',
      preText: '',
      postText: '',
      title: 'No Data',
    },
  ]
);

export const sectionsCircleDefault = () => (
  [
    {
      arrayId: 0,
      number: '67',
      quantity: '€ Million +',
      description: 'Transaction Value on the marketplace',
      isComplete: false,
    },
    {
      arrayId: 1,
      number: '31',
      quantity: '€ Million +',
      description: 'Average membership fee to access full database of active customers',
      isComplete: false,
    },
    {
      arrayId: 2,
      number: '19',
      quantity: '€ Million +',
      description: 'Commission and advertising fee from external logistic and credit management providers',
      isComplete: false,
    },
  ]
);

export const sectionsCircleEmpty = () => (
  [
    {
      arrayId: 0,
      number: '00',
      quantity: '€ Million +',
      description: 'No data',
    },
  ]
);
