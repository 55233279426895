import gql from 'graphql-tag';

const mutation = gql`
  mutation Mutation(
    $id: Int!,
    $sections: [SectionInput]!,
    $rightCircleSections: [RightCircleSectionInput]!,
    $circleSections: [CircleSectionInput]!,
    $checklistSections: [ChecklistSectionInput]!,
    $profileSections: [ProfileSectionInput]!,
    $companyName: String!,
    $companyNameComplete: Boolean,
    $finish: Boolean,
    $acceptTerms: Boolean,
  ) {
    createSections(
      id: $id,
      sections: $sections,
      rightCircleSections: $rightCircleSections,
      circleSections: $circleSections,
      checklistSections: $checklistSections,
      profileSections: $profileSections,
      )
    {
      status
    }
    updateVentureOpportunity(
      id: $id, companyName: $companyName, finish: $finish, companyNameComplete: $companyNameComplete,
      acceptTerms: $acceptTerms
    ) {
      status
      venture {
        id
        companyName
        companyNameComplete
      }
    }
  }
`;

export default mutation;
