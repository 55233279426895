import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'

import FounderListView from './FounderListView';
import FounderDetailView from "./FounderDetailView";
import FounderAddView from "./FounderAddView";
import OnePagerPreview from "./OnePagerPreview";

class FounderView extends Component {

  render() {
    return (
      <Switch>
        <Route path="/dashboard/founder/list/" component={FounderListView}/>
        <Route path="/dashboard/founder/id/:id" component={FounderDetailView}/>
        <Route path="/dashboard/founder/add/" component={FounderAddView}/>
        <Route path="/dashboard/founder/venture/:id" component={OnePagerPreview}/>
      </Switch>
    )
  }
}

export default FounderView
