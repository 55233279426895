import gql from "graphql-tag";

export const mutationFounder = gql`
mutation Mutation($founderIds: [Int]!) {
  activeSuggestedFounders(founderIds: $founderIds) {
    status
  }
}
`;
export const mutationInvestor = gql`
mutation Mutation($memberIds: [Int]!) {
  activeSuggestedMembers(memberIds: $memberIds) {
    status
  }
}
`;
