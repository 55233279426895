import React from 'react';
import { Query } from 'react-apollo';
import MessageComponent from '../../../components/Message';
import DueDiligenceComponent from '../../../components/DueDiligence';
import gql from 'graphql-tag';

const due_query = gql`
query dueQuery(
  $pre_evaluation: Boolean!,
  $admin: Boolean!,
  $manager: Boolean!,
  $investment: Boolean!,
) {
  allDuesAdmin(
    preEvaluation: $pre_evaluation,
    admin: $admin,
    manager: $manager,
    investment: $investment,
  ){
    id
    name
    questionSet{
      id
      name
      description
      order
      showToAdmin
      showToManager
      showToInvestment
      options {
        id
        name
      }
      subsection {
        id
        name
      }
    }
  }
  allSubsections {
    id
    name
    order
  }
}
`;

const InitialEvaluation = (props) => {
  const { pre_evaluation, admin, manager, member_token, venture, changeTab  } = props;

  return (
    <Query
      query={due_query}
      variables={
        {
          pre_evaluation,
          admin,
          manager,
          investment: !manager,
        }
      }
    >
      {
        ({loading, error, data, refetch}) => {
          if (loading) {
            return (
              <MessageComponent
                icon="loading"
                iconSize={50}
                text="Loading Due Diligence"
                level={4}/>
            );
          }
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}/>
          );
          return (
            <DueDiligenceComponent
              pre_evaluation={true}
              admin={admin}
              manager={manager}
              investment={!manager}
              data={data.allDuesAdmin}
              member={member_token}
              venture={venture}
              changeTab={changeTab}
              subsections={data.allSubsections}
              newTab={!manager ? 'invite': 'results'}
            />
          );
        }
      }
    </Query>
  );
};

export default InitialEvaluation;
