import React, { Component } from 'react';
import {
  Row,
  Affix,
  Col,
  Button,
  Progress,
  notification,
} from 'antd';
import { graphql } from 'react-apollo';
import mutation from '../../mutation';
import ModalDisclaimer from './ModalDisclaimer';

const saveFormat = (list, extraKeyList) => {
  const response = [];
  const pick = (...props) => (o) => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});
  for (let i = 0; i < list.length; i += 1) {
    const extraKeys = extraKeyList ? pick(...extraKeyList)(list[i]) : {};
    const tmpValue = {
      id: list[i].id,
      order: extraKeyList ? i : undefined,
      content: [],
      ...extraKeys,
    };
    for (let j = 0; j < list[i].content.length; j += 1) {
      const { arrayId, ...rest } = list[i].content[j];
      tmpValue.content.push(
        {
          order: j,
          ...rest,
        },
      );
    }
    response.push(tmpValue);
  }

  return response;
};

class ProgressActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveLoading: false,
      showDisclaimer: false,
    };
  }

  componentDidMount() {
    const { preview } = this.props;
    if (preview === false) {
      setInterval(() => this.enterIconLoading(false), 120000);
    }
  }

  handleEdit = (bool) => {
    const { changeEdit } = this.props;
    changeEdit(bool);
    if (bool) {
      notification.warning({
        message: 'Editing mode',
        duration: 6,
      });
    } else {
      notification.warning({
        message: 'This is how your profile is looking by the moment',
        duration: 6,
      });
    }
  };

  openModal = (bool) => {
    this.setState({
      showDisclaimer: bool,
    });
  }

  enterIconLoading = (bool, acceptTerms) => {
    this.setState({ saveLoading: true });
    const {
      sectionList, rightList, circleList,
      checkList, profileList, ventureId,
      companyName, companyNameComplete,
      mutate, setComplete,
    } = this.props;
    const tmpSection = saveFormat(sectionList);
    const tmpRightCircleSections = saveFormat(rightList);
    const circleKeyList = [
      'initPercentage', 'endPercentage', 'percentageText', 'percentageComplete',
    ];
    const tmpCircleSections = saveFormat(circleList, circleKeyList);
    const tmpCheckListSections = saveFormat(checkList);
    const profileKeyList = ['linkedin', 'name', 'isComplete'];
    const tmpProfileSections = saveFormat(profileList, profileKeyList);
    const variables = {
      id: ventureId,
      companyName,
      companyNameComplete,
      finish: bool,
      sections: tmpSection,
      rightCircleSections: tmpRightCircleSections,
      circleSections: tmpCircleSections,
      checklistSections: tmpCheckListSections,
      profileSections: tmpProfileSections,
      acceptTerms: acceptTerms ? acceptTerms : false,
    };
    this.setState({
      showDisclaimer: false,
    });
    mutate({
      variables,
    }).then((res) => {
      this.setState({ saveLoading: false });
      if (res.data.updateVentureOpportunity.status === 200) {
        if (bool) {
          setComplete(bool);
          notification.success({
            message: 'Your Profile was sent!',
            duration: 3.5,
          });
        } else {
          notification.success({
            message: 'Your Profile was saved! Next step is to send it',
            duration: 3.5,
          });
        }
      }
      if (res.data.errors) {
        notification.error({
          message: 'An error have occurred',
          duration: 3.5,
        });
        console.log(res.data.errors);
      }
    })
    .catch((err) => {
      this.setState({ saveLoading: false });
      notification.error({
        message: 'Network error',
        duration: 3.5,
      });
      console.log(err);
      console.log('Network error');
    });
  };

  calculatePercentage = () => {
    const {
      sectionList, rightList, circleList,
      checkList, profileList, companyNameComplete,
      existPitchVenture,
    } = this.props;
    const fieldCompleteList = [];
    fieldCompleteList.push(companyNameComplete);
    sectionList.forEach((sections) => {
      if (sections.content.length === 0) {
        fieldCompleteList.push(false);
      } else {
        sections.content.forEach((item) => {
          fieldCompleteList.push(item.isComplete);
        });
      }
    });
    rightList.forEach((sections) => {
      sections.content.forEach((item) => {
        fieldCompleteList.push(item.isComplete);
      });
    });
    circleList.forEach((sections) => {
      fieldCompleteList.push(sections.percentageComplete);
      sections.content.forEach((item) => {
        fieldCompleteList.push(item.isComplete);
      });
    });
    checkList.forEach((sections) => {
      sections.content.forEach((item) => {
        fieldCompleteList.push(item.isComplete);
      });
    });
    profileList.forEach((sections) => {
      fieldCompleteList.push(sections.isComplete);
    });
    fieldCompleteList.push(existPitchVenture);
    let counter = 0;
    fieldCompleteList.forEach((item) => {
      if (item) {
        counter += 1;
      }
    });

    return Math.round((counter / fieldCompleteList.length) * 100);
  };

  render() {
    const { editing } = this.props;
    const { saveLoading, showDisclaimer } = this.state;
    const { handleEdit } = this;
    const generalButtonsContent = {
      xs: { span: 24, offset: 0 },
      md: { span: 12, offset: 12 },
      lg: { span: 9, offset: 15 },
      xl: { span: 8, offset: 16 },
      xxl: { span: 5, offset: 19 },
    };

    return (
      <Row>
        <ModalDisclaimer
          visible={showDisclaimer}
          handleOk={(acceptTerms) => {this.enterIconLoading(true, acceptTerms);}}
          handleCancel={() => (this.openModal(false)) }
        />
        <Affix
          offsetTop={30}
        >
          <Col {...generalButtonsContent}>
            <Row className="button-group" type="flex" justify="space-between">
              <Button
                className={(editing ? 'hidden-button' : 'normal-button first')}
                style={{ marginRight: '10px' }}
                icon="edit"
                onClick={(event) => handleEdit(true, event)}
              >
                Edit
              </Button>
              <Button
                className={(editing ? 'normal-button first' : 'hidden-button')}
                icon="search"
                onClick={(event) => handleEdit(false, event)}
              >
                Preview
              </Button>
              <Button
                className="normal-button"
                icon="save"
                style={{ marginRight: '10px' }}
                loading={saveLoading}
                onClick={(event) => this.enterIconLoading(false)}
              >
                Save
              </Button>
              <Button
                className="save-button"
                type="primary"
                loading={saveLoading}
                onClick={() => this.openModal(true)}
              >
                Send Profile
              </Button>
            </Row>
            <Row className="mt-1">
              <Progress
                className="one-pager-progress"
                type="line"
                percent={this.calculatePercentage()}
              />
            </Row>
          </Col>
        </Affix>
      </Row>
    );
  }
}

export default graphql(mutation)(ProgressActionBar);
