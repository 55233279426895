import React from 'react';
import { Query } from 'react-apollo';
import { investorColumns } from './tableColumns';
import {
  Table,
  Typography,
  Row,
} from 'antd';
import { membersQuery } from './query';

const { Title } = Typography;

const InvestorTable = () => (
  <Query query={membersQuery}>
    {
      ({ data, refetch }) => {
        const { memberSuggestedNotActive } = data;

        return (
          <Row>
            <Title className="dashboard-title" level={2}>
              Investor
            </Title>,
            <Table
              columns={investorColumns(refetch)}
              dataSource={memberSuggestedNotActive}
              pagination={{pageSize: 50}}
              bordered
              rowKey={(record) => {
                return record.id;
              }}
            />
          </Row>
        );
      }
    }
  </Query>
);

export default InvestorTable;
