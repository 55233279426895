import React, {Component} from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';

import OnePagerView from './onepager/OnePagerView';
import OpinionView from './opinion/OpinionView';
import OnePagerStatus from './onepager/OnePagerStatus';

import './navigator.scss';

import gql from 'graphql-tag';

import {Query} from 'react-apollo';

import MessageComponent from '../../components/Message';

const founder_query = gql`
query Query($venture_token: String!) {
  ventureToken(token: $venture_token) {
    id
    companyName
    token
    isReceived
    receivedDate
    companyNameComplete
    tags {
      id
      name
    }
  }
}
`;

class NavigatorView extends Component {
  render() {
    let venture_token = this.props.match.params.token;
    return (
      <Query query={founder_query} variables={{venture_token}}>
        {({loading, error, data, refetch}) => {
          if (loading) return (
            <MessageComponent
              icon="loading"
              iconSize={50}
              text=""
              level={4}
            />
          );
          if (error) return (
            <MessageComponent
              icon="smile"
              iconSize={50}
              text={`${error.message}`}
              level={4}
            />
          );
          return (
            <Switch>
              <Route
                  path="/navigator/one-pager/:token/form"
                  render={(props) => <OnePagerView {...props} venture={data.ventureToken} />}
              />
              <Route
                  path="/navigator/one-pager/:token/status"
                  render={(props) => <OnePagerStatus {...props} venture={data.ventureToken} />}
              />
              <Route
                  path="/navigator/one-pager/:token/member/:memberToken"
                  render={(props) => <OpinionView {...props} venture={data.ventureToken} />}
              />
            </Switch>
          );
        }}
      </Query>
    );
  }
}

export default NavigatorView;