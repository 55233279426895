import React from 'react';
import VentureComponent from "../../../components/Venture";

class FounderDetailView extends React.Component {

  render() {
    return (
      <VentureComponent
        id_founder={this.props.match.params.id}
      />
    );
  }
}

export default FounderDetailView;