import React from 'react';
import gql from 'graphql-tag';
import {
    Query,
    Mutation
} from 'react-apollo';
import {
    Col,
    Row,
    Typography,
    Button,
    Icon,
    notification,
} from 'antd';
import MessageComponent from '../Message';
import CustomTag from '../CustomTag';

const {Paragraph} = Typography;

const query = gql`
query averageQuery(
  $show_to_investment: Boolean!,
) {
  tags(showInvestor: $show_to_investment) {
    id
    name
  }
}
`;

const mutation = gql`
mutation Mutation($ventureId: ID!, $tags: [Int]!) {
  classifyVenture(ventureId: $ventureId, tags:$tags) {
    status
  }
}
`;

class Classify extends React.Component {
    state = {
        selected: []
    };

    componentDidMount() {
        this.setState({selected: this.props.venture.tags.map(item => item.id)});
    }

    onSelectTag = (checked, tagId) => {
        let {selected} = this.state;
        if (checked) {
            selected.push(tagId);
        } else {
            const index = selected.indexOf(tagId);
            selected.splice(index, 1);
        }
        this.setState({selected});
    };

    onCompleteMutation() {
        notification['success']({
            message: 'Due Diligence saved!',
            duration: 3.5,
        });
        this.props.functionUpdateCurrent(Number(this.props.current) + 1);
    }

    render() {
        const {selected} = this.state;
        return (
            <div>
                <Row className="my-1" type="flex" justify="space-around" align="middle" gutter={48}>
                    <Col span={16}>
                        <Paragraph className="text-black-label">
                            Classify this venture select some of this tags
                        </Paragraph>
                        <Query query={query} variables={{show_to_investment: true}}>
                            {
                                ({loading, error, data, refetch}) => {
                                    if (loading) {
                                        return (
                                            <MessageComponent
                                                icon="loading"
                                                iconSize={50}
                                                text="Loading Form"
                                                level={4}/>
                                        );
                                    }
                                    if (error) return (
                                        <MessageComponent
                                            icon="smile"
                                            iconSize={50}
                                            text={`${error.message}`}
                                            level={4}/>
                                    );
                                    return (
                                        <div className="text-center">
                                            {data.tags.map(tag => (
                                                <CustomTag
                                                    key={tag.id}
                                                    value={tag.id}
                                                    checked={selected.indexOf(tag.id) >= 0}
                                                    onSelectTag={this.onSelectTag}
                                                >
                                                    {tag.name}
                                                </CustomTag>
                                            ))}
                                        </div>
                                    );
                                }
                            }
                        </Query>
                    </Col>
                </Row>
                <Row className="my-1" type="flex" justify="space-around" align="middle">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Mutation
                            mutation={mutation}
                            onCompleted={() => {
                                this.onCompleteMutation();
                            }}
                            onError={() => notification['error']({
                                message: 'Network Error!',
                                duration: 3.5,
                            })}
                            variables={{
                                ventureId: this.props.venture.id,
                                tags: selected,
                            }}
                        >
                            {(classifyVenture, {loading}) => (
                                <Button type="primary" onClick={classifyVenture} className="login-form-button">
                                    Save and Next
                                    <Icon type="right"/>
                                </Button>
                            )}
                        </Mutation>
                    </Col>
                </Row>
            </div>
        );
    };
}

export default Classify;