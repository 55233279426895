import React from 'react';

import MessageComponent from '../../../../components/Message';

import userIcon from '../../../../images/user.svg';
import {
  mediaUrl,
} from '../../../../utils/ApiEndPoints';
import {
  sectionsRightCircleDefault,
  sectionsRightCircleEmpty,
  sectionsCircleDefault,
  sectionsCircleEmpty,
} from './constants';
import OnePagerBody from './components/OnePagerBody';

import '../../../../styles/utils.scss';

import './styles.scss';

const icons = [
  {
    arrayId: 0,
    icon: 'shopping-cart',
    title: 'Shopping Cart',
  },
  {
    arrayId: 1,
    icon: 'cluster',
    title: 'Cluster',
  },
  {
    arrayId: 2,
    icon: 'file-search',
    title: 'Search',
  },
  {
    arrayId: 3,
    icon: 'wechat',
    title: 'Chat',
  },
  {
    arrayId: 4,
    icon: 'clock-circle',
    title: 'Clock',
  },
  {
    arrayId: 5,
    icon: 'setting',
    title: 'Setting',
  },
];

const updateIds = (content) => {
  const copyContent = content;
  if (copyContent) {
    for (let i = 0; i < copyContent.length; i += 1) {
      copyContent[i].arrayId = i;
    }
  }
};

class OnePagerViewRenderer extends React.Component {
  constructor(props) {
    super(props);

    const { preview } = this.props;

    const { contentSections, sections } = this.props;
    if (contentSections.length) {
      sections.forEach((item) => {
        item.content = [];
        contentSections.forEach((contentItem) => {
          if (contentItem.section.id === item.id) {
            const tmpContent = {
              icon: contentItem.icon,
              title: contentItem.title,
              description: contentItem.description,
              isComplete: contentItem.isComplete,
            };
            item.content.push(tmpContent);
          }
        });
      });

      updateIds(sections);
      sections.forEach((item) => {
        updateIds(item.content);
      });
    }

    sections.forEach((item, index) => {
      if (!item.content) {
        item.arrayId = index;
        if (!preview) {
          item.content = item.defaultsectionitemSet.map((defaultItem, index) => ({
            arrayId: index,
            title: defaultItem.title,
            description: defaultItem.description,
            icon: icons[index].icon,
            isComplete: false,
          }));
        } else {
          item.content = [
            {
              arrayId: 0,
              icon: 'clock-circle',
              title: item.itemTitleExample,
              description: item.itemDescriptionExample,
              isComplete: false,
            },
          ];
        }
      }
    });


    const { contentSectionsRightCircle, sectionsRightCircle } = this.props;
    if (contentSectionsRightCircle.length) {
      sectionsRightCircle.forEach((item) => {
        item.content = [];
        contentSectionsRightCircle.forEach((contentItem) => {
          if (contentItem.rightCircleSection.id === item.id) {
            const tmpContent = {
              number: contentItem.number,
              quantity: contentItem.quantity,
              year: contentItem.year,
              preText: contentItem.preText,
              postText: contentItem.postText,
              title: contentItem.title,
              isComplete: contentItem.isComplete,
            };
            item.content.push(tmpContent);
          }
        });
      });

      updateIds(sectionsRightCircle);
      sectionsRightCircle.forEach((item) => {
        updateIds(item.content);
      });
    }
    sectionsRightCircle.forEach((item, index) => {
      if (!item.content) {
        item.arrayId = index;
        if (!preview) {
          item.content = sectionsRightCircleDefault();
        } else {
          item.content = sectionsRightCircleEmpty();
        }
      }
    });


    const { contentSectionsCircle, sectionsCircle } = this.props;
    if (contentSectionsCircle.length) {
      sectionsCircle.forEach((item) => {
        contentSectionsCircle.forEach((contentItem) => {
          if (contentItem.circleSection.id === item.id) {
            item.content = [];
            item.initPercentage = contentItem.initPercentage;
            item.endPercentage = contentItem.endPercentage;
            item.percentageText = contentItem.percentageText;
            item.percentageComplete = contentItem.percentageComplete;

            contentItem.circlesectioncontentSet.forEach((subItem) => {
              const tmpContent = {
                number: subItem.number,
                quantity: subItem.quantity,
                description: subItem.description,
                isComplete: subItem.isComplete,
              };
              item.content.push(tmpContent);
            });
          }
        });
      });
      updateIds(sectionsCircle);
      sectionsCircle.forEach((item) => {
        updateIds(item.content);
      });
    }
    sectionsCircle.forEach((item, index) => {
      if (!item.content) {
        item.arrayId = index;
        if (!preview) {
          item.initPercentage = '2.0';
          item.endPercentage = '4.0';
          item.percentageText = 'commission on each transaction';
          item.percentageComplete = false;
          item.content = sectionsCircleDefault();
        } else {
          item.initPercentage = '0.0';
          item.endPercentage = '0.0';
          item.percentageText = 'no data';
          item.content = sectionsCircleEmpty();
        }
      }
    });


    const { contentSectionsChecklist, sectionsChecklist } = this.props;
    if (contentSectionsChecklist.length) {
      sectionsChecklist.forEach((item) => {
        item.content = [];
        contentSectionsChecklist.forEach((contentItem) => {
          if (contentItem.checklistSection.id === item.id) {
            const tmpContent = {
              title: contentItem.title,
              description: contentItem.description,
              isComplete: contentItem.isComplete,
            };
            item.content.push(tmpContent);
          }
        });
      });

      updateIds(sectionsChecklist);
      sectionsChecklist.forEach((item) => {
        updateIds(item.content);
      });
    }
    sectionsChecklist.forEach((item, index) => {
      if (!item.content) {
        item.arrayId = index;
        if (!preview) {
          if (index === 0) {
            item.content = [
              {
                arrayId: 0,
                title: 'Define recent trends that make your solution possible',
                description: 'Description',
                isComplete: false,
              },
            ];
          } else {
            item.content = [
              {
                arrayId: 0,
                title: 'Increase sales quantity by 10%',
                description: 'Description',
                isComplete: false,
              },
            ];
          }
        } else {
          item.content = [
            {
              arrayId: 0,
              title: 'No data',
              description: 'No data',
            },
          ];
        }
      }
    });

    const { contentSectionsProfile, sectionsProfile, profilePhotos } = this.props;
    const contentProfiles = [];
    sectionsProfile.forEach((item, index) => {
      const profile = {
        id: item.id,
        minItems: item.minItems,
        maxItems: item.maxItems,
        order: 0,
        name: 'Name example',
        linkedin: 'username',
        linkedinVisible: false,
        sectionProfileIndex: index,
        image: {
          previewVisible: false,
          previewImage: '',
          fileList: [{
            uid: '-1',
            name: 'user.png',
            status: 'done',
            url: userIcon,
          }],
        },
        content: [
          {
            arrayId: 0,
            description: 'Managing Director',
          },
          {
            arrayId: 1,
            description: '12-year experience as Sales & Marketing',
          },
          {
            arrayId: 2,
            description: 'General manager in Chemical industry',
          },
        ],
      };
      if (contentSectionsProfile.length) {
        const content = contentSectionsProfile.filter(
          (contentItem) => contentItem.profileSection.id === item.id,
        );
        if (content.length) {
          content.forEach((contentProfile, contentIndex) => {
            const myProfile = JSON.parse(JSON.stringify(profile));
            if (contentIndex < profilePhotos.length) {
              myProfile.image.fileList = [{
                uid: '-1',
                name: 'user.png',
                status: 'done',
                url: mediaUrl + profilePhotos[contentIndex].image,
              }];
            }
            myProfile.content = contentProfile.profilesectioncontentSet.map(
              (subItem) => ({ description: subItem.description }),
            );
            myProfile.name = contentProfile.name;
            myProfile.order = contentProfile.order;
            myProfile.key = contentProfile.key;
            myProfile.linkedin = contentProfile.linkedin;
            myProfile.isComplete = contentProfile.isComplete;
            contentProfiles.push(myProfile);
          });
        } else {
          contentProfiles.push(JSON.parse(JSON.stringify(profile)));
        }
      } else {
        contentProfiles.push(JSON.parse(JSON.stringify(profile)));
      }
    });
    updateIds(contentProfiles);
    contentProfiles.forEach((item) => {
      updateIds(item.content);
    });
    const { venture } = this.props;

    this.state = {
      isCompleted: venture.isReceived,
      companyName: venture.companyName,
      companyNameComplete: venture.companyNameComplete,
      sectionList: sections,
      rightList: sectionsRightCircle,
      circleList: sectionsCircle,
      checkList: sectionsChecklist,
      profileList: contentProfiles,
    };
  }

  setComplete = (bool) => {
    this.setState({
      isCompleted: bool,
    });
  }

  onChangeCompanyName = (companyName) => {
    this.setState({
      companyName,
      companyNameComplete: true,
    });
  };

  updateState = (data) => {
    this.setState(data);
  }

  render() {
    const {
      sectionList, rightList, circleList, checkList, profileList,
    } = this.state;
    const { companyName, companyNameComplete, isCompleted } = this.state;
    const {
      sections, venture, pitchVenture, preview, sectionsProfile,
    } = this.props;
    if (preview === false && isCompleted) {
      return (
        <MessageComponent
          icon="check-circle"
          iconSize={100}
          theme="twoTone"
          twoToneColor="#52c41a"
          text="Thank you! Your venture information form has been successfully submitted.
          We will reach you once our evaluation process is conducted."
          level={4}
        />
      );
    }

    return (
      <OnePagerBody
        preview={preview}
        sectionList={sectionList}
        rightList={rightList}
        circleList={circleList}
        checkList={checkList}
        profileList={profileList}
        ventureId={venture.id}
        companyName={companyName}
        companyNameComplete={companyNameComplete}
        pitchVenture={pitchVenture}
        sections={sections}
        setComplete={this.setComplete}
        onChangeCompanyName={this.onChangeCompanyName}
        sectionsProfile={sectionsProfile}
        state={this.state}
        updateState={this.updateState}
      />
    );
  }
}

export default OnePagerViewRenderer;
