import {isUndefined} from "util";

export const customBackgroundColor = (color) => {
  return { backgroundColor: color };
};

export const customFontType = (font) => {
  if (!isUndefined(font) && font !== null) {
    return {
      fontFamily: `${font}`
    };
  }
  return {};
};