import gql from 'graphql-tag';

export const QUERIES = {
  dashboardInvestor: gql`
    {
      currentUser {
        id
        username
        firstName
        lastName
        email
      }
      investorUser {
        id
        tags {
          id
          name
        }
        answerSet {
          choices {
            name
          }
          question {
            id
            name
          }
        }
      }
    }
  `,
  investorsVenturesByUser: gql`
    {
      investorUser {
        id
        token
        memberopinionSet {
          id
          venture{
            id
            companyName
            token
          }
          email
          linkUsed
          evaluation
          comment
        }
      }
    }
  `,
};
